import {ActionReferenceType, ContactCustomFieldInput, GroupActionReferenceInput} from '@graphql';

// TODO: generic output typing
export function valueListToReferenceList(
  idList: number[],
  action: ActionReferenceType = 'ADD',
): GroupActionReferenceInput[] {
  if (!idList) {
    return [];
  }
  return [
    {
      action,
      idList,
    },
  ];
}
export function valueListToReferenceListWithActions(
  addableIds: number[],
  removableIds: number[]
): GroupActionReferenceInput[] {
  if (!addableIds && !removableIds) {
    return [];
  }
  const result = [];
  if (addableIds) {
    result.push({
      idList: addableIds,
      action: 'ADD'
    });
  }
  if (removableIds) {
    result.push({
      idList: removableIds,
      action: 'REMOVE'
    });
  }
  return result;
}
// TODO: generic output typing
export function customFieldsValueToCustomFieldList(customFieldsValue: { [inputName: string]: any }): ContactCustomFieldInput[] {
  return Object.keys(customFieldsValue)
    .map(fieldName => ({
      fieldName,
      valueList: customFieldsValue[fieldName],
    }));
}
