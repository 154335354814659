<h3 mat-dialog-title translate>{{ 'SelectTimezone' | translate }}</h3>
<div mat-dialog-content>
  <mat-form-field class="w-100">
    <mat-select [(ngModel)]="selectZone">
      <mat-option *ngFor="let zone of data.zonesList" [value]="zone.zoneName">
        {{ zone.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions class="modal-btns">
  <button type="button" (click)="onCancel()" mat-stroked-button color="warn">
    <span translate>{{ 'Cancel' | translate }}</span>
  </button>
  <button type="button" (click)="onConfirm()" mat-flat-button color="primary">
    <span translate>{{ 'Save' | translate }}</span>
  </button>
</div>
