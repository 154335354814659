import {Injectable} from '@angular/core';
import {FieldFragment, GetContactGQL, GetGroupGQL, GetLeadGQL, GetSpecializationGQL, GetWorkplaceGQL} from '@graphql';
import {FieldWithValue, ObjectName} from '@shared-models';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GetObjectService {
  constructor(
    private readonly getContact: GetContactGQL,
    private readonly getGroup: GetGroupGQL,
    private readonly getSpecialization: GetSpecializationGQL,
    private readonly getLead: GetLeadGQL,
    private readonly getWorkplace: GetWorkplaceGQL,
  ) {}

  getObjectDataWithFields(
    id: number,
    objectName: ObjectName,
    fields: FieldFragment[],
  ): Observable<FieldWithValue[]> {
    if (!id) {
      return of(fields);
    }
    switch (objectName) {
      case 'Contact':
        return this.getContact.watch({ id }, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
          map(data => data.data.getContact),
          map(params => fields.map(field => ({ ...field, value: params[field.name] }))),
        );
      case 'Group':
        return this.getGroup.watch({ id }, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
          map(data => data.data.getGroup),
          map(params => fields.map(field => ({ ...field, value: params[field.name] }))),
        );
      case 'Specialization':
        return this.getSpecialization.watch({ id }, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
          map(data => data.data.getSpecialization),
          map(params => fields.map(field => ({ ...field, value: params[field.name] }))),
        );
      case 'Lead':
        return this.getLead.watch({ id }, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
          map(data => data.data.getLead),
          map(params => fields.map(field => ({ ...field, value: params[field.name] }))),
        );
      case 'Workplace':
        return this.getWorkplace.watch({ id }, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
          map(data => data.data.getWorkplace),
          map(params => fields.map(field => ({ ...field, value: params[field.name] }))),
        );
      default:
        return of([]);
    }
  }
}
