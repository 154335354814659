import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-discard-changes-modal',
  templateUrl: './discard-changes-modal.component.html',
  styleUrls: ['./discard-changes-modal.component.scss'],
  standalone:true,
  imports:[MatDialogModule, TranslateModule, MatButtonModule, MatDialogModule]
})
export class DiscardChangesModalComponent {
  constructor(private readonly translateService: TranslateService) { }
}
