import {Injectable} from '@angular/core';
import {
  CustomFieldDefinition,
  GetCustomFieldDefinitionResponseGQL,
  GetCustomFieldDefinitionResponseQuery,
} from '@graphql';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ObjectName} from '@shared-models';
import {ResponseService} from './response.service';

@Injectable({
  providedIn: 'root',
})
export class GetCustomFieldsService {
  constructor(
    private readonly getCustomFieldDefinitionResponseGQL: GetCustomFieldDefinitionResponseGQL,
    private readonly responseService: ResponseService,
  ) {}

  getFields(objectName: ObjectName): Observable<CustomFieldDefinition[]> {
    return this.getCustomFieldDefinitionResponseGQL
      .fetch({ objectName }, { fetchPolicy: 'no-cache', errorPolicy: 'all' })
      .pipe(
        switchMap(response =>
          this.responseService.responseToDataOrError<GetCustomFieldDefinitionResponseQuery>(
            response,
          ),
        ),
        map(response => response.getCustomFieldDefinitionResponse.customFieldDefinitionEntryList),
        map(entryList => entryList.find(entry => entry.objectName === objectName)),
        map(entry => entry.customFieldDefinitionList),
      );
  }
}
