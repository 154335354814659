<ng-container *ngIf="dataList && columns">
  <cm-ui-table
    *ngIf="!dataList.error"
    [columns]="columns"
    [rows]="dataList.rows"
    [rowsTotal]="dataList.total"
    [pageSize]="pageSize"
    [actions]="actions"
    [loading]="isLoading"
    [headerButtons]="tableMenuButtons"
    [selectionType]="selectionType"
    [searchVisible]="searchVisible"
    [showMobileColumnPicker]="true"
    (tableButtonClick)="onTableAction($event)"
    (paginateChange)="onPaginationChange($event)"
    (actionClicked)="onActionClicked($event)"
    (sortChange)="onSortChange($event)"
    (search)="onSearchValueChange($event)"
    (columnsChange)="onColumnsChange($event)"
    (selectionChange)="onMultipleActionClicked($event)"
  ></cm-ui-table>
  <p class="error-msg" *ngIf="dataList.error" translate>commonError</p>
</ng-container>
