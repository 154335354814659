<input #input type="file" name="file" id="file" [accept]="accept" (change)="onFileChange($event)">
<label class="dropzone" [class.drag-over]="dragOver" for="file"
    (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
    {{placeholder | translate}}
</label>


<div class="uploaded-file" *ngIf="file">
    <div>
        <mat-icon class="material-symbols-outlined icon" class="file-icon">description</mat-icon>
    </div>
    <div class="file-name" class="flex-column" [title]="file.name">
        <div>
            {{file.name}}
        </div>

    </div>
    <div class="file-size">{{formatFileSize(file.size)}}</div>
    <button mat-icon-button (click)="removeFile()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div *ngIf="invalidExtension" class="invalid-extension">
    {{'shared.components.fileUpload.invalidExtension'| translate}}
</div>
