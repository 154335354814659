<h3 mat-dialog-title translate>{{ data.translationKeys.title }}</h3>
<div mat-dialog-content>
  <p translate>{{ data.translationKeys.message }}</p>
</div>
<div mat-dialog-actions class="modal-btns">
  <button type="button" (click)="onCancel()" mat-button color="primary">
    <span translate>{{ data.translationKeys.cancel }}</span>
  </button>
  <button type="button" (click)="onConfirm()" mat-button color="warn">
    <span translate>{{ data.translationKeys.confirm }}</span>
  </button>
</div>
