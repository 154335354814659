import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, TranslateModule],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent {
  @Output() private fileChanged: EventEmitter<File> = new EventEmitter();
  @Input() accept: string = '.highp';
  @Input() placeholder: string = 'shared.components.fileUpload.message';

  @ViewChild('input') inputRef: ElementRef<HTMLInputElement>
  file: File;
  invalidExtension: boolean = false;

  dragStatus: 'dragover' | 'dragleave' | 'drop';
  get dragOver() { return this.dragStatus == 'dragover' }
  get dragLeave() { return this.dragStatus == 'dragleave' }
  get dropped() { return this.dragStatus == 'drop' }


  constructor() { }

  onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragStatus = 'dragover';
    this.invalidExtension = false;
  }

  onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragStatus = 'dragleave';
  }

  onDrop(evt) {

    evt.preventDefault();
    evt.stopPropagation();
    this.inputRef.nativeElement.value = '';
    this.file = null;
    this.dragStatus = 'drop';
    const files: File[] = evt?.dataTransfer?.files ?? [];
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      const fileExt = '.'+f.name.split('.').pop();
      const allowed = this.accept.split(',').includes(fileExt);
      if (!allowed) {
        this.invalidExtension = true;
      }
    }
    if (this.invalidExtension) {
      this.dragStatus = null;
      return;
    }

    this.file = evt.dataTransfer.files[0] as File;

    this.fileChanged.emit(this.file);
  }


  onFileChange(event: Event): void {
    this.invalidExtension = false;
    const input = event.target as HTMLInputElement;
    if (input.files.length) {
      this.file = input.files[0]
    } else {
      this.file = null;
    }
    this.fileChanged.emit(this.file)
  }

  removeFile() {
    this.inputRef.nativeElement.value = '';
    this.file = null;
    this.fileChanged.emit(null);
  }

  formatFileSize(bytes: number, decimalPoint = 2) {
    if (bytes == 0) return '0 Bytes';
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
