import {Injectable} from '@angular/core';
import {FormDefinitionGroup} from '@cm/ui-modules';
import {ContactCustomField, FieldFragment} from '@graphql';
import {TranslateService} from '@ngx-translate/core';
import {
  customFieldsToFormFields,
  customFormFieldsToFormDefinitionGroup,
  fieldsToFormDefinitionGroups,
} from '@shared-helpers';
import {FieldWithValue, ObjectName} from '@shared-models';
import {Observable, of} from 'rxjs';
import {flatMap, map, switchMap} from 'rxjs/operators';
import {FormDefinitionWithVersion} from './../models/form-definition-with-value';
import {GetCustomFieldsService} from './get-custom-fields.service';
import {GetFieldsService} from './get-fields.service';
import {GetObjectService} from './get-object.service';

@Injectable({
  providedIn: 'root',
})
export class FormDefinitionService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly getObjectService: GetObjectService,
    private readonly getCustomFieldsService: GetCustomFieldsService,
    private readonly fieldsService: GetFieldsService,
  ) {}

  getFormDefinition(
    objectName: ObjectName,
    rowId: number = null,
  ): Observable<FormDefinitionWithVersion> {
    return this.fieldsService.getNonInsertable(objectName).pipe(
      switchMap(fields => this.objectFieldsToFieldsWithValue(rowId, objectName, fields)),
      switchMap(fields => {
        const version = fields.find(field => field.name === 'version')?.value;
        const formGroups = fieldsToFormDefinitionGroups(fields, this.translateService, objectName);
        if (objectName === 'Contact' || objectName === 'Lead') {
          const customFieldValues = fields.find(field => field.name === 'customFieldList')?.value;
          return this.addCustomFieldsFormGroupDefinitions(
            objectName,
            formGroups,
            customFieldValues,
            version,
            this.translateService
          );
        } else {
          return of({
            formDefinition: { formGroups },
            version,
          });
        }
      }),
    );
  }

  objectFieldsToFieldsWithValue(
    rowId: number,
    objectName: ObjectName,
    fields: FieldFragment[],
  ): Observable<FieldWithValue[]> {
    return this.getObjectService
      .getObjectDataWithFields(rowId, objectName, fields)
      .pipe(flatMap(fieldsWithData => this.fieldsService.sortFields(objectName, fieldsWithData)));
  }

  addCustomFieldsFormGroupDefinitions(
    objectName: ObjectName,
    formDefinitionGroups: FormDefinitionGroup[],
    customFieldValues: ContactCustomField[],
    version: number,
    translateService: TranslateService
  ): Observable<FormDefinitionWithVersion> {
    return this.getCustomFieldsService.getFields(objectName).pipe(
      map(customFields => customFieldsToFormFields(customFields, customFieldValues)),
      map(customFormFields => ({
        formGroups: [
          ...formDefinitionGroups,
          customFormFieldsToFormDefinitionGroup(customFormFields, translateService),
        ],
      })),
      map(formDefinition => ({
        formDefinition,
        version,
      })),
    );
  }
}
