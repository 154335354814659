import {Injectable} from '@angular/core';
import {CmUiCompoundFilterObjectNameOption, CmUiTableColumn, FormActionButton} from '@cm/ui-modules';
import {TranslateService} from '@ngx-translate/core';
import {TableColumnDefinition} from '@shared-models';
import {BehaviorSubject, Observable} from 'rxjs';
import {PredefinedFilter} from '@cm/ui-modules/lib/cm-ui-compound-filter/models';

@Injectable({
  providedIn: 'root',
})
export class TranslateElementsService {
  constructor(private readonly translateService: TranslateService) {
  }

  formActionButtons(buttons: FormActionButton[]): FormActionButton[] {
    return buttons.map(button => ({
      ...button,
      label: this.translateService.instant(button.label),
    }));
  }

  translateTableHeader(source: TableColumnDefinition[], dest$: Observable<CmUiTableColumn[]>): Observable<CmUiTableColumn[]> {
    const columnsList$ = new BehaviorSubject<CmUiTableColumn[]>([]);
    dest$.subscribe(items => columnsList$.next(items));

    const valueC = columnsList$.getValue();
    valueC.forEach(tr => {
      source.forEach(src => {
        if (tr.prop === src.prop) {
          tr.label = this.translateService.instant(src.key);
        }
      });
    });
    return columnsList$.asObservable();
  }
  translateCompoundFilterObjectNames(filters: CmUiCompoundFilterObjectNameOption[]): void {
    filters.forEach(item => {
      item.label = this.translateService.instant('ADMIN.common.filter.objectNames.' + item.label);
    });
  }

  translatePredefinedCompoundFilterObjectNames(filters: PredefinedFilter[]): void {
    filters.forEach(item => {
      item.description = this.translateService.instant(item.description);
    });
  }
}
