<p translate>shared.components.discardChangesModal.text</p>
<div class="modal-actions">
  <button
    mat-button
    [mat-dialog-close]="'cancel'">
    <span translate>shared.components.discardChangesModal.cancel</span>
  </button>

  <button
    mat-flat-button
    color="warn"
    [mat-dialog-close]="'discardChanges'">
    <span translate>shared.components.discardChangesModal.discardChanges</span>
  </button>
</div>
