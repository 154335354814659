import {Injectable} from '@angular/core';
import {CmSearchField, CmUiTableColumn} from '@cm/ui-modules';
import {Observable, of, zip} from 'rxjs';
import {ObjectName, TableDefinition} from '../models';
import {CustomFieldService} from './custom-field.service';
import {map} from 'rxjs/operators';
import {
  BATCH_JOB_TABLE_DEFINITION,
  BILLING_DETAIL_TABLE_DEFINITION,
  BILLING_TABLE_DEFINITION,
  BILLING_PLAN_TABLE_DEFINITION,
  CONTACT_DATA_TABLE_DEFINITION,
  CONTACT_TABLE_DEFINITION,
  CUSTOM_FIELD_TABLE_DEFINITION,
  GROUP_TABLE_DEFINITION,
  LEAD_TABLE_DEFINITION,
  MEETING_TABLE_DEFINITION,
  MESSAGE_CAMPAIGN_TABLE_DEFINITION,
  REPRESENTATIVE_TABLE_DEFINITION,
  SEGMENT_TABLE_DEFINITION,
  SPECIALIZATION_TABLE_DEFINITION,
  WORKPLACE_TABLE_DEFINITION,
} from '../config';
import {TableDefinitionService} from './table-definition.service';
import {TranslateElementsService} from './translate-elements.service';

@Injectable({
  providedIn: 'root',
})
export class TableColumnService {
  private readonly objectColumnsByObjectName = new Map<ObjectName, Observable<CmUiTableColumn[]>>();

  private readonly objectSearchFieldsByObjectName = new Map<ObjectName, CmSearchField[]>();

  // prettier-ignore
  constructor(
    private customFieldService: CustomFieldService,
    private translateElementsService: TranslateElementsService,
    private definitionService: TableDefinitionService,
  ) {
    this.objectColumnsByObjectName
      .set('Contact', definitionService.toColumns(CONTACT_TABLE_DEFINITION))
      .set('SegmentContact', definitionService.toColumns(CONTACT_DATA_TABLE_DEFINITION))
      .set('MessageCampaign', definitionService.toColumns(MESSAGE_CAMPAIGN_TABLE_DEFINITION))
      .set('Meeting', definitionService.toColumns(MEETING_TABLE_DEFINITION))
      .set('Group', definitionService.toColumns(GROUP_TABLE_DEFINITION))
      .set('Lead', definitionService.toColumns(LEAD_TABLE_DEFINITION))
      .set('Workplace', definitionService.toColumns(WORKPLACE_TABLE_DEFINITION))
      .set('BatchJob', definitionService.toColumns(BATCH_JOB_TABLE_DEFINITION))
      .set('CustomFieldDefinition', definitionService.toColumns(CUSTOM_FIELD_TABLE_DEFINITION))
      .set('Specialization', definitionService.toColumns(SPECIALIZATION_TABLE_DEFINITION))
      .set('Segment', definitionService.toColumns(SEGMENT_TABLE_DEFINITION))
      .set('Representative', definitionService.toColumns(REPRESENTATIVE_TABLE_DEFINITION))
      .set('Billing', definitionService.toColumns(BILLING_TABLE_DEFINITION))
      .set('BillingPlan', definitionService.toColumns(BILLING_PLAN_TABLE_DEFINITION))
      .set('BillingDetail', definitionService.toColumns(BILLING_DETAIL_TABLE_DEFINITION));
    this.objectSearchFieldsByObjectName
      .set('Contact', definitionService.toSearchFields(CONTACT_TABLE_DEFINITION))
      .set('SegmentContact', definitionService.toSearchFields(CONTACT_DATA_TABLE_DEFINITION))
      .set('MessageCampaign', definitionService.toSearchFields(MESSAGE_CAMPAIGN_TABLE_DEFINITION))
      .set('Meeting', definitionService.toSearchFields(MEETING_TABLE_DEFINITION))
      .set('Group', definitionService.toSearchFields(GROUP_TABLE_DEFINITION))
      .set('Lead', definitionService.toSearchFields(LEAD_TABLE_DEFINITION))
      .set('Workplace', definitionService.toSearchFields(WORKPLACE_TABLE_DEFINITION))
      .set('BatchJob', definitionService.toSearchFields(BATCH_JOB_TABLE_DEFINITION))
      .set('CustomFieldDefinition', definitionService.toSearchFields(CUSTOM_FIELD_TABLE_DEFINITION))
      .set('Specialization', definitionService.toSearchFields(SPECIALIZATION_TABLE_DEFINITION))
      .set('Segment', definitionService.toSearchFields(SEGMENT_TABLE_DEFINITION))
      .set('Representative', definitionService.toSearchFields(REPRESENTATIVE_TABLE_DEFINITION))
      .set('Billing', definitionService.toSearchFields(BILLING_TABLE_DEFINITION))
      .set('BillingPlan', definitionService.toSearchFields(BILLING_PLAN_TABLE_DEFINITION))
      .set('BillingDetail', definitionService.toSearchFields(BILLING_DETAIL_TABLE_DEFINITION));
  }
  getObjectColumns(objectName: ObjectName): Observable<CmUiTableColumn[]> {
    return this.objectColumnsByObjectName.get(objectName);
  }

  getObjectSearchFields(objectName: ObjectName): CmSearchField[] {
    return this.objectSearchFieldsByObjectName.get(objectName);
  }
  getObjectColumnsExtendsByCustomColumns(objectName: ObjectName): Observable<CmUiTableColumn[]> {
    const columnsStoragePostfix = 'Columns';
    const storedColumnsDefinitions = window.localStorage.getItem(objectName + columnsStoragePostfix);
    let  defaultStoredColumns: Observable<CmUiTableColumn[]>;
    let  customStoredColumns: Observable<CmUiTableColumn[]>;
    if (storedColumnsDefinitions) {
      const defaultColumns: CmUiTableColumn[] = JSON.parse(storedColumnsDefinitions).filter(item => !item.isCustomField);
      defaultStoredColumns = of(this.remapFormatters(this.getTableDefinition(objectName), defaultColumns));
      const onlineCustomColumns = this.customFieldService.getObjectCustomColumns(objectName);
      // customStoredColumns = this.mergeCustomFields(onlineCustomColumns,
      //   JSON.parse(storedColumnsDefinitions).filter(item => item.isCustomField));
      // customStoredColumns = JSON.parse(storedColumnsDefinitions).filter(item => item.isCustomField);
      const onlineBaseColumns = [];
      const storedColumns = [];
      this.objectColumnsByObjectName.get(objectName).subscribe(item => onlineBaseColumns.push(item));
      defaultStoredColumns.subscribe(item => storedColumns.push(item));

      if (onlineBaseColumns[0].length !== storedColumns[0].length) {
        defaultStoredColumns = this.objectColumnsByObjectName.get(objectName);
      }
      customStoredColumns = onlineCustomColumns;
    } else {
      defaultStoredColumns = this.objectColumnsByObjectName.get(objectName);
      customStoredColumns = this.customFieldService.getObjectCustomColumns(objectName);
    }
    return zip(
      this.translateElementsService.translateTableHeader(this.getTableDefinition(objectName), defaultStoredColumns),
      customStoredColumns,
    ).pipe(map(([defaultColumns, customColumns]) => [...defaultColumns, ...customColumns]));
  }


  remapFormatters(source: TableDefinition, destination: CmUiTableColumn[]): CmUiTableColumn [] {
    source.forEach(item => {
      destination.forEach(dest => {
        if (item.dateFormat && item.prop === dest.prop) {
          dest.formatter = this.definitionService.getFormatter(item);
        }
      });
    });
    return destination;
  }
  // mergeCustomFields(dest$: Observable<CmUiTableColumn[]>, source: CmUiTableColumn[]): Observable<CmUiTableColumn[]> {
  //   dest$.pipe(
  //     take(1),
  //     map(item => {
  //       if (item) {
  //         item.forEach(par => {
  //           source.forEach(src => {
  //             if (par.prop === src.prop) {
  //               par.visible = src.visible;
  //               return par;
  //             }
  //           });
  //         });
  //       }
  //     })
  //   ).subscribe();
  //   return dest$;
  // }

  getTableDefinition(objName: string): TableDefinition {
    switch (objName) {
      case 'Contact': {
        return CONTACT_TABLE_DEFINITION;
      }
      case 'SegmentContact': {
        return CONTACT_DATA_TABLE_DEFINITION;
      }
      case 'MessageCampaign': {
        return MESSAGE_CAMPAIGN_TABLE_DEFINITION;
      }
      case 'Meeting': {
        return MEETING_TABLE_DEFINITION;
      }
      case 'Group': {
        return GROUP_TABLE_DEFINITION;
      }
      case 'Lead': {
        return LEAD_TABLE_DEFINITION;
      }
      case 'Workplace': {
        return WORKPLACE_TABLE_DEFINITION;
      }
      case 'Specialization': {
        return SPECIALIZATION_TABLE_DEFINITION;
      }
      case 'BatchJob': {
        return BATCH_JOB_TABLE_DEFINITION;
      }
      case 'CustomFieldDefinition': {
        return CUSTOM_FIELD_TABLE_DEFINITION;
      }
      case 'Representative': {
        return REPRESENTATIVE_TABLE_DEFINITION;
      }
      case 'Segment': {
        return REPRESENTATIVE_TABLE_DEFINITION;
      }
      case 'Billing': {
        return BILLING_TABLE_DEFINITION;
      }
      case 'BillingPlan': {
        return BILLING_PLAN_TABLE_DEFINITION;
      }
      case 'BillingDetail': {
        return BILLING_DETAIL_TABLE_DEFINITION;
      }
  }}
}
