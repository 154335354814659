import { CmUiNavbarButton } from '@cm/ui-modules';

export const CONTACT_LIST_LEFT_MENU: CmUiNavbarButton[] = [
];

export const DASHBOARD_BUTTON: CmUiNavbarButton = {
  type: 'appLink',
  routerLink: [''],
  label: 'Dashboard',
};
