export enum LoginResultType {
  ERROR,
  SUCCESS,
  INVALID_GRANT,
  TOO_MANY_TRY,
  NOJWT,
  MULTISESSION,  
}

export interface SuccessLoginResult {
  type: LoginResultType.SUCCESS;
  message?: string;
}

export interface NOJWTLoginResult {
  type: LoginResultType.NOJWT;
  message?: string;
}

export interface MULTISESSIONLoginResult {
  type: LoginResultType.MULTISESSION;
  message?: string;
}

export interface ErrorLoginResult {
  type: LoginResultType.ERROR;
  message?: string;
}

export interface InvalidGrantErrorLoginResult {
  type: LoginResultType.INVALID_GRANT;
  message?: string;
}

export interface TooManyTryLoginResult {
  type: LoginResultType.TOO_MANY_TRY;
  errorDetails: ErrorDetails;
  message?: string;  
}

export interface ErrorDetails {
  numberTry: number;
  penalizedPeriodInMinutes: number;
  penaltyTimeInMinutes: number;
  userBlockedFromUTC: string;
  message?: string;  
}

export type LoginResult = SuccessLoginResult | InvalidGrantErrorLoginResult | TooManyTryLoginResult | ErrorLoginResult | NOJWTLoginResult | MULTISESSIONLoginResult;
