import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

type SnackBarContentStatus = 'success' | 'fail';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
  ) {}

  show(translationKey: string, status: SnackBarContentStatus = 'success'): void {
    this.showWithText(this.translateService.instant(translationKey), status);
  }
  showWithText(text: string, status: SnackBarContentStatus = 'success'): void {
    this.snackBar.open(text, null, {
      panelClass: status === 'fail' ? 'snack-bar-content-fail' : null,
      duration: 5000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
    });
  }
}
