import {Injectable} from '@angular/core';
import { FetchResult } from '@apollo/client';
import {Observable, of, throwError} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AsyncValidationError} from '@cm/ui-modules/lib/cm-ui-form/models';

@Injectable({
  providedIn: 'root',
})
export class ResponseService {
  constructor(private readonly translateService: TranslateService) {}

  responseToDataOrError<T>({ errors, data }: FetchResult<T>): Observable<T> {
    if (errors && errors.length > 0) {
      const error: AsyncValidationError = {
        fieldName: errors[0].message === 'already-exists' && !errors[0].extensions.fieldPath
          ? 'email' :  errors[0].extensions.fieldPath as string,
        message: this.translateService.instant(`responseErrorMsg.${errors[0].message}`),
      };
      return throwError(error);
    } else {
      return of(data);
    }
  }
}
