// @ts-expect-error avoid json file import error
import { version } from '../../package.json';

export const environment = {
  production: true,
  mobileAppsHost: 'https://app.gethighp.com',
  VERSION: version,
  access_token: null,
  refresh_token: null,
  auth_domain: '',
  hashauthtoken: 'dGVzdDp0ZXN0',
  exports: {
    uri: '/api/download',
  },
  //TODO: this credentials are for testsandbox, it should be changed to production
  basic_token_user: {
    name: 'test',
    password: 'test'
  },
  baseHref:'/app',
  assetBasePath: '/app/assets/',
  highp_logo: '/app/assets/highp-logo.png',
};
