import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccessService } from '@cm/ui-modules';
import {
  CloneMessageCampaignGQL,
  DeleteMessageCampaignGQL,
  GetMessageCampaignGQL,
  GetMessageCampaignListGQL,
  GetMessageCampaignListQuery,
  GetMessageCampaignListQueryVariables,
} from '@graphql';
import { Apollo, QueryRef } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { filter as filterOperator, map } from 'rxjs/operators';
import { mapMutationResponseToDeleteResult } from '../../shared/helpers';
import { CampaignList, DeleteResult } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class CampaignsService {
  private campaignsListQuery: QueryRef<GetMessageCampaignListQuery>;

  constructor(
    private apollo: Apollo,
    private getCampaignList: GetMessageCampaignListGQL,
    private deleteMessageCampaignGQL: DeleteMessageCampaignGQL,
    private cloneMessageCampaignGQL: CloneMessageCampaignGQL,
    private messageCampaignGQL: GetMessageCampaignGQL,
    private httpClient: HttpClient,
    private checkAccess: AccessService,
  ) { }

  getCampaigns(variables: GetMessageCampaignListQueryVariables): Observable<CampaignList> {
    this.campaignsListQuery = this.getCampaignList.watch(variables, { errorPolicy: 'all' });
    return this.campaignsListQuery.valueChanges.pipe(
      filterOperator(response => !!response.data),
      map(queryResult => queryResult.data.getMessageCampaignList),
      map(campaignsList => ({
        total: campaignsList.total,
        rows: campaignsList.data.map(row => ({
          id: row.id,
          name: row.name,
          ordered: row.statistics.numberOfOrderedMessages,
          sent: row.statistics.numberOfSentMessages,
          opened: row.statistics.numberOfOpenedMessages,
          canceled: row.statistics.numberOfCancelledMessages,
          language: row.language,
          dateStartUTC: row.dateStartUTC,
          lastModifiedAtUTC: row.lastModifiedAtUTC,
          status: row.status,
          type: row.type,
        })),
      })),
    );
  }

  queryRows(variables: GetMessageCampaignListQueryVariables): void {
    this.campaignsListQuery.resetLastResults();
    this.campaignsListQuery
      .fetchMore({ variables: variables as any })
      .then(fetchMoreResult => {
        this.campaignsListQuery.updateQuery((prev) => fetchMoreResult.data || prev)
      })
      .catch(error => console.warn(error));

  }

  deleteCampaign(id: number): Observable<DeleteResult> {
    return this.deleteMessageCampaignGQL
      .mutate({ id }, { errorPolicy: 'all' })
      .pipe(map(response => mapMutationResponseToDeleteResult(response)));
  }

  deleteMultipleCampaignsByIds(ids: number[]): Observable<DeleteResult> {
    const mutations: string = ids.reduce(
      (previousState, id) => `${previousState} q${id}: deleteMessageCampaign(id: ${id}) `,
      '',
    );
    return this.apollo
      .mutate({
        mutation: gql`mutation { ${mutations} }`,
        errorPolicy: 'all',
      })
      .pipe(map(response => mapMutationResponseToDeleteResult(response)));
  }

  exportCampaign(data) {
    return this.httpClient.post(`/api/campaign/export`, {
      data,
    });
  }

  // exportCampaign(data: string) {
  //   return this.httpClient.post(
  //     `/api/core/campaign/export`,
  //     {
  //       data: data,
  //     },
  //     {
  //       observe: 'response',
  //       responseType: 'blob' as 'json',
  //       headers: this.requestHeaders(),
  //     },
  //   );
  // }

  importCampaign(data: FormData) {
    return this.httpClient.post(`/api/core/campaign/import`, data, {
      observe: 'response',
      responseType: 'arraybuffer',
      headers: this.requestHeaders(),
    });
  }

  private requestHeaders() {
    const access_token = this.checkAccess.getUser().access_token;
    const header = new HttpHeaders();
    return header.set('Authorization', `Bearer ${access_token}`);
  }

  getCampaign(id: number) {
    return this.messageCampaignGQL
      .fetch({ id }, { errorPolicy: 'all' })
      .pipe(map(response => response));
  }

  archiveCampaign(ids: number[]): Observable<any> {
    const mutations: string = ids.reduce(
      (previousState, id) => `${previousState} q${id}: archiveMessageCampaign(id: ${id}) {
        id
      }`,
      '',
    );
    return this.apollo
      .mutate({
        mutation: gql`mutation ArchiveMessageCampaign { ${mutations} }`,
        errorPolicy: 'all',
      })
      .pipe(map(response => mapMutationResponseToDeleteResult(response)));
  }

  cloneCampaign(campaignId: number) {
    return this.cloneMessageCampaignGQL
      .mutate({ campaignId }, { errorPolicy: 'all' })
      .pipe(map(response => response));
  }
}
