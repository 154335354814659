import { Component, Input } from '@angular/core';
import { AccessService } from '@cm/ui-modules';
import { FrontendTranslateService } from '@shared/services/translation.service';
import { MenuTranslator } from '../../classes/MenuTranslator';
import { LoginService } from 'src/app/modules/authenticate/containers/login/login.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  @Input() hasSecondNav = false;
  @Input() title: string;
  constructor(
    translateService: FrontendTranslateService, 
    private readonly menuTranslator: MenuTranslator, 
    private readonly accessService: AccessService,
    private readonly loginService: LoginService
  ) { 
  }

  onLogoutClicked() {
    // this.accessService.removeUser()
    // this.loginService.logout();
  
    window.location.href = '/app/logout';
  }
}
