import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AccountBalance: { input: any; output: any; }
  AutomatorObjectScalar: { input: any; output: any; }
  AutomatorStringObjectRelationDescriptionMapScalar: { input: any; output: any; }
  /** Built-in java.math.BigDecimal */
  BigDecimal: { input: any; output: any; }
  ConcurrentMap_String_TenantDomainRecordScalar: { input: any; output: any; }
  Date: { input: any; output: any; }
  LocalDate: { input: any; output: any; }
  LocalDateTime: { input: string; output: string; }
  LocalTime: { input: string; output: string; }
  Locale: { input: any; output: any; }
  /** Long type */
  Long: { input: any; output: any; }
  ObjectScalar: { input: any; output: any; }
  OffsetDateTime: { input: any; output: any; }
  SegmentExpressionScalar: { input: any; output: any; }
  StringTranslationValueGQLMapScalar: { input: any; output: any; }
  StringWhatsNewDescriptionMapScalar: { input: any; output: any; }
  UUID: { input: any; output: any; }
  YearMonth: { input: any; output: any; }
  ZoneId: { input: any; output: any; }
  ZonedDateTime: { input: any; output: any; }
};

export type AcademicRank =
  | 'DOCTOR'
  | 'NONE'
  | 'PROFESSOR';

export type AccountAggregates = {
  __typename?: 'AccountAggregates';
  asOfFirst?: Maybe<Scalars['BigDecimal']['output']>;
  currentState?: Maybe<Scalars['BigDecimal']['output']>;
  usedAtPeriod?: Maybe<Scalars['BigDecimal']['output']>;
};

export type AccountBalanceChangedEvent = {
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsChange?: Maybe<Scalars['BigDecimal']['output']>;
  estimationNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  periodicBillGQL?: Maybe<PeriodicBillGql>;
  reasonOfChange?: Maybe<Scalars['String']['output']>;
  translationsGQL?: Maybe<TranslationsGql>;
};

/** A page is a sublist of a list of objects. It allows gain information about the position of it in the containing entire list. */
export type AccountBalanceChangedEventPage = {
  __typename?: 'AccountBalanceChangedEventPage';
  /** Returns the page content as List. */
  content?: Maybe<Array<Maybe<AccountBalanceChangedEvent>>>;
  /** File ID for download. */
  fileId?: Maybe<Scalars['String']['output']>;
  /** Returns whether the current Slice is the first one. */
  first: Scalars['Boolean']['output'];
  /** Returns whether the Slice has content at all. */
  hasContent: Scalars['Boolean']['output'];
  /** Returns if there is a next Slice. */
  hasNext: Scalars['Boolean']['output'];
  /** Returns if there is a previous Slice. */
  hasPrevious: Scalars['Boolean']['output'];
  /** Returns whether the current Slice is the last one. */
  last: Scalars['Boolean']['output'];
  /** Returns the number of the current Slice. */
  number: Scalars['Int']['output'];
  /** Returns the number of elements currently on this Slice. */
  numberOfElements: Scalars['Int']['output'];
  /** Returns the size of the Slice. */
  size: Scalars['Int']['output'];
  /** Returns the total amount of elements. */
  totalElements: Scalars['Long']['output'];
  /** Returns the number of total pages. */
  totalPages: Scalars['Int']['output'];
};

export type AccountBalanceDate = {
  __typename?: 'AccountBalanceDate';
  value?: Maybe<Scalars['LocalDateTime']['output']>;
};

export type AccountEventType =
  | 'CHANGE_INSIGHT'
  | 'CHANGE_MESSAGE'
  | 'CHANGE_PRESENTATION'
  | 'INSIGHT'
  | 'INTERNAL_USER'
  | 'MESSAGE'
  | 'PRESENTATION';

export type ActionReferenceType =
  | 'ADD'
  | 'CLEAR'
  | 'REMOVE'
  | 'REPLACE';

export type ActivePresentationApplicationStatistic = {
  __typename?: 'ActivePresentationApplicationStatistic';
  id?: Maybe<Scalars['Long']['output']>;
  numberOfActiveApplications: Scalars['Int']['output'];
  presentationApplicationType?: Maybe<PresentationApplicationType>;
  report?: Maybe<PresentationBillableStatisticsPeriodicReport>;
};

export type ActivePresentationApplicationStatisticGql = {
  __typename?: 'ActivePresentationApplicationStatisticGQL';
  id?: Maybe<Scalars['Long']['output']>;
  numberOfActiveApplications: Scalars['Int']['output'];
  presentationApplicationType?: Maybe<PresentationApplicationType>;
};

export type ActivePresentationApplicationStatisticGqlInput = {
  id?: InputMaybe<Scalars['Long']['input']>;
  numberOfActiveApplications: Scalars['Int']['input'];
  presentationApplicationType?: InputMaybe<PresentationApplicationType>;
};

export type ActivePresentationApplicationTariff = {
  __typename?: 'ActivePresentationApplicationTariff';
  costOfOneActiveApplication?: Maybe<Scalars['BigDecimal']['output']>;
  featuresTariff?: Maybe<PresentationFeaturesTariff>;
  id?: Maybe<Scalars['Long']['output']>;
  presentationApplicationType?: Maybe<PresentationApplicationType>;
};

export type ActivePresentationApplicationTariffAtBillingDay = {
  __typename?: 'ActivePresentationApplicationTariffAtBillingDay';
  costOfOneActiveApplication?: Maybe<Scalars['BigDecimal']['output']>;
  featuresTariffAtBillingDay?: Maybe<PresentationFeaturesTariffAtBillingDay>;
  id?: Maybe<Scalars['Long']['output']>;
  presentationApplicationType?: Maybe<PresentationApplicationType>;
};

export type ActivePresentationApplicationTariffGql = {
  __typename?: 'ActivePresentationApplicationTariffGQL';
  costOfOneActiveApplication?: Maybe<Scalars['BigDecimal']['output']>;
  featuresTariff?: Maybe<PresentationFeaturesTariff>;
  id?: Maybe<Scalars['Long']['output']>;
  presentationApplicationType?: Maybe<PresentationApplicationType>;
};

export type ActivePresentationApplicationTariffGqlInput = {
  costOfOneActiveApplication?: InputMaybe<Scalars['BigDecimal']['input']>;
  featuresTariff?: InputMaybe<PresentationFeaturesTariffInput>;
  id?: InputMaybe<Scalars['Long']['input']>;
  presentationApplicationType?: InputMaybe<PresentationApplicationType>;
};

export type ActivePresentationApplicationTariffInput = {
  costOfOneActiveApplication?: InputMaybe<Scalars['BigDecimal']['input']>;
  featuresTariff?: InputMaybe<PresentationFeaturesTariffInput>;
  id?: InputMaybe<Scalars['Long']['input']>;
  presentationApplicationType?: InputMaybe<PresentationApplicationType>;
};

export type AddAccountBalanceChangedEventGraphQldto = AccountBalanceChangedEvent & {
  __typename?: 'AddAccountBalanceChangedEventGraphQLDTO';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsChange?: Maybe<Scalars['BigDecimal']['output']>;
  estimationNumber?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<AccountEventType>;
  id?: Maybe<Scalars['Long']['output']>;
  periodicBillGQL?: Maybe<PeriodicBillGql>;
  reasonOfChange?: Maybe<Scalars['String']['output']>;
  translationsGQL?: Maybe<TranslationsGql>;
};

export type AddAccountBalanceChangedEventGraphQldtoInput = {
  creditUnitsChange: Scalars['BigDecimal']['input'];
  estimationNumber?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<AccountEventType>;
  id?: InputMaybe<Scalars['Long']['input']>;
  periodicBillGQL?: InputMaybe<PeriodicBillGqlInput>;
  reasonOfChange: Scalars['String']['input'];
  translationsGQL?: InputMaybe<TranslationsGqlInput>;
};

export type ApiFile = {
  __typename?: 'ApiFile';
  ext?: Maybe<Scalars['String']['output']>;
  fileHeight?: Maybe<Scalars['Int']['output']>;
  fileWidth?: Maybe<Scalars['Int']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  flags?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Application =
  | 'ADMIN'
  | 'ADMIN_EXTERNAL'
  | 'ADMIN_PANEL'
  | 'API'
  | 'AUTOMATOR'
  | 'DATATABLE'
  | 'DATUSO_IOS'
  | 'DBE_IOS'
  | 'DBE_PRESENTER_IOS'
  | 'HIGHP_IOS'
  | 'REMOTE_MEETING_VIEWER_EXTERNAL'
  | 'STREAMING_IOS'
  | 'SYNC_IOS'
  | 'VIEWERS_IOS';

export type ApplicationInput =
  | 'ADMIN'
  | 'ADMIN_EXTERNAL'
  | 'ADMIN_PANEL'
  | 'API'
  | 'AUTOMATOR'
  | 'DATATABLE'
  | 'DATUSO_IOS'
  | 'DBE_IOS'
  | 'DBE_PRESENTER_IOS'
  | 'HIGHP_IOS'
  | 'REMOTE_MEETING_VIEWER_EXTERNAL'
  | 'STREAMING_IOS'
  | 'SYNC_IOS'
  | 'VIEWERS_IOS';

export type AutomatorAccountBalanceChangedEvent = AccountBalanceChangedEvent & {
  __typename?: 'AutomatorAccountBalanceChangedEvent';
  automatorPeriodicBill?: Maybe<AutomatorPeriodicBill>;
  automatorPeriodicBillId?: Maybe<Scalars['Long']['output']>;
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsChange?: Maybe<Scalars['BigDecimal']['output']>;
  estimationNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  periodicBillGQL?: Maybe<PeriodicBillGql>;
  reasonOfChange?: Maybe<Scalars['String']['output']>;
  translationsGQL?: Maybe<TranslationsGql>;
};

/**
 * This command adds relation(s) between parent object and specified object by name.
 * In contrast to replace command it doesn't affect existing relations.
 * It is suitable only for plural relations such as OneToMany or ManyToMany. Detailed relation descriptions (along with specified isPlural boolean field) can be found in AutomatorObjectDescription object which is returned by getAutomatorObjectDescriptionList method.
 */
export type AutomatorAddPluralRelationCommand = {
  __typename?: 'AutomatorAddPluralRelationCommand';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  objectIds?: Maybe<AutomatorIntegerListConfigurationField>;
  objectName?: Maybe<Scalars['String']['output']>;
  plural: Scalars['Boolean']['output'];
  relationFieldName?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

/**
 * This command adds relation(s) between parent object and specified object by name.
 * In contrast to replace command it doesn't affect existing relations.
 * It is suitable only for plural relations such as OneToMany or ManyToMany. Detailed relation descriptions (along with specified isPlural boolean field) can be found in AutomatorObjectDescription object which is returned by getAutomatorObjectDescriptionList method.
 */
export type AutomatorAddPluralRelationCommandInput = {
  objectIds?: InputMaybe<AutomatorIntegerListConfigurationFieldInput>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  relationFieldName?: InputMaybe<Scalars['String']['input']>;
};

/** Current api version: 2.1.0-apm */
export type AutomatorApiVersion = {
  __typename?: 'AutomatorApiVersion';
  build?: Maybe<Scalars['String']['output']>;
  major?: Maybe<Scalars['Int']['output']>;
  minor?: Maybe<Scalars['Int']['output']>;
  patch?: Maybe<Scalars['Int']['output']>;
  suffixTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  version?: Maybe<Scalars['String']['output']>;
};

export type AutomatorAvailableWorkflowElementType = {
  __typename?: 'AutomatorAvailableWorkflowElementType';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  isAvailable: Scalars['Boolean']['output'];
  reasons?: Maybe<Array<Maybe<AutomatorWorkflowElementTypeNotAvailableReason>>>;
  targetType?: Maybe<AutomatorWorkflowElementType>;
};

export type AutomatorBillableStatisticsPeriodicReport = {
  __typename?: 'AutomatorBillableStatisticsPeriodicReport';
  automatorLicenceHasBeenEnabled?: Maybe<Scalars['Boolean']['output']>;
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
};

/** This exception specific for sending message indicates that a campaign of given id doesn't exist and the message cannot be sent.  */
export type AutomatorCampaignNotFoundExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorCampaignNotFoundExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorChronoUnit =
  | 'CENTURIES'
  | 'DAYS'
  | 'DECADES'
  | 'ERAS'
  | 'FOREVER'
  | 'HALF_DAYS'
  | 'HOURS'
  | 'MICROS'
  | 'MILLENNIA'
  | 'MILLIS'
  | 'MINUTES'
  | 'MONTHS'
  | 'NANOS'
  | 'SECONDS'
  | 'WEEKS'
  | 'YEARS';

export type AutomatorChronoUnitConfigurationField = {
  __typename?: 'AutomatorChronoUnitConfigurationField';
  type?: Maybe<AutomatorConfigurationFieldType>;
  value?: Maybe<AutomatorChronoUnit>;
  variableId?: Maybe<Scalars['String']['output']>;
};

export type AutomatorChronoUnitConfigurationFieldInput = {
  _type_?: InputMaybe<AutomatorConfigurationFieldTypeDisambiguator>;
  type?: InputMaybe<AutomatorConfigurationFieldType>;
  value?: InputMaybe<AutomatorChronoUnit>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

export type AutomatorClearFieldOperation = {
  __typename?: 'AutomatorClearFieldOperation';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorClearFieldOperationInput = {
  fieldName?: InputMaybe<Scalars['String']['input']>;
};

/** This command erases relation(s) between parent object and specified object by name. It can be applied to both singular (OneToOne, ManyToOne) and plural (OneToMany, ManyToMany) relations. */
export type AutomatorClearRelationCommand = {
  __typename?: 'AutomatorClearRelationCommand';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  objectName?: Maybe<Scalars['String']['output']>;
  relationFieldName?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

/** This command erases relation(s) between parent object and specified object by name. It can be applied to both singular (OneToOne, ManyToOne) and plural (OneToMany, ManyToMany) relations. */
export type AutomatorClearRelationCommandInput = {
  objectName?: InputMaybe<Scalars['String']['input']>;
  relationFieldName?: InputMaybe<Scalars['String']['input']>;
};

export type AutomatorCompoundFilterType =
  | 'EXPRESSION'
  | 'FILTER';

export type AutomatorConfigurationFieldType =
  | 'literal'
  | 'variable';

/** Input type discriminator */
export type AutomatorConfigurationFieldTypeDisambiguator =
  | 'AutomatorDynamicVariableConfigurationField'
  | 'AutomatorLiteralConfigurationField';

/** This trigger reacts to creation event of a ManyToMany or OneToMany relation event but also to OneToOne and OneToMany field value change, e.g. it will be executed when start presentation reference for meeting is updated from null to id=1 and from id=1 to id=2. It will be also executed when for a given contact a Representative is added to representative list. */
export type AutomatorConnectionAddedEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorConnectionAddedEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<Scalars['String']['output']>;
  relatedObjectName?: Maybe<Scalars['String']['output']>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to creation event of a ManyToMany or OneToMany relation event but also to OneToOne and OneToMany field value change, e.g. it will be executed when start presentation reference for meeting is updated from null to id=1 and from id=1 to id=2. It will be also executed when for a given contact a Representative is added to representative list. */
export type AutomatorConnectionAddedEventTriggerWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  relatedObjectName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorConnectionAddedEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorConnectionAddedEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorConnectionAddedEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This trigger reacts to deletion event of a ManyToMany or OneToMany relation event but also to OneToOne and OneToMany field value change, e.g. it will be executed when start presentation reference for meeting is updated from 1 to null and from 1 to 2 as well. It will be also executed when for a given contact a Representative is removed from representative list. */
export type AutomatorConnectionRemovedEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorConnectionRemovedEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<Scalars['String']['output']>;
  relatedObjectName?: Maybe<Scalars['String']['output']>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to deletion event of a ManyToMany or OneToMany relation event but also to OneToOne and OneToMany field value change, e.g. it will be executed when start presentation reference for meeting is updated from 1 to null and from 1 to 2 as well. It will be also executed when for a given contact a Representative is removed from representative list. */
export type AutomatorConnectionRemovedEventTriggerWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  relatedObjectName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorConnectionRemovedEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorConnectionRemovedEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorConnectionRemovedEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This exception specific for webhook action indicates that resulting candidate body for the request could not be converted to JSON. */
export type AutomatorCouldNotBuildRequestBodyExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorCouldNotBuildRequestBodyExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** This idicates that event payload received by the trigger couldn't be converted to dynamic variable values.  */
export type AutomatorCouldNotConvertInputDataExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorCouldNotConvertInputDataExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** This exception specifically occurs when a problem occured while creating a dynamic trigger for 'wait for...' flow element type. */
export type AutomatorCouldNotCreateDynamicTriggerExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorCouldNotCreateDynamicTriggerExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  triggerType?: Maybe<AutomatorDynamicTriggerType>;
};

/** This exception specifically occurs when scheduling a delay was not possible. */
export type AutomatorCouldNotScheduleDelayExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorCouldNotScheduleDelayExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorCreateObjectActionRelationCommands = {
  __typename?: 'AutomatorCreateObjectActionRelationCommands';
  addPluralRelationCommands?: Maybe<Array<Maybe<AutomatorAddPluralRelationCommand>>>;
  upsertSingularRelationCommands?: Maybe<Array<Maybe<AutomatorUpsertSingularRelationCommand>>>;
};

export type AutomatorCreateObjectActionRelationCommandsInput = {
  addPluralRelationCommands?: InputMaybe<Array<InputMaybe<AutomatorAddPluralRelationCommandInput>>>;
  upsertSingularRelationCommands?: InputMaybe<Array<InputMaybe<AutomatorUpsertSingularRelationCommandInput>>>;
};

/** This action allows for generic creation of objects. For relations, field names or object descriptions that will be needed in configuration of this element please use getAutomatorObjectDescriptionList method. */
export type AutomatorCreateObjectActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorCreateObjectActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  data?: Maybe<Array<Maybe<AutomatorObjectFieldData>>>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<Scalars['String']['output']>;
  relationCommands?: Maybe<AutomatorCreateObjectActionRelationCommands>;
  relationTypeValidationInputList?: Maybe<AutomatorRelationCommandValidationInputList>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This action allows for generic creation of objects. For relations, field names or object descriptions that will be needed in configuration of this element please use getAutomatorObjectDescriptionList method. */
export type AutomatorCreateObjectActionWorkflowElementInput = {
  data?: InputMaybe<Array<InputMaybe<AutomatorObjectFieldDataInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  relationCommands?: InputMaybe<AutomatorCreateObjectActionRelationCommandsInput>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorCreateObjectActionWorkflowElementList = {
  __typename?: 'AutomatorCreateObjectActionWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorCreateObjectActionWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This exception specific to object created and updated triggers indicates that current data of object is missing.  */
export type AutomatorCurrentDataIsMissingExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorCurrentDataIsMissingExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorCustomEnumFieldValue = {
  __typename?: 'AutomatorCustomEnumFieldValue';
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AutomatorCustomFieldDetails = {
  __typename?: 'AutomatorCustomFieldDetails';
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Maybe<AutomatorCustomEnumFieldValue>>>;
};

export type AutomatorDateTimeFormat =
  | 'ISO_DATE_TIME'
  | 'ISO_LOCAL_DATE'
  | 'ISO_LOCAL_TIME'
  | 'RFC_1123_DATE_TIME';

export type AutomatorDateWithTimeWithZone = {
  __typename?: 'AutomatorDateWithTimeWithZone';
  date?: Maybe<AutomatorLocalDateConfigurationField>;
  time?: Maybe<AutomatorLocalTimeConfigurationField>;
  zoneId?: Maybe<AutomatorZoneIdConfigurationField>;
};

export type AutomatorDateWithTimeWithZoneInput = {
  date?: InputMaybe<AutomatorLocalDateConfigurationFieldInput>;
  time?: InputMaybe<AutomatorLocalTimeConfigurationFieldInput>;
  zoneId?: InputMaybe<AutomatorZoneIdConfigurationFieldInput>;
};

export type AutomatorDayOfWeek =
  | 'FRIDAY'
  | 'MONDAY'
  | 'SATURDAY'
  | 'SUNDAY'
  | 'THURSDAY'
  | 'TUESDAY'
  | 'WEDNESDAY';

export type AutomatorDayOfWeekSetConfigurationField = {
  __typename?: 'AutomatorDayOfWeekSetConfigurationField';
  type?: Maybe<AutomatorConfigurationFieldType>;
  value?: Maybe<Array<Maybe<AutomatorDayOfWeek>>>;
  variableId?: Maybe<Scalars['String']['output']>;
};

export type AutomatorDayOfWeekSetConfigurationFieldInput = {
  _type_?: InputMaybe<AutomatorConfigurationFieldTypeDisambiguator>;
  type?: InputMaybe<AutomatorConfigurationFieldType>;
  value?: InputMaybe<Array<InputMaybe<AutomatorDayOfWeek>>>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

/** This element freezes service until given conditions are met.  */
export type AutomatorDelayFlowWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorDelayFlowWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  changeValue?: Maybe<AutomatorDurationWithUnit>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  delayType?: Maybe<AutomatorDelayType>;
  hasChangeValue?: Maybe<Scalars['Boolean']['output']>;
  hasTimeWindow?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  timeWindow?: Maybe<AutomatorTimeWindow>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  waitFor?: Maybe<AutomatorDurationWithUnit>;
  waitUntil?: Maybe<AutomatorDateWithTimeWithZone>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This element freezes service until given conditions are met.  */
export type AutomatorDelayFlowWorkflowElementInput = {
  changeValue?: InputMaybe<AutomatorDurationWithUnitInput>;
  delayType?: InputMaybe<AutomatorDelayType>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  timeWindow?: InputMaybe<AutomatorTimeWindowInput>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  waitFor?: InputMaybe<AutomatorDurationWithUnitInput>;
  waitUntil?: InputMaybe<AutomatorDateWithTimeWithZoneInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorDelayFlowWorkflowElementList = {
  __typename?: 'AutomatorDelayFlowWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorDelayFlowWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorDelayType =
  | 'PERIOD'
  | 'UNTIL';

/** This action allows for generic deletion of objects.  */
export type AutomatorDeleteObjectActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorDeleteObjectActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectId?: Maybe<AutomatorIntegerConfigurationField>;
  objectName?: Maybe<Scalars['String']['output']>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This action allows for generic deletion of objects.  */
export type AutomatorDeleteObjectActionWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectId?: InputMaybe<AutomatorIntegerConfigurationFieldInput>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorDeleteObjectActionWorkflowElementList = {
  __typename?: 'AutomatorDeleteObjectActionWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorDeleteObjectActionWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorDurationWithUnit = {
  __typename?: 'AutomatorDurationWithUnit';
  isNegative?: Maybe<Scalars['Boolean']['output']>;
  time?: Maybe<AutomatorLongConfigurationField>;
  unit?: Maybe<AutomatorChronoUnitConfigurationField>;
};

export type AutomatorDurationWithUnitInput = {
  isNegative?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<AutomatorLongConfigurationFieldInput>;
  unit?: InputMaybe<AutomatorChronoUnitConfigurationFieldInput>;
};

export type AutomatorDynamicGetObjectSelection = {
  __typename?: 'AutomatorDynamicGetObjectSelection';
  compoundFilter?: Maybe<AutomatorQueryCompoundFilter>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorDynamicGetObjectSelectionInput = {
  compoundFilter?: InputMaybe<AutomatorQueryCompoundFilterInput>;
};

export type AutomatorDynamicTriggerType =
  | 'FIRE_MANY'
  | 'FIRE_ONCE';

export type AutomatorDynamicVariableCompoundFilter = {
  __typename?: 'AutomatorDynamicVariableCompoundFilter';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  expression?: Maybe<AutomatorDynamicVariableFilterExpression>;
  filter?: Maybe<AutomatorDynamicVariableFilter>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  type?: Maybe<AutomatorCompoundFilterType>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorDynamicVariableCompoundFilterInput = {
  expression?: InputMaybe<AutomatorDynamicVariableFilterExpressionInput>;
  filter?: InputMaybe<AutomatorDynamicVariableFilterInput>;
  type?: InputMaybe<AutomatorCompoundFilterType>;
};

/** Referenced variable doesn't exist for the given element. */
export type AutomatorDynamicVariableDoesntExistException = AutomatorFieldValidationException & {
  __typename?: 'AutomatorDynamicVariableDoesntExistException';
  message?: Maybe<Scalars['String']['output']>;
  propertyPath?: Maybe<Scalars['String']['output']>;
  rejectedValue?: Maybe<Scalars['String']['output']>;
};

export type AutomatorDynamicVariableFilter = {
  __typename?: 'AutomatorDynamicVariableFilter';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  operator?: Maybe<AutomatorRelationalOperator>;
  validation?: Maybe<AutomatorValidation>;
  value?: Maybe<AutomatorObjectConfigurationField>;
  variableId?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorDynamicVariableFilterExpression = {
  __typename?: 'AutomatorDynamicVariableFilterExpression';
  compoundFilters?: Maybe<Array<Maybe<AutomatorDynamicVariableCompoundFilter>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  operator?: Maybe<AutomatorExpressionsOperator>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorDynamicVariableFilterExpressionInput = {
  compoundFilters?: InputMaybe<Array<InputMaybe<AutomatorDynamicVariableCompoundFilterInput>>>;
  operator?: InputMaybe<AutomatorExpressionsOperator>;
};

export type AutomatorDynamicVariableFilterInput = {
  operator?: InputMaybe<AutomatorRelationalOperator>;
  value?: InputMaybe<AutomatorObjectConfigurationFieldInput>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

export type AutomatorDynamicVariableType =
  | 'BOOLEAN'
  | 'DOUBLE'
  | 'ENUM'
  | 'FLOAT'
  | 'INT'
  | 'LIST'
  | 'LOCAL_DATE'
  | 'LOCAL_DATETIME'
  | 'LOCAL_TIME'
  | 'LONG'
  | 'NONE'
  | 'OBJECT'
  | 'STRING'
  | 'TIMESTAMP'
  | 'ZONE_ID';

export type AutomatorElementExecutionVariableDefinition =
  | 'CONNECTION_ADDED_EVENT_FIRST_OBJECT_ID'
  | 'CONNECTION_ADDED_EVENT_SECOND_OBJECT_ID'
  | 'CONNECTION_REMOVED_EVENT_FIRST_OBJECT_ID'
  | 'CONNECTION_REMOVED_EVENT_SECOND_OBJECT_ID'
  | 'EXECUTED_AT_DATE_TIME_UTC'
  | 'GET_OBJECT_LIST_NUMBER'
  | 'GET_OBJECT_LIST_TOTAL'
  | 'QUERY_OBJECT_EXISTS_COUNT'
  | 'QUERY_OBJECT_EXISTS_FIRST_ID'
  | 'QUERY_OBJECT_EXISTS_RESULT'
  | 'SENT_HP_CAMPAIGN_ID'
  | 'SENT_HP_MESSAGE_ID'
  | 'SENT_HP_RECIPIENT_ID'
  | 'SENT_HP_SENDER_ID'
  | 'UPDATED_OBJECT_ID'
  | 'UPDATED_OBJECT_NAME'
  | 'WAIT_FOR_MESSAGE_EVENT_ID'
  | 'WEBHOOK_RESPONSE_BODY'
  | 'WEBHOOK_RESPONSE_CODE'
  | 'WEBHOOK_RESPONSE_IS_ERROR'
  | 'WEBHOOK_RESPONSE_REASON_PHRASE';

export type AutomatorEmailBody = {
  __typename?: 'AutomatorEmailBody';
  html?: Maybe<AutomatorStringConfigurationField>;
  plainText?: Maybe<AutomatorStringConfigurationField>;
  type?: Maybe<AutomatorEmailBodyType>;
};

export type AutomatorEmailBodyInput = {
  html?: InputMaybe<AutomatorStringConfigurationFieldInput>;
  plainText?: InputMaybe<AutomatorStringConfigurationFieldInput>;
  type?: InputMaybe<AutomatorEmailBodyType>;
};

export type AutomatorEmailBodyType =
  | 'HTML'
  | 'HTML_WITH_PLAINTEXT_FALLBACK'
  | 'PLAINTEXT';

/** Update object actions does not have any field or relation actions hence it is an empty action - should be deleted or filled in. */
export type AutomatorEmptyUpdateObjectActionException = AutomatorFieldValidationException & {
  __typename?: 'AutomatorEmptyUpdateObjectActionException';
  message?: Maybe<Scalars['String']['output']>;
  propertyPath?: Maybe<Scalars['String']['output']>;
  rejectedValue?: Maybe<Scalars['String']['output']>;
};

/** Workflow should end with an action element as any flow or trigger element is nonsensical. */
export type AutomatorEndsWithWrongWorkflowElementTypeException = AutomatorTopologyValidationException & {
  __typename?: 'AutomatorEndsWithWrongWorkflowElementTypeException';
  endsWithElement?: Maybe<AutomatorWorkflowElement>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorEnumSpecificFieldOperation = {
  __typename?: 'AutomatorEnumSpecificFieldOperation';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  type?: Maybe<AutomatorEnumSpecificFieldOperationType>;
  values?: Maybe<AutomatorStringListConfigurationField>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorEnumSpecificFieldOperationInput = {
  fieldName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutomatorEnumSpecificFieldOperationType>;
  values?: InputMaybe<AutomatorStringListConfigurationFieldInput>;
};

export type AutomatorEnumSpecificFieldOperationType =
  | 'ADD_TO_EXISTING'
  | 'REMOVE_FROM_EXISTING'
  | 'REPLACE_ALL';

export type AutomatorExpressionsOperator =
  | 'AND'
  | 'EXCEPT'
  | 'OR';

/** This exception informs that an exception relied to external service that automator uses occurred. This is equivalent of not getting 2XX code from a request. */
export type AutomatorExternalServiceInternalErrorExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorExternalServiceInternalErrorExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** This exception informs that an external service that was to be used is unavailable. This is equivalent of a request timeout.  */
export type AutomatorExternalServiceUnavailableExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorExternalServiceUnavailableExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorFeaturesTariff = {
  __typename?: 'AutomatorFeaturesTariff';
  costOfActiveLicense?: Maybe<Scalars['BigDecimal']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastModifiedAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
};

export type AutomatorFeaturesTariffAtBillingDay = {
  __typename?: 'AutomatorFeaturesTariffAtBillingDay';
  costOfActiveLicense?: Maybe<Scalars['BigDecimal']['output']>;
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
};

/**
 * There are illegal cycles between AutomatorWorkflows that may cause infinite executions based on feedback loop, e.g.
 * - workflow #1 is started by node object created event with objectName=A but ends with insert object node with objectName=B;
 * - workflow #2 is started by node object created event with objectName=B but ends with insert object node with objectName=C;
 * - workflow #3 is started by node object created event with objectName=C but ends with insert object node with objectName=A that will dynamictrigger service of workflow #1 which will eventually dynamictrigger workflow #3 again and so on...
 */
export type AutomatorFeedbackLoopBetweenWorkflowsException = AutomatorTopologyValidationException & {
  __typename?: 'AutomatorFeedbackLoopBetweenWorkflowsException';
  illegalCycleFomElement?: Maybe<AutomatorWorkflowElement>;
  illegalCycleToElement?: Maybe<AutomatorWorkflowElement>;
  message?: Maybe<Scalars['String']['output']>;
};

/** (Future) There are illegal cycles between AutomatorWorkflows that may cause infinite executions based on feedback loops in the given Workflow, e.g. workflow starts with object created event node with objectName=A and ends with insert object node with objectName=A. */
export type AutomatorFeedbackLoopInWorkflow = AutomatorTopologyValidationException & {
  __typename?: 'AutomatorFeedbackLoopInWorkflow';
  illegalCycleFomElement?: Maybe<AutomatorWorkflowElement>;
  illegalCycleToElement?: Maybe<AutomatorWorkflowElement>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorFieldInvalidValueReason =
  | 'COLLECTION_IS_EMPTY'
  | 'COLLECTION_SIZE_INVALID'
  | 'CONFIGURATION_FIELD_INVALID'
  | 'DELAY_TYPE_IS_NOT_CONSISTENT_WITH_FIELDS'
  | 'DURATION_WITH_UNIT_IS_NEGATIVE'
  | 'INVALID_EMAIL'
  | 'INVALID_EMAIL_BODY'
  | 'WAIT_FOR_IS_NEGATIVE'
  | 'WAIT_UNTIL_IS_IN_PAST';

/** The field should not be null but it is.  */
export type AutomatorFieldIsNullException = AutomatorFieldValidationException & {
  __typename?: 'AutomatorFieldIsNullException';
  message?: Maybe<Scalars['String']['output']>;
  propertyPath?: Maybe<Scalars['String']['output']>;
  rejectedValue?: Maybe<Scalars['String']['output']>;
};

/** This type of exceptions represents any illegal input for a particular field. */
export type AutomatorFieldValidationException = {
  message?: Maybe<Scalars['String']['output']>;
  propertyPath?: Maybe<Scalars['String']['output']>;
  rejectedValue?: Maybe<Scalars['String']['output']>;
};

export type AutomatorFireOnceTimeEventTriggerConditions = {
  __typename?: 'AutomatorFireOnceTimeEventTriggerConditions';
  fireAfter?: Maybe<Scalars['LocalDateTime']['output']>;
};

export type AutomatorFireOnceTimeEventTriggerConditionsInput = {
  fireAfter?: InputMaybe<Scalars['LocalDateTime']['input']>;
};

export type AutomatorFormattingDetails = {
  __typename?: 'AutomatorFormattingDetails';
  dateTimeFormat?: Maybe<AutomatorDateTimeFormat>;
  decimalPrecision?: Maybe<Scalars['Int']['output']>;
  locale?: Maybe<Scalars['Locale']['output']>;
};

export type AutomatorFormattingDetailsInput = {
  dateTimeFormat?: InputMaybe<AutomatorDateTimeFormat>;
  decimalPrecision?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

/** This action allows for generic getting of objects. Processing semantics: every record sent to this element will produce N records to next elements where N is the size of the record set. */
export type AutomatorGetObjectListActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorGetObjectListActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  dynamicGetObjectSelection?: Maybe<AutomatorDynamicGetObjectSelection>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  manualGetObjectSelection?: Maybe<AutomatorManualGetObjectListSelection>;
  mode?: Maybe<AutomatorGetObjectSelectionMode>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<Scalars['String']['output']>;
  orderBy?: Maybe<AutomatorOrderBy>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This action allows for generic getting of objects. Processing semantics: every record sent to this element will produce N records to next elements where N is the size of the record set. */
export type AutomatorGetObjectListActionWorkflowElementInput = {
  dynamicGetObjectSelection?: InputMaybe<AutomatorDynamicGetObjectSelectionInput>;
  manualGetObjectSelection?: InputMaybe<AutomatorManualGetObjectListSelectionInput>;
  mode?: InputMaybe<AutomatorGetObjectSelectionMode>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<AutomatorOrderByInput>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorGetObjectListActionWorkflowElementList = {
  __typename?: 'AutomatorGetObjectListActionWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorGetObjectListActionWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorGetObjectSelectionMode =
  | 'DYNAMIC'
  | 'MANUAL';

/** This action allows for generic getting of objects. Processing semantics: it works as a enricher ('Content Enricher' as specified in the Enterprise Integration Patterns), so every record sent to this element will be 'joined' with related records from the record set. */
export type AutomatorGetOneObjectActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorGetOneObjectActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  dynamicGetObjectSelection?: Maybe<AutomatorDynamicGetObjectSelection>;
  failIfMoreThanOne?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  manualGetObjectSelection?: Maybe<AutomatorManualGetOneObjectSelection>;
  mode?: Maybe<AutomatorGetObjectSelectionMode>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<Scalars['String']['output']>;
  orderBy?: Maybe<AutomatorOrderBy>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This action allows for generic getting of objects. Processing semantics: it works as a enricher ('Content Enricher' as specified in the Enterprise Integration Patterns), so every record sent to this element will be 'joined' with related records from the record set. */
export type AutomatorGetOneObjectActionWorkflowElementInput = {
  dynamicGetObjectSelection?: InputMaybe<AutomatorDynamicGetObjectSelectionInput>;
  failIfMoreThanOne?: InputMaybe<Scalars['Boolean']['input']>;
  manualGetObjectSelection?: InputMaybe<AutomatorManualGetOneObjectSelectionInput>;
  mode?: InputMaybe<AutomatorGetObjectSelectionMode>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<AutomatorOrderByInput>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorGetOneObjectActionWorkflowElementList = {
  __typename?: 'AutomatorGetOneObjectActionWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorGetOneObjectActionWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This exception is risen if fail on empty is set to true in get object action element. It indicates that specified connection resulted in retrieving empty record set. */
export type AutomatorGotEmptyResultSet = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorGotEmptyResultSet';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** This exception is risen if fail if more than one is set to true. It means that the query returned more than one object and it was strictly expected to return only 1. */
export type AutomatorGotMoreThanOneObject = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorGotMoreThanOneObject';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** There are disconnected elements in the workflow. This is an equivalent of unreachable code. */
export type AutomatorGraphDisconnectedException = AutomatorTopologyValidationException & {
  __typename?: 'AutomatorGraphDisconnectedException';
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorHasCompoundFilterFlowWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorHasCompoundFilterFlowWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  compoundFilter?: Maybe<AutomatorDynamicVariableCompoundFilter>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

export type AutomatorHasDynamicSelectionActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorHasDynamicSelectionActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  dynamicGetObjectSelection?: Maybe<AutomatorDynamicGetObjectSelection>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<Scalars['String']['output']>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

export type AutomatorHasSubstitutionTagsActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorHasSubstitutionTagsActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  formattingDetails?: Maybe<AutomatorFormattingDetails>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  tags?: Maybe<Array<Maybe<AutomatorSubstitutionTag>>>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** There are illegal loops, i.e. those which do not use Loop node, in the given AutomatorWorkflow that may cause infinite executions. */
export type AutomatorIllegalLoopInWorkflowException = AutomatorTopologyValidationException & {
  __typename?: 'AutomatorIllegalLoopInWorkflowException';
  illegalCycleFomElement?: Maybe<AutomatorWorkflowElement>;
  illegalCycleToElement?: Maybe<AutomatorWorkflowElement>;
  message?: Maybe<Scalars['String']['output']>;
};

/** This crud action specific exception is thrown when input given for update or create object action is not valid for some reason.  */
export type AutomatorInputValidationExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorInputValidationExecutionException';
  fieldPath?: Maybe<Scalars['String']['output']>;
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorIntegerConfigurationField = {
  __typename?: 'AutomatorIntegerConfigurationField';
  type?: Maybe<AutomatorConfigurationFieldType>;
  value?: Maybe<Scalars['Int']['output']>;
  variableId?: Maybe<Scalars['String']['output']>;
};

export type AutomatorIntegerConfigurationFieldInput = {
  _type_?: InputMaybe<AutomatorConfigurationFieldTypeDisambiguator>;
  type?: InputMaybe<AutomatorConfigurationFieldType>;
  value?: InputMaybe<Scalars['Int']['input']>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

export type AutomatorIntegerDynamicVariableConfigurationField = {
  __typename?: 'AutomatorIntegerDynamicVariableConfigurationField';
  type?: Maybe<AutomatorConfigurationFieldType>;
  value?: Maybe<Scalars['Int']['output']>;
  variableId: Scalars['String']['output'];
};

export type AutomatorIntegerDynamicVariableConfigurationFieldInput = {
  type?: InputMaybe<AutomatorConfigurationFieldType>;
  value?: InputMaybe<Scalars['Int']['input']>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

export type AutomatorIntegerListConfigurationField = {
  __typename?: 'AutomatorIntegerListConfigurationField';
  type?: Maybe<AutomatorConfigurationFieldType>;
  value?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  variableId?: Maybe<Scalars['String']['output']>;
};

export type AutomatorIntegerListConfigurationFieldInput = {
  _type_?: InputMaybe<AutomatorConfigurationFieldTypeDisambiguator>;
  type?: InputMaybe<AutomatorConfigurationFieldType>;
  value?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

/** The provided field value doens't meet requirements, e.g. a number exceedes maximum allowed value. See reason field for details. */
export type AutomatorInvalidFieldValueException = AutomatorFieldValidationException & {
  __typename?: 'AutomatorInvalidFieldValueException';
  message?: Maybe<Scalars['String']['output']>;
  propertyPath?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<AutomatorFieldInvalidValueReason>;
  rejectedValue?: Maybe<Scalars['String']['output']>;
};

/** The provided field value doens't meet requirements, e.g. email is not correct. See data validation type field for details. */
export type AutomatorLiteralFieldValueInDataFieldInvalidException = AutomatorFieldValidationException & {
  __typename?: 'AutomatorLiteralFieldValueInDataFieldInvalidException';
  dataValidationType?: Maybe<AutomatorLiteralObjectFieldDataValidationType>;
  message?: Maybe<Scalars['String']['output']>;
  propertyPath?: Maybe<Scalars['String']['output']>;
  rejectedValue?: Maybe<Scalars['String']['output']>;
};

export type AutomatorLiteralObjectFieldDataValidationType =
  | 'DATE_FORMAT'
  | 'DATE_TIME_FORMAT'
  | 'EMAIL_FORMAT'
  | 'TIME_FORMAT'
  | 'ZONE_ID';

/** This element routes records to next elements based on evaluation of given conditions for dynamic variables: on true it routesto elements in next elements collection (nextElements) and on false to elements in on false elements collection (onFalseNextElements). */
export type AutomatorLocalChoiceFlowWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorLocalChoiceFlowWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  compoundFilter?: Maybe<AutomatorDynamicVariableCompoundFilter>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  onFalseNextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This element routes records to next elements based on evaluation of given conditions for dynamic variables: on true it routesto elements in next elements collection (nextElements) and on false to elements in on false elements collection (onFalseNextElements). */
export type AutomatorLocalChoiceFlowWorkflowElementInput = {
  compoundFilter?: InputMaybe<AutomatorDynamicVariableCompoundFilterInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  onFalseNextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorLocalChoiceFlowWorkflowElementList = {
  __typename?: 'AutomatorLocalChoiceFlowWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorLocalChoiceFlowWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorLocalDateConfigurationField = {
  __typename?: 'AutomatorLocalDateConfigurationField';
  type?: Maybe<AutomatorConfigurationFieldType>;
  value?: Maybe<Scalars['LocalDate']['output']>;
  variableId?: Maybe<Scalars['String']['output']>;
};

export type AutomatorLocalDateConfigurationFieldInput = {
  _type_?: InputMaybe<AutomatorConfigurationFieldTypeDisambiguator>;
  type?: InputMaybe<AutomatorConfigurationFieldType>;
  value?: InputMaybe<Scalars['LocalDate']['input']>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

/** This element routes records to next elements based on evaluation of given conditions for dynamic variables. */
export type AutomatorLocalFlowFilterFlowWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorLocalFlowFilterFlowWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  compoundFilter?: Maybe<AutomatorDynamicVariableCompoundFilter>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This element routes records to next elements based on evaluation of given conditions for dynamic variables. */
export type AutomatorLocalFlowFilterFlowWorkflowElementInput = {
  compoundFilter?: InputMaybe<AutomatorDynamicVariableCompoundFilterInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorLocalFlowFilterFlowWorkflowElementList = {
  __typename?: 'AutomatorLocalFlowFilterFlowWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorLocalFlowFilterFlowWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorLocalTimeConfigurationField = {
  __typename?: 'AutomatorLocalTimeConfigurationField';
  type?: Maybe<AutomatorConfigurationFieldType>;
  value?: Maybe<Scalars['LocalTime']['output']>;
  variableId?: Maybe<Scalars['String']['output']>;
};

export type AutomatorLocalTimeConfigurationFieldInput = {
  _type_?: InputMaybe<AutomatorConfigurationFieldTypeDisambiguator>;
  type?: InputMaybe<AutomatorConfigurationFieldType>;
  value?: InputMaybe<Scalars['LocalTime']['input']>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

export type AutomatorLongConfigurationField = {
  __typename?: 'AutomatorLongConfigurationField';
  type?: Maybe<AutomatorConfigurationFieldType>;
  value?: Maybe<Scalars['Long']['output']>;
  variableId?: Maybe<Scalars['String']['output']>;
};

export type AutomatorLongConfigurationFieldInput = {
  _type_?: InputMaybe<AutomatorConfigurationFieldTypeDisambiguator>;
  type?: InputMaybe<AutomatorConfigurationFieldType>;
  value?: InputMaybe<Scalars['Long']['input']>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

/** This exception specific for webhook action indicates that resulting request URL value did not conform to the RFC 1738 standard.  */
export type AutomatorMalformedUrlExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorMalformedURLExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type AutomatorManualGetObjectListSelection = {
  __typename?: 'AutomatorManualGetObjectListSelection';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  idFieldName?: Maybe<Scalars['String']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  targetIds?: Maybe<Array<Maybe<Scalars['Long']['output']>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorManualGetObjectListSelectionInput = {
  idFieldName?: InputMaybe<Scalars['String']['input']>;
  targetIds?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
};

export type AutomatorManualGetOneObjectSelection = {
  __typename?: 'AutomatorManualGetOneObjectSelection';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  idFieldName?: Maybe<Scalars['String']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  targetId?: Maybe<Scalars['Long']['output']>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorManualGetOneObjectSelectionInput = {
  idFieldName?: InputMaybe<Scalars['String']['input']>;
  targetId?: InputMaybe<Scalars['Long']['input']>;
};

/** This trigger reacts to a specific meeting related event. If some condition is not set (e.g. meetingNoteIds) all values pass this particular condition.  */
export type AutomatorMeetingEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorMeetingEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  customActionIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  eventType?: Maybe<Scalars['String']['output']>;
  eventValue?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  meetingIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  meetingModeratorIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  meetingNoteIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  meetingParticipantIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  presentationChapterIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  presentationIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  presentationSlideIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to a specific meeting related event. If some condition is not set (e.g. meetingNoteIds) all values pass this particular condition.  */
export type AutomatorMeetingEventTriggerWorkflowElementInput = {
  customActionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  eventValue?: InputMaybe<Scalars['String']['input']>;
  meetingIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  meetingModeratorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  meetingNoteIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  meetingParticipantIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  presentationChapterIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  presentationIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  presentationSlideIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorMeetingEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorMeetingEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorMeetingEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** (Future) There is a Merge element after a Choice element such that it relies on both branches of the choice and it will cause never-ending service of the workflow, i.e. Merge will wait for service of one branch of Choice that will never come. */
export type AutomatorMergeAfterChoiceException = AutomatorTopologyValidationException & {
  __typename?: 'AutomatorMergeAfterChoiceException';
  choiceElement?: Maybe<AutomatorWorkflowElement>;
  mergeElement?: Maybe<AutomatorWorkflowElement>;
  message?: Maybe<Scalars['String']['output']>;
};

/** This trigger reacts to a specific message related event. If some condition is not set (e.g. meetingNoteIds) all values pass this particular condition.  */
export type AutomatorMessageEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorMessageEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  campaignIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  eventType?: Maybe<Scalars['String']['output']>;
  eventValue?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  messageElementIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  messageElementType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  receiverIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  senderIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to a specific message related event. If some condition is not set (e.g. meetingNoteIds) all values pass this particular condition.  */
export type AutomatorMessageEventTriggerWorkflowElementInput = {
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  eventValue?: InputMaybe<Scalars['String']['input']>;
  messageElementIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  messageElementType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  receiverIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  senderIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorMessageEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorMessageEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorMessageEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorMonth =
  | 'APRIL'
  | 'AUGUST'
  | 'DECEMBER'
  | 'FEBRUARY'
  | 'JANUARY'
  | 'JULY'
  | 'JUNE'
  | 'MARCH'
  | 'MAY'
  | 'NOVEMBER'
  | 'OCTOBER'
  | 'SEPTEMBER';

export type AutomatorNameAndEmail = {
  __typename?: 'AutomatorNameAndEmail';
  email?: Maybe<AutomatorStringConfigurationField>;
  name?: Maybe<AutomatorStringConfigurationField>;
};

export type AutomatorNameAndEmailInput = {
  email?: InputMaybe<AutomatorStringConfigurationFieldInput>;
  name?: InputMaybe<AutomatorStringConfigurationFieldInput>;
};

/** There are no elements in the Workflow. */
export type AutomatorNoElementsException = AutomatorTopologyValidationException & {
  __typename?: 'AutomatorNoElementsException';
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorNumericSpecificFieldOperation = {
  __typename?: 'AutomatorNumericSpecificFieldOperation';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  type?: Maybe<AutomatorNumericSpecificFieldOperationType>;
  value?: Maybe<AutomatorLongConfigurationField>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorNumericSpecificFieldOperationInput = {
  fieldName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutomatorNumericSpecificFieldOperationType>;
  value?: InputMaybe<AutomatorLongConfigurationFieldInput>;
};

export type AutomatorNumericSpecificFieldOperationType =
  | 'DECREMENT_BY'
  | 'INCREMENT_BY';

export type AutomatorObjectCrudDetails = {
  __typename?: 'AutomatorObjectCRUDDetails';
  isCreatable?: Maybe<Scalars['Boolean']['output']>;
  isDeletable?: Maybe<Scalars['Boolean']['output']>;
  isGettable?: Maybe<Scalars['Boolean']['output']>;
  isListable?: Maybe<Scalars['Boolean']['output']>;
  isReachable: Scalars['Boolean']['output'];
  isUpdatable?: Maybe<Scalars['Boolean']['output']>;
};

export type AutomatorObjectConfigurationField = {
  __typename?: 'AutomatorObjectConfigurationField';
  type?: Maybe<AutomatorConfigurationFieldType>;
  value?: Maybe<Scalars['AutomatorObjectScalar']['output']>;
  variableId?: Maybe<Scalars['String']['output']>;
};

export type AutomatorObjectConfigurationFieldInput = {
  _type_?: InputMaybe<AutomatorConfigurationFieldTypeDisambiguator>;
  type?: InputMaybe<AutomatorConfigurationFieldType>;
  value?: InputMaybe<Scalars['AutomatorObjectScalar']['input']>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

/** This trigger reacts to creation event of an object specified by name. In order to get list of supported object names please use getAutomatorObjectDescriptionList. */
export type AutomatorObjectCreatedEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorObjectCreatedEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<Scalars['String']['output']>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to creation event of an object specified by name. In order to get list of supported object names please use getAutomatorObjectDescriptionList. */
export type AutomatorObjectCreatedEventTriggerWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorObjectCreatedEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorObjectCreatedEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorObjectCreatedEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This trigger reacts to deletion event of an object specified by name. In order to get list of supported object names please use getAutomatorObjectDescriptionList. */
export type AutomatorObjectDeletedEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorObjectDeletedEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<Scalars['String']['output']>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to deletion event of an object specified by name. In order to get list of supported object names please use getAutomatorObjectDescriptionList. */
export type AutomatorObjectDeletedEventTriggerWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorObjectDeletedEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorObjectDeletedEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorObjectDeletedEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This object describes domain objects only for Automator internal use, so it doesn't intend to be full meta model or give detailed description of the domain, but it should be helpful in configuration of CRUD triggers and actions. */
export type AutomatorObjectDescription = {
  __typename?: 'AutomatorObjectDescription';
  allProperties?: Maybe<Array<Maybe<AutomatorObjectPropertyDescription>>>;
  crudDetails?: Maybe<AutomatorObjectCrudDetails>;
  filtrableProperties?: Maybe<Array<Maybe<AutomatorObjectPropertyDescription>>>;
  gettableProperties?: Maybe<Array<Maybe<AutomatorObjectPropertyDescription>>>;
  hasCustomFields: Scalars['Boolean']['output'];
  idDescription?: Maybe<AutomatorObjectPropertyDescription>;
  insertableProperties?: Maybe<Array<Maybe<AutomatorObjectPropertyDescription>>>;
  isValueObject?: Maybe<Scalars['Boolean']['output']>;
  objectName?: Maybe<Scalars['String']['output']>;
  relations?: Maybe<Array<Maybe<AutomatorObjectRelationDescription>>>;
  relationsByFieldName?: Maybe<Scalars['AutomatorStringObjectRelationDescriptionMapScalar']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  updatableProperties?: Maybe<Array<Maybe<AutomatorObjectPropertyDescription>>>;
};

export type AutomatorObjectDescriptionList = {
  __typename?: 'AutomatorObjectDescriptionList';
  data?: Maybe<Array<Maybe<AutomatorObjectDescription>>>;
};

export type AutomatorObjectDynamicVariableConfigurationField = {
  __typename?: 'AutomatorObjectDynamicVariableConfigurationField';
  type?: Maybe<AutomatorConfigurationFieldType>;
  value?: Maybe<Scalars['AutomatorObjectScalar']['output']>;
  variableId: Scalars['String']['output'];
};

export type AutomatorObjectDynamicVariableConfigurationFieldInput = {
  type?: InputMaybe<AutomatorConfigurationFieldType>;
  value?: InputMaybe<Scalars['AutomatorObjectScalar']['input']>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

/** This object as literal value will accept any primitive json value, e.g. 5, 0.23, "example" or array of primitive values, e.g. [1, 2, 3], ["a", "b", "c"].  */
export type AutomatorObjectFieldData = {
  __typename?: 'AutomatorObjectFieldData';
  fieldName?: Maybe<Scalars['String']['output']>;
  value?: Maybe<AutomatorObjectConfigurationField>;
};

/** This object as literal value will accept any primitive json value, e.g. 5, 0.23, "example" or array of primitive values, e.g. [1, 2, 3], ["a", "b", "c"].  */
export type AutomatorObjectFieldDataInput = {
  fieldName?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<AutomatorObjectConfigurationFieldInput>;
};

/** Referenced field name of an object doesn't exist. Note: not all objects from the API are supported in the AutomatorWorkflowElements. */
export type AutomatorObjectFieldNameDoesntExistException = AutomatorFieldValidationException & {
  __typename?: 'AutomatorObjectFieldNameDoesntExistException';
  message?: Maybe<Scalars['String']['output']>;
  propertyPath?: Maybe<Scalars['String']['output']>;
  rejectedValue?: Maybe<Scalars['String']['output']>;
};

/** Referenced object of a given name doesn't exist. Note: not all objects from the API are supported in the AutomatorWorkflowElements. */
export type AutomatorObjectNameDoesntExistException = AutomatorFieldValidationException & {
  __typename?: 'AutomatorObjectNameDoesntExistException';
  message?: Maybe<Scalars['String']['output']>;
  propertyPath?: Maybe<Scalars['String']['output']>;
  rejectedValue?: Maybe<Scalars['String']['output']>;
};

/** This exception is thrown when description of an object of a given name is requested, e.g. in order to be created, and there is no support for that object in the current schema provider service. */
export type AutomatorObjectNameNotFoundExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorObjectNameNotFoundExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  objectName?: Maybe<Scalars['String']['output']>;
};

/** This crud action specific exception is thrown when id of an object in update or delete object action doesn't exist. */
export type AutomatorObjectOfGivenIdNotFoundExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorObjectOfGivenIdNotFoundExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorObjectPropertyDescription = {
  __typename?: 'AutomatorObjectPropertyDescription';
  crudDetails?: Maybe<AutomatorPropertyCrudDetails>;
  customFieldDetails?: Maybe<AutomatorCustomFieldDetails>;
  enumValues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  hasEnumValues: Scalars['Boolean']['output'];
  isCustomField?: Maybe<Scalars['Boolean']['output']>;
  isEnumOrNestedEnum: Scalars['Boolean']['output'];
  isNullable?: Maybe<Scalars['Boolean']['output']>;
  isObjectType: Scalars['Boolean']['output'];
  isSimpleType: Scalars['Boolean']['output'];
  isSingularObjectType: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  referredFieldName?: Maybe<Scalars['String']['output']>;
  referredObjectName?: Maybe<Scalars['String']['output']>;
  subType?: Maybe<AutomatorDynamicVariableType>;
  type?: Maybe<AutomatorDynamicVariableType>;
  typeName?: Maybe<Scalars['String']['output']>;
};

/** This exception is thrown when description of a property of a given name is requested, e.g. in order to be retrieved, and there is no support for that object property in the current schema provider service. */
export type AutomatorObjectPropertyNotFoundExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorObjectPropertyNotFoundExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  objectName?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
};

export type AutomatorObjectRelationDescription = {
  __typename?: 'AutomatorObjectRelationDescription';
  additionalRelationFields?: Maybe<Array<Maybe<AutomatorObjectPropertyDescription>>>;
  fieldName?: Maybe<Scalars['String']['output']>;
  isPlural?: Maybe<Scalars['Boolean']['output']>;
  objectName?: Maybe<Scalars['String']['output']>;
  referredObjectName?: Maybe<Scalars['String']['output']>;
};

/** This trigger reacts to an update event of an object specified by name. In order to get list of supported object names please use getAutomatorObjectDescriptionList. */
export type AutomatorObjectUpdatedEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorObjectUpdatedEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<Scalars['String']['output']>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to an update event of an object specified by name. In order to get list of supported object names please use getAutomatorObjectDescriptionList. */
export type AutomatorObjectUpdatedEventTriggerWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorObjectUpdatedEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorObjectUpdatedEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorObjectUpdatedEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This exception specific to object updated and deleted triggers indicates that old data of object is missing.  */
export type AutomatorOldDataIsMissingExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorOldDataIsMissingExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** This exception is thrown when despite number of retries, specified in the exception the target system still returns optimistic locking violation. This may happen during very high traffic.  */
export type AutomatorOptimisticLockingExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorOptimisticLockingExecutionException';
  lastVersionUsed?: Maybe<Scalars['Int']['output']>;
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  numberOfRetries?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorOrder = {
  __typename?: 'AutomatorOrder';
  fieldName?: Maybe<Scalars['String']['output']>;
  order?: Maybe<AutomatorOrderType>;
};

export type AutomatorOrderBy = {
  __typename?: 'AutomatorOrderBy';
  orderFieldName?: Maybe<Scalars['String']['output']>;
  orderOperator?: Maybe<AutomatorOrderOperator>;
};

export type AutomatorOrderByInput = {
  orderFieldName?: InputMaybe<Scalars['String']['input']>;
  orderOperator?: InputMaybe<AutomatorOrderOperator>;
};

export type AutomatorOrderInput = {
  fieldName?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<AutomatorOrderType>;
};

export type AutomatorOrderOperator =
  | 'ASCENDING'
  | 'DESCENDING';

export type AutomatorOrderType =
  | 'ASCENDING'
  | 'DESCENDING';

export type AutomatorPeriodicBill = {
  __typename?: 'AutomatorPeriodicBill';
  automatorBillableStatisticsPeriodicReport?: Maybe<AutomatorBillableStatisticsPeriodicReport>;
  automatorFeaturesTariffAtBillingDay?: Maybe<AutomatorFeaturesTariffAtBillingDay>;
  billingPeriod?: Maybe<Scalars['YearMonth']['output']>;
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsForLicenseActivation?: Maybe<Scalars['BigDecimal']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  overallSumOfCreditUnits?: Maybe<Scalars['BigDecimal']['output']>;
};

export type AutomatorPropertyCrudDetails = {
  __typename?: 'AutomatorPropertyCRUDDetails';
  isFiltrable?: Maybe<Scalars['Boolean']['output']>;
  isGettable?: Maybe<Scalars['Boolean']['output']>;
  isInsertable?: Maybe<Scalars['Boolean']['output']>;
  isUpdatable?: Maybe<Scalars['Boolean']['output']>;
};

export type AutomatorPropertyStatus =
  | 'CURRENT'
  | 'OLD';

export type AutomatorQueryCompoundFilter = {
  __typename?: 'AutomatorQueryCompoundFilter';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  expression?: Maybe<AutomatorQueryFilterExpression>;
  filter?: Maybe<AutomatorQueryFilter>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  type?: Maybe<AutomatorCompoundFilterType>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorQueryCompoundFilterInput = {
  expression?: InputMaybe<AutomatorQueryFilterExpressionInput>;
  filter?: InputMaybe<AutomatorQueryFilterInput>;
  type?: InputMaybe<AutomatorCompoundFilterType>;
};

export type AutomatorQueryFilter = {
  __typename?: 'AutomatorQueryFilter';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  /** @deprecated Deprecated */
  objectField?: Maybe<Scalars['String']['output']>;
  objectName?: Maybe<Scalars['String']['output']>;
  operator?: Maybe<AutomatorRelationalOperator>;
  validation?: Maybe<AutomatorValidation>;
  value?: Maybe<AutomatorObjectConfigurationField>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorQueryFilterExpression = {
  __typename?: 'AutomatorQueryFilterExpression';
  compoundFilters?: Maybe<Array<Maybe<AutomatorQueryCompoundFilter>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  operator?: Maybe<AutomatorExpressionsOperator>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorQueryFilterExpressionInput = {
  compoundFilters?: InputMaybe<Array<InputMaybe<AutomatorQueryCompoundFilterInput>>>;
  operator?: InputMaybe<AutomatorExpressionsOperator>;
};

export type AutomatorQueryFilterInput = {
  objectField?: InputMaybe<Scalars['String']['input']>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  operator?: InputMaybe<AutomatorRelationalOperator>;
  value?: InputMaybe<AutomatorObjectConfigurationFieldInput>;
};

/** This action allows for querying whether there is an object that matches conditions specified in this element. It returns variables: was record found, how many records matched given criteria. */
export type AutomatorQueryObjectExistsActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorQueryObjectExistsActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  dynamicGetObjectSelection?: Maybe<AutomatorDynamicGetObjectSelection>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<Scalars['String']['output']>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This action allows for querying whether there is an object that matches conditions specified in this element. It returns variables: was record found, how many records matched given criteria. */
export type AutomatorQueryObjectExistsActionWorkflowElementInput = {
  dynamicGetObjectSelection?: InputMaybe<AutomatorDynamicGetObjectSelectionInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorQueryObjectExistsActionWorkflowElementList = {
  __typename?: 'AutomatorQueryObjectExistsActionWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorQueryObjectExistsActionWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This exception specific to webhook trigger indicates that received payload is technically valid but doesn't conform to the schema provided in the configuration.  */
export type AutomatorReceivedPayloadWithInvalidSchemaExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorReceivedPayloadWithInvalidSchemaExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** This exception specific for sending message indicates that a recipient of the given message doesn't exist and the message cannot be sent. */
export type AutomatorRecipientNotFoundExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorRecipientNotFoundExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorRecurringTimeEventTriggerConditions = {
  __typename?: 'AutomatorRecurringTimeEventTriggerConditions';
  daysOfMonth?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  daysOfWeek?: Maybe<Array<Maybe<AutomatorDayOfWeek>>>;
  hours?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  minutes?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  months?: Maybe<Array<Maybe<AutomatorMonth>>>;
};

export type AutomatorRecurringTimeEventTriggerConditionsInput = {
  daysOfMonth?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  daysOfWeek?: InputMaybe<Array<InputMaybe<AutomatorDayOfWeek>>>;
  hours?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  minutes?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  months?: InputMaybe<Array<InputMaybe<AutomatorMonth>>>;
};

/** Referenced relation command is not valid with a specified reason. */
export type AutomatorRelationCommandException = AutomatorFieldValidationException & {
  __typename?: 'AutomatorRelationCommandException';
  message?: Maybe<Scalars['String']['output']>;
  propertyPath?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<AutomatorRelationCommandExceptionReason>;
  rejectedValue?: Maybe<Scalars['String']['output']>;
};

export type AutomatorRelationCommandExceptionReason =
  | 'RELATION_DOES_NOT_EXIST'
  | 'RELATION_IS_NOT_PLURAL'
  | 'RELATION_IS_NOT_SINGULAR';

export type AutomatorRelationCommandValidationInput = {
  __typename?: 'AutomatorRelationCommandValidationInput';
  declaredAsPlural: Scalars['Boolean']['output'];
  propertyPath?: Maybe<Scalars['String']['output']>;
  relationFieldName?: Maybe<Scalars['String']['output']>;
  targetObjectName?: Maybe<Scalars['String']['output']>;
};

export type AutomatorRelationCommandValidationInputList = {
  __typename?: 'AutomatorRelationCommandValidationInputList';
  empty: Scalars['Boolean']['output'];
  inputs?: Maybe<Array<Maybe<AutomatorRelationCommandValidationInput>>>;
  rootPropertyPath?: Maybe<Scalars['String']['output']>;
  sourceObjectName?: Maybe<Scalars['String']['output']>;
};

export type AutomatorRelationalOperator =
  | 'CONTAINS'
  | 'ENDS_WITH'
  | 'EQUALS'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUALS_TO'
  | 'IN'
  | 'IS_NOT_NULL'
  | 'IS_NULL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUALS_TO'
  | 'NOT_CONTAINS'
  | 'NOT_ENDS_WITH'
  | 'NOT_EQUALS'
  | 'NOT_IN'
  | 'NOT_STARTS_WITH'
  | 'STARTS_WITH';

/**
 * This command removes relation(s) between parent object and specified object by name.
 * In contrast to replace command it doesn't affect existing relations.
 * It is suitable only for plural relations such as OneToMany or ManyToMany. Detailed relation descriptions (along with specified isPlural boolean field) can be found in AutomatorObjectDescription object which is returned by getAutomatorObjectDescriptionList method.
 */
export type AutomatorRemovePluralRelationCommand = {
  __typename?: 'AutomatorRemovePluralRelationCommand';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  objectIds?: Maybe<AutomatorIntegerListConfigurationField>;
  objectName?: Maybe<Scalars['String']['output']>;
  plural: Scalars['Boolean']['output'];
  relationFieldName?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

/**
 * This command removes relation(s) between parent object and specified object by name.
 * In contrast to replace command it doesn't affect existing relations.
 * It is suitable only for plural relations such as OneToMany or ManyToMany. Detailed relation descriptions (along with specified isPlural boolean field) can be found in AutomatorObjectDescription object which is returned by getAutomatorObjectDescriptionList method.
 */
export type AutomatorRemovePluralRelationCommandInput = {
  objectIds?: InputMaybe<AutomatorIntegerListConfigurationFieldInput>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  relationFieldName?: InputMaybe<Scalars['String']['input']>;
};

/**
 * This command replaces relation(s) between parent object and specified object by name.
 * Note: this will erase any current relations and leave only those specified in this command.
 * It is suitable only for plural relations such as OneToMany or ManyToMany. Detailed relation descriptions (along with specified isPlural boolean field) can be found in AutomatorObjectDescription object which is returned by getAutomatorObjectDescriptionList method.
 */
export type AutomatorReplacePluralRelationCommand = {
  __typename?: 'AutomatorReplacePluralRelationCommand';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  objectIds?: Maybe<AutomatorIntegerListConfigurationField>;
  objectName?: Maybe<Scalars['String']['output']>;
  plural: Scalars['Boolean']['output'];
  relationFieldName?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

/**
 * This command replaces relation(s) between parent object and specified object by name.
 * Note: this will erase any current relations and leave only those specified in this command.
 * It is suitable only for plural relations such as OneToMany or ManyToMany. Detailed relation descriptions (along with specified isPlural boolean field) can be found in AutomatorObjectDescription object which is returned by getAutomatorObjectDescriptionList method.
 */
export type AutomatorReplacePluralRelationCommandInput = {
  objectIds?: InputMaybe<AutomatorIntegerListConfigurationFieldInput>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  relationFieldName?: InputMaybe<Scalars['String']['input']>;
};

export type AutomatorReportsSortingOrder =
  | 'ASC'
  | 'DESC';

/** This exception specific for webhook action indicates response body was too large and will not be processed any further. */
export type AutomatorResponseTooLargeExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorResponseTooLargeExecutionException';
  allowedSize?: Maybe<Scalars['String']['output']>;
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  responseSize?: Maybe<Scalars['String']['output']>;
};

export type AutomatorSearchableDynamicVariableValue = {
  __typename?: 'AutomatorSearchableDynamicVariableValue';
  jsonValue?: Maybe<Scalars['String']['output']>;
  searchableValue?: Maybe<Scalars['AutomatorObjectScalar']['output']>;
};

export type AutomatorSearchableDynamicVariableValues = {
  __typename?: 'AutomatorSearchableDynamicVariableValues';
  variableValues?: Maybe<Array<Maybe<AutomatorSearchableDynamicVariableValuesEntry>>>;
};

export type AutomatorSearchableDynamicVariableValuesEntry = {
  __typename?: 'AutomatorSearchableDynamicVariableValuesEntry';
  subType?: Maybe<AutomatorDynamicVariableType>;
  type?: Maybe<AutomatorDynamicVariableType>;
  value?: Maybe<AutomatorSearchableDynamicVariableValue>;
  variableId?: Maybe<Scalars['String']['output']>;
};

export type AutomatorSearchableVariableValuesFilterInput = {
  operator?: InputMaybe<AutomatorSearchableVariableValuesFilterOperator>;
  subType?: InputMaybe<AutomatorDynamicVariableType>;
  type?: InputMaybe<AutomatorDynamicVariableType>;
  value?: InputMaybe<Scalars['AutomatorObjectScalar']['input']>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

export type AutomatorSearchableVariableValuesFilterOperator =
  | 'EXACT_EQUALS'
  | 'LIST_CONTAINS'
  | 'MATCH'
  | 'NOT_EXACT_EQUALS'
  | 'NOT_LIST_CONTAINS'
  | 'NOT_MATCH';

/** Action of sending an email with specified body, list of recipients and a sender. */
export type AutomatorSendEmailActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorSendEmailActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  body?: Maybe<AutomatorEmailBody>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  formattingDetails?: Maybe<AutomatorFormattingDetails>;
  fromName?: Maybe<AutomatorStringConfigurationField>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  subject?: Maybe<AutomatorStringConfigurationField>;
  tags?: Maybe<Array<Maybe<AutomatorSubstitutionTag>>>;
  to?: Maybe<AutomatorNameAndEmail>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** Action of sending an email with specified body, list of recipients and a sender. */
export type AutomatorSendEmailActionWorkflowElementInput = {
  body?: InputMaybe<AutomatorEmailBodyInput>;
  formattingDetails?: InputMaybe<AutomatorFormattingDetailsInput>;
  fromName?: InputMaybe<AutomatorStringConfigurationFieldInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  subject?: InputMaybe<AutomatorStringConfigurationFieldInput>;
  tags?: InputMaybe<Array<InputMaybe<AutomatorSubstitutionTagInput>>>;
  to?: InputMaybe<AutomatorNameAndEmailInput>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorSendEmailActionWorkflowElementList = {
  __typename?: 'AutomatorSendEmailActionWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorSendEmailActionWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** Action of sending a Message of a given campaign from a given sender to a given receiver. */
export type AutomatorSendMessageActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorSendMessageActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  campaignId?: Maybe<AutomatorIntegerConfigurationField>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  recipientId?: Maybe<AutomatorIntegerConfigurationField>;
  sendOnlyOnce?: Maybe<Scalars['Boolean']['output']>;
  senderId?: Maybe<AutomatorIntegerConfigurationField>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** Action of sending a Message of a given campaign from a given sender to a given receiver. */
export type AutomatorSendMessageActionWorkflowElementInput = {
  campaignId?: InputMaybe<AutomatorIntegerConfigurationFieldInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  recipientId?: InputMaybe<AutomatorIntegerConfigurationFieldInput>;
  sendOnlyOnce?: InputMaybe<Scalars['Boolean']['input']>;
  senderId?: InputMaybe<AutomatorIntegerConfigurationFieldInput>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorSendMessageActionWorkflowElementList = {
  __typename?: 'AutomatorSendMessageActionWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorSendMessageActionWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** Action of sending an sms with specified body, recipients and a sender name. */
export type AutomatorSendSmsActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorSendSmsActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  body?: Maybe<AutomatorStringConfigurationField>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  formattingDetails?: Maybe<AutomatorFormattingDetails>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  recipientPhoneNumber?: Maybe<AutomatorStringConfigurationField>;
  senderName?: Maybe<AutomatorStringConfigurationField>;
  tags?: Maybe<Array<Maybe<AutomatorSubstitutionTag>>>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** Action of sending an sms with specified body, recipients and a sender name. */
export type AutomatorSendSmsActionWorkflowElementInput = {
  body?: InputMaybe<AutomatorStringConfigurationFieldInput>;
  formattingDetails?: InputMaybe<AutomatorFormattingDetailsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  recipientPhoneNumber?: InputMaybe<AutomatorStringConfigurationFieldInput>;
  senderName?: InputMaybe<AutomatorStringConfigurationFieldInput>;
  tags?: InputMaybe<Array<InputMaybe<AutomatorSubstitutionTagInput>>>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorSendSmsActionWorkflowElementList = {
  __typename?: 'AutomatorSendSmsActionWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorSendSmsActionWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This exception specific for sending message indicates that a recipient of the given message doesn't exist and the message cannot be sent. */
export type AutomatorSenderNotFoundExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorSenderNotFoundExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** Action that adds a parent record and nested records to be sent by the SFDC Integration service. Sending schedule is managed by the SFDC Integration service. */
export type AutomatorSfdcAddToNestedUploadJobActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorSfdcAddToNestedUploadJobActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nestedJobId?: Maybe<Scalars['Long']['output']>;
  nestedRecordData?: Maybe<Array<Maybe<AutomatorSfdcRecordData>>>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  parentRecordData?: Maybe<AutomatorSfdcRecordData>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** Action that adds a parent record and nested records to be sent by the SFDC Integration service. Sending schedule is managed by the SFDC Integration service. */
export type AutomatorSfdcAddToNestedUploadJobActionWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nestedJobId?: InputMaybe<Scalars['Long']['input']>;
  nestedRecordData?: InputMaybe<Array<InputMaybe<AutomatorSfdcRecordDataInput>>>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  parentRecordData?: InputMaybe<AutomatorSfdcRecordDataInput>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorSfdcAddToNestedUploadJobActionWorkflowElementList = {
  __typename?: 'AutomatorSfdcAddToNestedUploadJobActionWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorSfdcAddToNestedUploadJobActionWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** Action that adds a record to be sent by the SFDC Integration service. Sending schedule is managed by the SFDC Integration service. */
export type AutomatorSfdcAddToUploadJobActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorSfdcAddToUploadJobActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  recordData?: Maybe<AutomatorSfdcRecordData>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** Action that adds a record to be sent by the SFDC Integration service. Sending schedule is managed by the SFDC Integration service. */
export type AutomatorSfdcAddToUploadJobActionWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  recordData?: InputMaybe<AutomatorSfdcRecordDataInput>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorSfdcAddToUploadJobActionWorkflowElementList = {
  __typename?: 'AutomatorSfdcAddToUploadJobActionWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorSfdcAddToUploadJobActionWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This trigger reacts to creation of a sfdc object from the connected org. */
export type AutomatorSfdcObjectCreatedEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorSfdcObjectCreatedEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<AutomatorSfdcReplicatedObjectName>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to creation of a sfdc object from the connected org. */
export type AutomatorSfdcObjectCreatedEventTriggerWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<AutomatorSfdcReplicatedObjectName>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorSfdcObjectCreatedEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorSfdcObjectCreatedEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorSfdcObjectCreatedEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This trigger reacts to deletion of a sfdc object from the connected org. */
export type AutomatorSfdcObjectDeletedEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorSfdcObjectDeletedEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<AutomatorSfdcReplicatedObjectName>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to deletion of a sfdc object from the connected org. */
export type AutomatorSfdcObjectDeletedEventTriggerWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<AutomatorSfdcReplicatedObjectName>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorSfdcObjectDeletedEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorSfdcObjectDeletedEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorSfdcObjectDeletedEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This trigger reacts to an undeletion of a sfdc object from the connected org. */
export type AutomatorSfdcObjectUndeletedEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorSfdcObjectUndeletedEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<AutomatorSfdcReplicatedObjectName>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to an undeletion of a sfdc object from the connected org. */
export type AutomatorSfdcObjectUndeletedEventTriggerWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<AutomatorSfdcReplicatedObjectName>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorSfdcObjectUndeletedEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorSfdcObjectUndeletedEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorSfdcObjectUndeletedEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This trigger reacts to an update of a sfdc object from the connected org. */
export type AutomatorSfdcObjectUpdatedEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorSfdcObjectUpdatedEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectName?: Maybe<AutomatorSfdcReplicatedObjectName>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to an update of a sfdc object from the connected org. */
export type AutomatorSfdcObjectUpdatedEventTriggerWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectName?: InputMaybe<AutomatorSfdcReplicatedObjectName>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorSfdcObjectUpdatedEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorSfdcObjectUpdatedEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorSfdcObjectUpdatedEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorSfdcRecordData = {
  __typename?: 'AutomatorSfdcRecordData';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  data?: Maybe<Array<Maybe<AutomatorObjectFieldData>>>;
  id?: Maybe<Scalars['Long']['output']>;
  internalId?: Maybe<AutomatorIntegerDynamicVariableConfigurationField>;
  internalObjectName?: Maybe<AutomatorStringConfigurationField>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  orderingFieldValue?: Maybe<AutomatorStringConfigurationField>;
  uploadJobId?: Maybe<Scalars['Long']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorSfdcRecordDataInput = {
  data?: InputMaybe<Array<InputMaybe<AutomatorObjectFieldDataInput>>>;
  internalId?: InputMaybe<AutomatorIntegerDynamicVariableConfigurationFieldInput>;
  internalObjectName?: InputMaybe<AutomatorStringConfigurationFieldInput>;
  orderingFieldValue?: InputMaybe<AutomatorStringConfigurationFieldInput>;
  uploadJobId?: InputMaybe<Scalars['Long']['input']>;
};

export type AutomatorSfdcReplicatedObjectName =
  | 'ACCOUNT'
  | 'USER';

/** This trigger reacts to an addition of a connection between an Account and a User from the connected Salesforce org. */
export type AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to an addition of a connection between an Account and a User from the connected Salesforce org. */
export type AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This trigger reacts to removal of a connection between an Account and a User from the connected Salesforce org. */
export type AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to removal of a connection between an Account and a User from the connected Salesforce org. */
export type AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorStringConfigurationField = {
  __typename?: 'AutomatorStringConfigurationField';
  type?: Maybe<AutomatorConfigurationFieldType>;
  value?: Maybe<Scalars['String']['output']>;
  variableId?: Maybe<Scalars['String']['output']>;
};

export type AutomatorStringConfigurationFieldInput = {
  _type_?: InputMaybe<AutomatorConfigurationFieldTypeDisambiguator>;
  type?: InputMaybe<AutomatorConfigurationFieldType>;
  value?: InputMaybe<Scalars['String']['input']>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

export type AutomatorStringListConfigurationField = {
  __typename?: 'AutomatorStringListConfigurationField';
  type?: Maybe<AutomatorConfigurationFieldType>;
  value?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  variableId?: Maybe<Scalars['String']['output']>;
};

export type AutomatorStringListConfigurationFieldInput = {
  _type_?: InputMaybe<AutomatorConfigurationFieldTypeDisambiguator>;
  type?: InputMaybe<AutomatorConfigurationFieldType>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

export type AutomatorSubstitutionTag = {
  __typename?: 'AutomatorSubstitutionTag';
  tag?: Maybe<Scalars['String']['output']>;
  value?: Maybe<AutomatorObjectDynamicVariableConfigurationField>;
};

export type AutomatorSubstitutionTagInput = {
  tag?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<AutomatorObjectDynamicVariableConfigurationFieldInput>;
};

/** This exception specific for handling elements which have subsitution tags and it means that a value of given tag was null and no values should be null by configuration.  */
export type AutomatorSubstitutionValueIsNullException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorSubstitutionValueIsNullException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
};

/** Https should be used instead http. */
export type AutomatorTargetUrlInsecureException = AutomatorFieldValidationException & {
  __typename?: 'AutomatorTargetUrlInsecureException';
  message?: Maybe<Scalars['String']['output']>;
  propertyPath?: Maybe<Scalars['String']['output']>;
  rejectedValue?: Maybe<Scalars['String']['output']>;
};

export type AutomatorTimeEventTriggerType =
  | 'FIRE_ONCE'
  | 'RECURRING';

/** This trigger reacts to a specified conditions in time: passing of a specific moment in time (FIRE_ONCE) or conditions for a given time (RECURRING) that are based on crontab.  */
export type AutomatorTimeEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorTimeEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  fireOnce?: Maybe<AutomatorFireOnceTimeEventTriggerConditions>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  recurring?: Maybe<AutomatorRecurringTimeEventTriggerConditions>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  triggerType?: Maybe<AutomatorTimeEventTriggerType>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
  zoneId?: Maybe<Scalars['ZoneId']['output']>;
};

/** This trigger reacts to a specified conditions in time: passing of a specific moment in time (FIRE_ONCE) or conditions for a given time (RECURRING) that are based on crontab.  */
export type AutomatorTimeEventTriggerWorkflowElementInput = {
  fireOnce?: InputMaybe<AutomatorFireOnceTimeEventTriggerConditionsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  recurring?: InputMaybe<AutomatorRecurringTimeEventTriggerConditionsInput>;
  triggerType?: InputMaybe<AutomatorTimeEventTriggerType>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
  zoneId?: InputMaybe<Scalars['ZoneId']['input']>;
};

export type AutomatorTimeEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorTimeEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorTimeEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorTimeWindow = {
  __typename?: 'AutomatorTimeWindow';
  allowedDaysOfWeek?: Maybe<AutomatorDayOfWeekSetConfigurationField>;
  endTime?: Maybe<AutomatorLocalTimeConfigurationField>;
  startTime?: Maybe<AutomatorLocalTimeConfigurationField>;
  zoneId?: Maybe<AutomatorZoneIdConfigurationField>;
};

export type AutomatorTimeWindowInput = {
  allowedDaysOfWeek?: InputMaybe<AutomatorDayOfWeekSetConfigurationFieldInput>;
  endTime?: InputMaybe<AutomatorLocalTimeConfigurationFieldInput>;
  startTime?: InputMaybe<AutomatorLocalTimeConfigurationFieldInput>;
  zoneId?: InputMaybe<AutomatorZoneIdConfigurationFieldInput>;
};

export type AutomatorTopologyElement = {
  __typename?: 'AutomatorTopologyElement';
  allPossibleNextElements?: Maybe<Array<Maybe<AutomatorTopologyElement>>>;
  id?: Maybe<Scalars['Long']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorTopologyElement>>>;
  timeoutElementWithEmptyTimeoutRouting: Scalars['Boolean']['output'];
};

/** This type of exceptions represents any illegal structure of a given AutomatorWorkflow. */
export type AutomatorTopologyValidationException = {
  message?: Maybe<Scalars['String']['output']>;
};

/** The element has an unexpected in-degree, e.g. a object created dynamictrigger has 1 in-degree and should have 0. */
export type AutomatorUnexpectedInDegreeException = AutomatorTopologyValidationException & {
  __typename?: 'AutomatorUnexpectedInDegreeException';
  element?: Maybe<AutomatorWorkflowElement>;
  expectedInDegree: Scalars['Int']['output'];
  hasInDegree: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

/** This is an umbrella exception for all unknown exceptions that may occur. */
export type AutomatorUnknownExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorUnknownExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** An umbrella exception for all external exceptions with unknown source. */
export type AutomatorUnspecifiedExternalExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorUnspecifiedExternalExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** An unspecified, internal problem occurred during the element service. */
export type AutomatorUnspecifiedInternalExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorUnspecifiedInternalExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorUpdateFieldOperations = {
  __typename?: 'AutomatorUpdateFieldOperations';
  clearFieldOperations?: Maybe<Array<Maybe<AutomatorClearFieldOperation>>>;
  enumSpecificFieldOperations?: Maybe<Array<Maybe<AutomatorEnumSpecificFieldOperation>>>;
  numericSpecificFieldOperations?: Maybe<Array<Maybe<AutomatorNumericSpecificFieldOperation>>>;
  updateWithFieldOperations?: Maybe<Array<Maybe<AutomatorUpdateWithFieldOperation>>>;
};

export type AutomatorUpdateFieldOperationsInput = {
  clearFieldOperations?: InputMaybe<Array<InputMaybe<AutomatorClearFieldOperationInput>>>;
  enumSpecificFieldOperations?: InputMaybe<Array<InputMaybe<AutomatorEnumSpecificFieldOperationInput>>>;
  numericSpecificFieldOperations?: InputMaybe<Array<InputMaybe<AutomatorNumericSpecificFieldOperationInput>>>;
  updateWithFieldOperations?: InputMaybe<Array<InputMaybe<AutomatorUpdateWithFieldOperationInput>>>;
};

export type AutomatorUpdateObjectActionRelationCommands = {
  __typename?: 'AutomatorUpdateObjectActionRelationCommands';
  addPluralRelationCommands?: Maybe<Array<Maybe<AutomatorAddPluralRelationCommand>>>;
  clearRelationCommands?: Maybe<Array<Maybe<AutomatorClearRelationCommand>>>;
  removeRelationCommands?: Maybe<Array<Maybe<AutomatorRemovePluralRelationCommand>>>;
  replaceRelationCommands?: Maybe<Array<Maybe<AutomatorReplacePluralRelationCommand>>>;
  upsertSingularRelationCommands?: Maybe<Array<Maybe<AutomatorUpsertSingularRelationCommand>>>;
};

export type AutomatorUpdateObjectActionRelationCommandsInput = {
  addPluralRelationCommands?: InputMaybe<Array<InputMaybe<AutomatorAddPluralRelationCommandInput>>>;
  clearRelationCommands?: InputMaybe<Array<InputMaybe<AutomatorClearRelationCommandInput>>>;
  removeRelationCommands?: InputMaybe<Array<InputMaybe<AutomatorRemovePluralRelationCommandInput>>>;
  replaceRelationCommands?: InputMaybe<Array<InputMaybe<AutomatorReplacePluralRelationCommandInput>>>;
  upsertSingularRelationCommands?: InputMaybe<Array<InputMaybe<AutomatorUpsertSingularRelationCommandInput>>>;
};

/** This action allows for generic update of objects. For relations, field names or object descriptions that will be needed in configuration of this element please use getAutomatorObjectDescriptionList method. */
export type AutomatorUpdateObjectActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorUpdateObjectActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  fieldOperations?: Maybe<AutomatorUpdateFieldOperations>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  objectId?: Maybe<AutomatorIntegerConfigurationField>;
  objectName?: Maybe<Scalars['String']['output']>;
  relationCommands?: Maybe<AutomatorUpdateObjectActionRelationCommands>;
  relationTypeValidationInputList?: Maybe<AutomatorRelationCommandValidationInputList>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This action allows for generic update of objects. For relations, field names or object descriptions that will be needed in configuration of this element please use getAutomatorObjectDescriptionList method. */
export type AutomatorUpdateObjectActionWorkflowElementInput = {
  fieldOperations?: InputMaybe<AutomatorUpdateFieldOperationsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  objectId?: InputMaybe<AutomatorIntegerConfigurationFieldInput>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  relationCommands?: InputMaybe<AutomatorUpdateObjectActionRelationCommandsInput>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorUpdateObjectActionWorkflowElementList = {
  __typename?: 'AutomatorUpdateObjectActionWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorUpdateObjectActionWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorUpdateWithFieldOperation = {
  __typename?: 'AutomatorUpdateWithFieldOperation';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  value?: Maybe<AutomatorObjectConfigurationField>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorUpdateWithFieldOperationInput = {
  fieldName?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<AutomatorObjectConfigurationFieldInput>;
};

export type AutomatorUpdateWorkflowElementInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

/**
 * This command removes relation(s) between parent object and specified object by name.
 * Note: this will replace or add (if null) current relation realizing upsert semantics. In order to erase relation, please use ClearRelationCommand as this will not accept null values.
 * It is suitable only for singular relations such as OneToOne or ManyToOne. Detailed relation descriptions (along with specified isPlural boolean field) can be found in AutomatorObjectDescription object which is returned by getAutomatorObjectDescriptionList method.
 */
export type AutomatorUpsertSingularRelationCommand = {
  __typename?: 'AutomatorUpsertSingularRelationCommand';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  objectId?: Maybe<AutomatorIntegerConfigurationField>;
  objectName?: Maybe<Scalars['String']['output']>;
  plural: Scalars['Boolean']['output'];
  relationFieldName?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

/**
 * This command removes relation(s) between parent object and specified object by name.
 * Note: this will replace or add (if null) current relation realizing upsert semantics. In order to erase relation, please use ClearRelationCommand as this will not accept null values.
 * It is suitable only for singular relations such as OneToOne or ManyToOne. Detailed relation descriptions (along with specified isPlural boolean field) can be found in AutomatorObjectDescription object which is returned by getAutomatorObjectDescriptionList method.
 */
export type AutomatorUpsertSingularRelationCommandInput = {
  objectId?: InputMaybe<AutomatorIntegerConfigurationFieldInput>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  relationFieldName?: InputMaybe<Scalars['String']['input']>;
};

export type AutomatorValidWorkflowElementVariables = {
  __typename?: 'AutomatorValidWorkflowElementVariables';
  fieldName?: Maybe<Scalars['String']['output']>;
  objectName?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  variables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
};

export type AutomatorValidation = {
  __typename?: 'AutomatorValidation';
  fieldExceptions?: Maybe<Array<Maybe<AutomatorFieldValidationException>>>;
  isValid?: Maybe<Scalars['Boolean']['output']>;
  topologyExceptions?: Maybe<Array<Maybe<AutomatorTopologyValidationException>>>;
};

/** Conversion of variable value caused exception, e.g. forcing long value to integer that is greater than the maximum integer value, parsing date from incorrectly formatted string (according to specified format) or trying to convert string to enum that has no match in the value list. */
export type AutomatorVariableCouldNotBeConvertedExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorVariableCouldNotBeConvertedExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  targetClassName?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  variableId?: Maybe<Scalars['String']['output']>;
  variableSubType?: Maybe<AutomatorDynamicVariableType>;
  variableType?: Maybe<AutomatorDynamicVariableType>;
};

/** Workflow element variable value is already set in the upstream and this means the state of a given workflow element service is corrupt and the processing of it should stop with an exception. */
export type AutomatorVariableValueIsAlreadySetExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorVariableValueIsAlreadySetExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['AutomatorObjectScalar']['output']>;
  variableId?: Maybe<Scalars['String']['output']>;
};

/** Workflow element variable value could not be found in the passed upstream. This could mean that schema changed or there are some fetching problems.  */
export type AutomatorVariableValueOfGivenIdNotFoundExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorVariableValueOfGivenIdNotFoundExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  variableId?: Maybe<Scalars['String']['output']>;
};

export type AutomatorWaitForMessageEventFlowWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorWaitForMessageEventFlowWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  fireOnlyOnFirstOccurrence?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  messageElementIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  messageElementType?: Maybe<Scalars['String']['output']>;
  messageEventType?: Maybe<Scalars['String']['output']>;
  messageEventValue?: Maybe<AutomatorStringConfigurationField>;
  messageId?: Maybe<AutomatorIntegerDynamicVariableConfigurationField>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  onTimeoutNextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  timeoutDuration?: Maybe<AutomatorDurationWithUnit>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

export type AutomatorWaitForMessageEventFlowWorkflowElementInput = {
  fireOnlyOnFirstOccurrence?: InputMaybe<Scalars['Boolean']['input']>;
  messageElementIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  messageElementType?: InputMaybe<Scalars['String']['input']>;
  messageEventType?: InputMaybe<Scalars['String']['input']>;
  messageEventValue?: InputMaybe<AutomatorStringConfigurationFieldInput>;
  messageId?: InputMaybe<AutomatorIntegerDynamicVariableConfigurationFieldInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  onTimeoutNextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  timeoutDuration?: InputMaybe<AutomatorDurationWithUnitInput>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorWaitForMessageEventFlowWorkflowElementList = {
  __typename?: 'AutomatorWaitForMessageEventFlowWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorWaitForMessageEventFlowWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorWebhookActionBodyType =
  | 'JSON';

export type AutomatorWebhookActionHttpHeaderEntry = {
  __typename?: 'AutomatorWebhookActionHttpHeaderEntry';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  validation?: Maybe<AutomatorValidation>;
  value?: Maybe<AutomatorStringConfigurationField>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorWebhookActionHttpHeaderEntryInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<AutomatorStringConfigurationFieldInput>;
};

export type AutomatorWebhookActionHttpRequestMethod =
  | 'DELETE'
  | 'GET'
  | 'POST'
  | 'PUT';

/** Action of performing a genering HTTP request specified in this element. */
export type AutomatorWebhookActionWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorWebhookActionWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  bodyType?: Maybe<AutomatorWebhookActionBodyType>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  failOnResponseError?: Maybe<Scalars['Boolean']['output']>;
  httpHeaderEntries?: Maybe<Array<Maybe<AutomatorWebhookActionHttpHeaderEntry>>>;
  id?: Maybe<Scalars['Long']['output']>;
  jsonRequestBodySchema?: Maybe<AutomatorWebhookJsonRequestBodySchema>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  method?: Maybe<AutomatorWebhookActionHttpRequestMethod>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  url?: Maybe<AutomatorStringConfigurationField>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** Action of performing a genering HTTP request specified in this element. */
export type AutomatorWebhookActionWorkflowElementInput = {
  bodyType?: InputMaybe<AutomatorWebhookActionBodyType>;
  failOnResponseError?: InputMaybe<Scalars['Boolean']['input']>;
  httpHeaderEntries?: InputMaybe<Array<InputMaybe<AutomatorWebhookActionHttpHeaderEntryInput>>>;
  jsonRequestBodySchema?: InputMaybe<AutomatorWebhookJsonRequestBodySchemaInput>;
  method?: InputMaybe<AutomatorWebhookActionHttpRequestMethod>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  url?: InputMaybe<AutomatorStringConfigurationFieldInput>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorWebhookActionWorkflowElementList = {
  __typename?: 'AutomatorWebhookActionWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorWebhookActionWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** This exception specific for webhook action indicates that the request was not successful and will be thrown only if specified in the element to do so. Effectively it is a shorthand for using webhook action with filter on 'is error' dynamic variable. */
export type AutomatorWebhookErrorResponseExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorWebhookErrorResponseExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** This trigger reacts to a specific webhook event which is a http request to a given url with use of specified api key.  */
export type AutomatorWebhookEventTriggerWorkflowElement = AutomatorWorkflowElement & {
  __typename?: 'AutomatorWebhookEventTriggerWorkflowElement';
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  failOnInvalidSchema?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastReceivedSchema?: Maybe<AutomatorWebhookSchema>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  schema?: Maybe<AutomatorWebhookSchema>;
  schemaLastReceivedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  topologyElement?: Maybe<AutomatorTopologyElement>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  webhookApiKey?: Maybe<Scalars['String']['output']>;
  webhookExternalId?: Maybe<Scalars['String']['output']>;
  webhookExternalUrl?: Maybe<Scalars['String']['output']>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** This trigger reacts to a specific webhook event which is a http request to a given url with use of specified api key.  */
export type AutomatorWebhookEventTriggerWorkflowElementInput = {
  failOnInvalidSchema?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextElementReferences?: InputMaybe<Array<InputMaybe<AutomatorWorkflowElementReferenceInput>>>;
  schema?: InputMaybe<AutomatorWebhookSchemaInput>;
  type?: InputMaybe<AutomatorWorkflowElementType>;
  view?: InputMaybe<AutomatorWorkflowElementViewInput>;
  workflowReference?: InputMaybe<AutomatorWorkflowReferenceInput>;
};

export type AutomatorWebhookEventTriggerWorkflowElementList = {
  __typename?: 'AutomatorWebhookEventTriggerWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorWebhookEventTriggerWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorWebhookJsonRequestBodySchema = {
  __typename?: 'AutomatorWebhookJsonRequestBodySchema';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  fields?: Maybe<Array<Maybe<AutomatorWebhookJsonRequestBodySchemaField>>>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorWebhookJsonRequestBodySchemaField = {
  __typename?: 'AutomatorWebhookJsonRequestBodySchemaField';
  children?: Maybe<Array<Maybe<AutomatorWebhookJsonRequestBodySchemaField>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  validation?: Maybe<AutomatorValidation>;
  value?: Maybe<AutomatorObjectConfigurationField>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorWebhookJsonRequestBodySchemaFieldInput = {
  children?: InputMaybe<Array<InputMaybe<AutomatorWebhookJsonRequestBodySchemaFieldInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<AutomatorObjectConfigurationFieldInput>;
};

export type AutomatorWebhookJsonRequestBodySchemaInput = {
  fields?: InputMaybe<Array<InputMaybe<AutomatorWebhookJsonRequestBodySchemaFieldInput>>>;
};

export type AutomatorWebhookSchema = {
  __typename?: 'AutomatorWebhookSchema';
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  fields?: Maybe<Array<Maybe<AutomatorWebhookSchemaField>>>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  type?: Maybe<AutomatorWebhookSchemaType>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorWebhookSchemaField = {
  __typename?: 'AutomatorWebhookSchemaField';
  children?: Maybe<Array<Maybe<AutomatorWebhookSchemaField>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  hasChildren: Scalars['Boolean']['output'];
  isNullAllowed?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  referredFieldName?: Maybe<Scalars['String']['output']>;
  referredObjectName?: Maybe<Scalars['String']['output']>;
  subType?: Maybe<AutomatorDynamicVariableType>;
  type?: Maybe<AutomatorDynamicVariableType>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorWebhookSchemaFieldInput = {
  children?: InputMaybe<Array<InputMaybe<AutomatorWebhookSchemaFieldInput>>>;
  isNullAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  referredFieldName?: InputMaybe<Scalars['String']['input']>;
  referredObjectName?: InputMaybe<Scalars['String']['input']>;
  subType?: InputMaybe<AutomatorDynamicVariableType>;
  type?: InputMaybe<AutomatorDynamicVariableType>;
};

/** Lists of nested objects, i.e. LIST<OBJECT> are not allowed. Also NONE type as primary type and LIST of NONE type. */
export type AutomatorWebhookSchemaFieldTypeIsInvalidException = AutomatorFieldValidationException & {
  __typename?: 'AutomatorWebhookSchemaFieldTypeIsInvalidException';
  message?: Maybe<Scalars['String']['output']>;
  propertyPath?: Maybe<Scalars['String']['output']>;
  rejectedValue?: Maybe<Scalars['String']['output']>;
};

export type AutomatorWebhookSchemaInput = {
  fields?: InputMaybe<Array<InputMaybe<AutomatorWebhookSchemaFieldInput>>>;
  type?: InputMaybe<AutomatorWebhookSchemaType>;
};

/** This exception is specific to webhook event trigger and occurs when fail on invalid schema is set to true and the given payload schema does not match the specified one. */
export type AutomatorWebhookSchemaInvalidExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorWebhookSchemaInvalidExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** Webhook event triggers can consume json inputs. There is no limit in the specification of json that could be applied to this so one has to be set arbitrarily. Currently it is set to: 4 */
export type AutomatorWebhookSchemaNestingLevelTooHighException = AutomatorFieldValidationException & {
  __typename?: 'AutomatorWebhookSchemaNestingLevelTooHighException';
  allowedNestingLevel: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  propertyPath?: Maybe<Scalars['String']['output']>;
  rejectedValue?: Maybe<Scalars['String']['output']>;
};

export type AutomatorWebhookSchemaType =
  | 'FORM'
  | 'JSON';

/** A composite that ties workflow elements together. Note: version of this object reflects changes to its fields and not child objects.  */
export type AutomatorWorkflow = {
  __typename?: 'AutomatorWorkflow';
  copyFromWorkflowId?: Maybe<Scalars['Long']['output']>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  elements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  id?: Maybe<Scalars['Long']['output']>;
  isCopy?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<AutomatorWorkflowState>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
};

/** This interface is shared across all workflow elements. It provides a basic outlet (nextElements) and a relation to workflow. */
export type AutomatorWorkflowElement = {
  allVariables?: Maybe<Array<Maybe<AutomatorWorkflowElementVariable>>>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextElements?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  type?: Maybe<AutomatorWorkflowElementType>;
  validVariables?: Maybe<Array<Maybe<AutomatorValidWorkflowElementVariables>>>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<AutomatorWorkflowElementView>;
  workflow?: Maybe<AutomatorWorkflow>;
};

/** Exception that occurs during workflow element service. It consist of a short message to give the idea to the API consumer and a log id which can be used in a ticket submission if the error is caused by a bug. */
export type AutomatorWorkflowElementExecutionException = {
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorWorkflowElementExecutionState =
  | 'CANCELLED'
  | 'FAILED'
  | 'IN_PROGRESS'
  | 'SCHEDULED'
  | 'SUCCESSFUL'
  | 'TRIGGER_TIMEOUT'
  | 'WAITING'
  | 'YIELDING_RECORDS';

export type AutomatorWorkflowElementExecutionStateChangedEventEntry = {
  __typename?: 'AutomatorWorkflowElementExecutionStateChangedEventEntry';
  createdAtOffset?: Maybe<Scalars['OffsetDateTime']['output']>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  elementExecutionId?: Maybe<Scalars['String']['output']>;
  eventCreatedAtOffset?: Maybe<Scalars['OffsetDateTime']['output']>;
  eventCreatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  exception?: Maybe<AutomatorWorkflowElementExecutionException>;
  filterResult?: Maybe<Scalars['Boolean']['output']>;
  getObjectListIsLast?: Maybe<Scalars['Boolean']['output']>;
  getObjectListNumberOfFetchedRecords?: Maybe<Scalars['Long']['output']>;
  getObjectListOrderNumber?: Maybe<Scalars['Long']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  precedingElementExecutionId?: Maybe<Scalars['String']['output']>;
  relatedTriggeringObjectId?: Maybe<Scalars['String']['output']>;
  scheduledAfterUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  state?: Maybe<AutomatorWorkflowElementExecutionState>;
  triggeringObjectId?: Maybe<Scalars['String']['output']>;
  variableValues?: Maybe<AutomatorSearchableDynamicVariableValues>;
  workflow?: Maybe<AutomatorWorkflow>;
  workflowElement?: Maybe<AutomatorWorkflowElement>;
  workflowElementId?: Maybe<Scalars['Long']['output']>;
  workflowExecutionId?: Maybe<Scalars['String']['output']>;
  workflowId?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorWorkflowElementExecutionStateChangedEventFilterInput = {
  afterCreatedAt?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  beforeCreatedAt?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
  elementVersion?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<AutomatorWorkflowElementExecutionState>;
  variableValuesFilter?: InputMaybe<AutomatorSearchableVariableValuesFilterInput>;
  workflowElementExecutionId?: InputMaybe<Scalars['String']['input']>;
  workflowExecutionId?: InputMaybe<Scalars['String']['input']>;
  workflowId?: InputMaybe<Scalars['Long']['input']>;
  workflowVersion?: InputMaybe<Scalars['Int']['input']>;
};

export type AutomatorWorkflowElementExecutionStateChangedEventList = {
  __typename?: 'AutomatorWorkflowElementExecutionStateChangedEventList';
  data?: Maybe<Array<Maybe<AutomatorWorkflowElementExecutionStateChangedEventEntry>>>;
  scrollId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorWorkflowElementExecutionSummary = {
  __typename?: 'AutomatorWorkflowElementExecutionSummary';
  durationInMs?: Maybe<Scalars['Long']['output']>;
  exception?: Maybe<AutomatorWorkflowElementExecutionException>;
  id?: Maybe<Scalars['String']['output']>;
  inputVariableValues?: Maybe<AutomatorSearchableDynamicVariableValues>;
  lastStatusChangeAtOffset?: Maybe<Scalars['OffsetDateTime']['output']>;
  lastStatusChangeAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  outputVariableValues?: Maybe<AutomatorSearchableDynamicVariableValues>;
  startedAtOffset?: Maybe<Scalars['OffsetDateTime']['output']>;
  startedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  status?: Maybe<AutomatorWorkflowElementExecutionSummaryStatus>;
  workflow?: Maybe<AutomatorWorkflow>;
  workflowElement?: Maybe<AutomatorWorkflowElement>;
  workflowElementId?: Maybe<Scalars['Long']['output']>;
  workflowExecutionId?: Maybe<Scalars['String']['output']>;
  workflowId?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorWorkflowElementExecutionSummaryCountsByStateAggregation = {
  __typename?: 'AutomatorWorkflowElementExecutionSummaryCountsByStateAggregation';
  cancelledCount: Scalars['Long']['output'];
  failedCount: Scalars['Long']['output'];
  inProgressCount: Scalars['Long']['output'];
  successfulCount: Scalars['Long']['output'];
  totalCount: Scalars['Long']['output'];
  triggerTimeoutCount: Scalars['Long']['output'];
};

export type AutomatorWorkflowElementExecutionSummaryFilterInput = {
  elementId?: InputMaybe<Scalars['Long']['input']>;
  inputVariableValuesFilter?: InputMaybe<AutomatorSearchableVariableValuesFilterInput>;
  outputVariableValuesFilter?: InputMaybe<AutomatorSearchableVariableValuesFilterInput>;
  workflowExecutionId?: InputMaybe<Scalars['String']['input']>;
  workflowId?: InputMaybe<Scalars['Long']['input']>;
  workflowVersion?: InputMaybe<Scalars['Int']['input']>;
};

export type AutomatorWorkflowElementExecutionSummaryList = {
  __typename?: 'AutomatorWorkflowElementExecutionSummaryList';
  data?: Maybe<Array<Maybe<AutomatorWorkflowElementExecutionSummary>>>;
  scrollId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorWorkflowElementExecutionSummaryStatus =
  | 'CANCELLED'
  | 'FAILED'
  | 'IN_PROGRESS'
  | 'SUCCESSFUL'
  | 'TRIGGER_TIMEOUT';

export type AutomatorWorkflowElementList = {
  __typename?: 'AutomatorWorkflowElementList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorWorkflowElement>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

/** Provided element configuration is invalid. This may occur when the model changes, e.g. when given object name in create object action no longer exists or when the validation didn't work correctly for some reason.  */
export type AutomatorWorkflowElementNotValidExecutionException = AutomatorWorkflowElementExecutionException & {
  __typename?: 'AutomatorWorkflowElementNotValidExecutionException';
  logId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AutomatorWorkflowElementReferenceInput = {
  id?: InputMaybe<Scalars['Long']['input']>;
};

export type AutomatorWorkflowElementType =
  | 'ConnectionAddedEventTriggerWorkflowElement'
  | 'ConnectionRemovedEventTriggerWorkflowElement'
  | 'CreateObjectActionWorkflowElement'
  | 'DelayFlowWorkflowElement'
  | 'DeleteObjectActionWorkflowElement'
  | 'GetObjectListActionWorkflowElement'
  | 'GetOneObjectActionWorkflowElement'
  | 'LocalChoiceFlowWorkflowElement'
  | 'LocalFlowFilterFlowWorkflowElement'
  | 'MeetingEventTriggerWorkflowElement'
  | 'MessageEventTriggerWorkflowElement'
  | 'ObjectCreatedEventTriggerWorkflowElement'
  | 'ObjectDeletedEventTriggerWorkflowElement'
  | 'ObjectUpdatedEventTriggerWorkflowElement'
  | 'QueryObjectExistsActionWorkflowElement'
  | 'SendEmailActionWorkflowElement'
  | 'SendMessageActionWorkflowElement'
  | 'SendSmsActionWorkflowElement'
  | 'SfdcAddToNestedUploadJobActionWorkflowElement'
  | 'SfdcAddToUploadJobActionWorkflowElement'
  | 'SfdcObjectCreatedEventTriggerWorkflowElement'
  | 'SfdcObjectDeletedEventTriggerWorkflowElement'
  | 'SfdcObjectUndeletedEventTriggerWorkflowElement'
  | 'SfdcObjectUpdatedEventTriggerWorkflowElement'
  | 'SfdcUserAccountConnectionAddedEventTriggerWorkflowElement'
  | 'SfdcUserAccountConnectionRemovedEventTriggerWorkflowElement'
  | 'TimeEventTriggerWorkflowElement'
  | 'UpdateObjectActionWorkflowElement'
  | 'WaitForMessageEventFlowWorkflowElement'
  | 'WebhookActionWorkflowElement'
  | 'WebhookEventTriggerWorkflowElement';

export type AutomatorWorkflowElementTypeNotAvailableReason =
  | 'SFDC_NOT_ENABLED';

export type AutomatorWorkflowElementVariable = {
  __typename?: 'AutomatorWorkflowElementVariable';
  customFieldDetails?: Maybe<AutomatorCustomFieldDetails>;
  enumValues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  executionVariableDefinition?: Maybe<AutomatorElementExecutionVariableDefinition>;
  id?: Maybe<Scalars['String']['output']>;
  isObjectOrigin?: Maybe<Scalars['Boolean']['output']>;
  originElement?: Maybe<AutomatorWorkflowElement>;
  originFieldName?: Maybe<Scalars['String']['output']>;
  originObjectName?: Maybe<Scalars['String']['output']>;
  propertyStatus?: Maybe<AutomatorPropertyStatus>;
  referredFieldName?: Maybe<Scalars['String']['output']>;
  referredObjectName?: Maybe<Scalars['String']['output']>;
  typeName?: Maybe<Scalars['String']['output']>;
  variableSubType?: Maybe<AutomatorDynamicVariableType>;
  variableType?: Maybe<AutomatorDynamicVariableType>;
};

export type AutomatorWorkflowElementView = {
  __typename?: 'AutomatorWorkflowElementView';
  positionX?: Maybe<Scalars['Int']['output']>;
  positionY?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorWorkflowElementViewInput = {
  positionX?: InputMaybe<Scalars['Int']['input']>;
  positionY?: InputMaybe<Scalars['Int']['input']>;
};

export type AutomatorWorkflowExecutionSummary = {
  __typename?: 'AutomatorWorkflowExecutionSummary';
  durationInMs?: Maybe<Scalars['Long']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastElementExecutedAtOffset?: Maybe<Scalars['OffsetDateTime']['output']>;
  lastElementExecutedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  startedAtOffset?: Maybe<Scalars['OffsetDateTime']['output']>;
  startedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  status?: Maybe<AutomatorWorkflowExecutionSummaryStatus>;
  workflow?: Maybe<AutomatorWorkflow>;
  workflowId?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorWorkflowExecutionSummaryCountsByStateAggregation = {
  __typename?: 'AutomatorWorkflowExecutionSummaryCountsByStateAggregation';
  cancelledCount: Scalars['Long']['output'];
  failedCount: Scalars['Long']['output'];
  inProgressCount: Scalars['Long']['output'];
  successfulCount: Scalars['Long']['output'];
  totalCount: Scalars['Long']['output'];
};

export type AutomatorWorkflowExecutionSummaryFilterInput = {
  afterLastElementExecutedAt?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  beforeLastElementExecutedAt?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  status?: InputMaybe<AutomatorWorkflowExecutionSummaryStatus>;
  workflowExecutionId?: InputMaybe<Scalars['String']['input']>;
  workflowId?: InputMaybe<Scalars['Long']['input']>;
  workflowVersion?: InputMaybe<Scalars['Int']['input']>;
};

export type AutomatorWorkflowExecutionSummaryList = {
  __typename?: 'AutomatorWorkflowExecutionSummaryList';
  data?: Maybe<Array<Maybe<AutomatorWorkflowExecutionSummary>>>;
  scrollId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorWorkflowExecutionSummaryStatus =
  | 'CANCELLED'
  | 'FAILED'
  | 'IN_PROGRESS'
  | 'SUCCESSFUL';

/** A composite that ties workflow elements together. Note: version of this object reflects changes to its fields and not child objects.  */
export type AutomatorWorkflowInput = {
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AutomatorWorkflowList = {
  __typename?: 'AutomatorWorkflowList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorWorkflow>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorWorkflowReferenceInput = {
  id?: InputMaybe<Scalars['Long']['input']>;
};

export type AutomatorWorkflowShort = {
  __typename?: 'AutomatorWorkflowShort';
  copyFromWorkflowId?: Maybe<Scalars['Long']['output']>;
  createdAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  isCopy?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedAtUTC?: Maybe<Scalars['ZonedDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<AutomatorWorkflowState>;
  tenantId?: Maybe<Scalars['String']['output']>;
  validation?: Maybe<AutomatorValidation>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AutomatorWorkflowShortList = {
  __typename?: 'AutomatorWorkflowShortList';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<AutomatorWorkflowShort>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<AutomatorOrder>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorWorkflowState =
  | 'STARTED'
  | 'STOPPED';

export type AutomatorWorkflowStateChangeResponse = {
  __typename?: 'AutomatorWorkflowStateChangeResponse';
  state?: Maybe<AutomatorWorkflowState>;
  stateChanged?: Maybe<Scalars['Boolean']['output']>;
  validation?: Maybe<AutomatorValidation>;
  workflowId?: Maybe<Scalars['Long']['output']>;
};

export type AutomatorZoneIdConfigurationField = {
  __typename?: 'AutomatorZoneIdConfigurationField';
  type?: Maybe<AutomatorConfigurationFieldType>;
  value?: Maybe<Scalars['ZoneId']['output']>;
  variableId?: Maybe<Scalars['String']['output']>;
};

export type AutomatorZoneIdConfigurationFieldInput = {
  _type_?: InputMaybe<AutomatorConfigurationFieldTypeDisambiguator>;
  type?: InputMaybe<AutomatorConfigurationFieldType>;
  value?: InputMaybe<Scalars['ZoneId']['input']>;
  variableId?: InputMaybe<Scalars['String']['input']>;
};

export type BatchJob = {
  __typename?: 'BatchJob';
  details?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  instanceSynchronizable?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedAt?: Maybe<Scalars['Date']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
  startedAt?: Maybe<Scalars['Date']['output']>;
  startedById?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  working?: Maybe<Scalars['Boolean']['output']>;
};

export type BatchJobInput = {
  details?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instanceSynchronizable?: InputMaybe<Scalars['Boolean']['input']>;
  lastUpdatedAt?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  startedAt?: InputMaybe<Scalars['Date']['input']>;
  startedById?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  working?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BatchJobList = ObjectsList & {
  __typename?: 'BatchJobList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<BatchJob>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type BatchJobProgress = {
  __typename?: 'BatchJobProgress';
  jobId?: Maybe<Scalars['Int']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
};

export type BillingAgreementType =
  | 'CUSTOM'
  | 'STANDARD';

export type CompoundFilterInput = {
  expression?: InputMaybe<FilterExpressionInput>;
  filter?: InputMaybe<FilterInput>;
  type: CompoundFilterType;
};

export type CompoundFilterType =
  | 'EXPRESSION'
  | 'FILTER';

export type ConstraintViolationGraphQlapiException = {
  __typename?: 'ConstraintViolationGraphQLAPIException';
  fieldPath?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Contact = {
  __typename?: 'Contact';
  /** The academic rank of the contact */
  academicRank?: Maybe<AcademicRank>;
  /** The business title of the contact */
  businessTitle?: Maybe<Scalars['String']['output']>;
  /** The custom fields of the contact defined in the system */
  customFieldList?: Maybe<Array<Maybe<ContactCustomField>>>;
  /** The email of the contact */
  email?: Maybe<Scalars['String']['output']>;
  emailStatus?: Maybe<Scalars['String']['output']>;
  /** The external id of the contact (e.g. id from external system that data was imported from) */
  externalId?: Maybe<Scalars['String']['output']>;
  /** The first name of the contact */
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  /** The gender of the contact */
  gender?: Maybe<Gender>;
  /** The groups that the contact is assigned to */
  groupList?: Maybe<Array<Maybe<Group>>>;
  /** The total number of groups that the contact is assigned to */
  groupListTotal: Scalars['Long']['output'];
  /** The date when messages will not be sent to the contact */
  highpCancelUntilUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  /** The id of the contact */
  id: Scalars['Int']['output'];
  /** The last name of the contact */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The meetings that the contact participated in */
  meetingList?: Maybe<Array<Maybe<Meeting>>>;
  /** The total number of meeting that the contact participated in */
  meetingListTotal: Scalars['Long']['output'];
  /** The mobile phone number of the contact */
  phone?: Maybe<Scalars['String']['output']>;
  phoneNumberStatus?: Maybe<PhoneNumberStatus>;
  /** The representatives that the contact is assigned to */
  representativeList?: Maybe<Array<Maybe<Representative>>>;
  /** The total number of representatives that the contact is assigned to */
  representativeListTotal: Scalars['Long']['output'];
  /** The external id from Salesforce.com */
  salesforceId?: Maybe<Scalars['String']['output']>;
  /** The total number of specializations that the contact is assigned to */
  specializationList?: Maybe<Array<Maybe<Specialization>>>;
  /** The total number of specializations that the contact is assigned to */
  specializationListTotal: Scalars['Long']['output'];
  version?: Maybe<Scalars['Int']['output']>;
  /** The workplaces that the contact is assigned to */
  workplaceList?: Maybe<Array<Maybe<Workplace>>>;
  /** The total number of workplaces that the contact is assigned to */
  workplaceListTotal: Scalars['Long']['output'];
};


export type ContactGroupListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContactMeetingListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContactRepresentativeListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContactSpecializationListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ContactWorkplaceListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactActionReferenceInput = {
  action: ActionReferenceType;
  idList?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ContactCustomField = {
  __typename?: 'ContactCustomField';
  fieldName?: Maybe<Scalars['String']['output']>;
  fieldType?: Maybe<FormFieldDefinitionType>;
  type?: Maybe<Scalars['String']['output']>;
  valueList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ContactCustomFieldInput = {
  fieldName: Scalars['String']['input'];
  valueList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContactInput = {
  /** The academic rank of the contact */
  academicRank?: InputMaybe<AcademicRank>;
  /**
   * The business title of the contact
   * Validations
   * Max length: 250
   */
  businessTitle?: InputMaybe<Scalars['String']['input']>;
  /** The custom fields of the contact defined in the system */
  customFieldList?: InputMaybe<Array<InputMaybe<ContactCustomFieldInput>>>;
  /**
   * The email of the contact
   * Validations
   * Unique: Value has to be unique across all contacts, representatives and product managers
   * Format: Value has to in email format e.g. name@company.com
   * Max length: 255
   */
  email?: InputMaybe<Scalars['String']['input']>;
  emailStatus?: InputMaybe<Scalars['String']['input']>;
  /**
   * The external id of the contact (e.g. id from external system that data was imported from)
   * Validations
   * Unique: Value has to be unique across all contacts, representatives and product managers
   * Max length: 50
   */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The first name of the contact
   * Validations
   * Max length: 255
   */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The gender of the contact */
  gender?: InputMaybe<Gender>;
  /** The groups that the contact is assigned to */
  groupActionReferenceList?: InputMaybe<Array<InputMaybe<GroupActionReferenceInput>>>;
  /**
   * The date when messages will not be sent to the contact
   * Validations
   * Format: Value has to be in ISO8601 format
   */
  highpCancelUntilUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
  /**
   * The last name of the contact
   * Validations
   * Max length: 255
   */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** The meetings that the contact participated in */
  meetingActionReferenceList?: InputMaybe<Array<InputMaybe<MeetingActionReferenceInput>>>;
  /**
   * The mobile phone number of the contact
   * Validations
   * Max length: 50
   */
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneNumberStatus?: InputMaybe<PhoneNumberStatus>;
  /** The representatives that the contact is assigned to */
  representativeActionReferenceList?: InputMaybe<Array<InputMaybe<RepresentativeActionReferenceInput>>>;
  /**
   * The external id from Salesforce.com
   * Validations
   * Max length: 255
   */
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  /** The sepcializations that the contact is assigned to */
  specializationActionReferenceList?: InputMaybe<Array<InputMaybe<SpecializationActionReferenceInput>>>;
  /** The workplaces that the contact is assigned to */
  workplaceActionReferenceList?: InputMaybe<Array<InputMaybe<WorkplaceActionReferenceInput>>>;
};

export type ContactList = ObjectsList & {
  __typename?: 'ContactList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Contact>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type ContactReferenceInput = {
  id: Scalars['Int']['input'];
};

export type CustomActionDefinition = {
  __typename?: 'CustomActionDefinition';
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type CustomActionDefinitionInput = {
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CustomActionDefinitionList = ObjectsList & {
  __typename?: 'CustomActionDefinitionList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<CustomActionDefinition>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type CustomFieldDefinition = {
  __typename?: 'CustomFieldDefinition';
  graphQLEnumDefinition?: Maybe<GraphQlEnumDefinition>;
  graphQLFieldDefinition?: Maybe<GraphQlFieldDefinition>;
  id?: Maybe<Scalars['Int']['output']>;
  isactive?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<FormFieldDefinitionType>;
  values?: Maybe<Array<Maybe<EnumValue>>>;
};

export type CustomFieldDefinitionEntry = {
  __typename?: 'CustomFieldDefinitionEntry';
  customFieldDefinitionList?: Maybe<Array<Maybe<CustomFieldDefinition>>>;
  objectName?: Maybe<Scalars['String']['output']>;
};

export type CustomFieldDefinitionInput = {
  graphQLEnumDefinition?: InputMaybe<GraphQlEnumDefinitionInput>;
  graphQLFieldDefinition?: InputMaybe<GraphQlFieldDefinitionInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isactive?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<FormFieldDefinitionType>;
  values?: InputMaybe<Array<InputMaybe<EnumValueInput>>>;
};

export type CustomFieldDefinitionList = ObjectsList & {
  __typename?: 'CustomFieldDefinitionList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<CustomFieldDefinition>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type CustomFieldDefinitionResponse = {
  __typename?: 'CustomFieldDefinitionResponse';
  customFieldDefinitionEntryList?: Maybe<Array<Maybe<CustomFieldDefinitionEntry>>>;
};

export type DegreeOfSeparation = {
  __typename?: 'DegreeOfSeparation';
  objectName?: Maybe<Scalars['String']['output']>;
  relatedEntityList?: Maybe<Array<Maybe<DegreeOfSeparationEntry>>>;
};

export type DegreeOfSeparationEntry = {
  __typename?: 'DegreeOfSeparationEntry';
  degreeOfSeparation: Scalars['Int']['output'];
  objectName?: Maybe<Scalars['String']['output']>;
};

/** Logic operator. */
export type ELogicOperator =
  | 'AND'
  | 'OR';

/** Value operator. */
export type EValueOperator =
  | 'EQUAL'
  | 'GREATER'
  | 'GREATER_EQUAL'
  | 'LESS'
  | 'LESS_EQUAL'
  | 'LIKE'
  | 'NOT_EQUAL'
  | 'NOT_LIKE';

export type EnumValue = {
  __typename?: 'EnumValue';
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EnumValueInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ExistsActionReferenceConstraintViolationGraphQlapiException = {
  __typename?: 'ExistsActionReferenceConstraintViolationGraphQLAPIException';
  fieldPath?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  objectName?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  wrongId?: Maybe<Scalars['ObjectScalar']['output']>;
};

export type ExistsWithIdConstraintViolationGraphQlapiException = {
  __typename?: 'ExistsWithIdConstraintViolationGraphQLAPIException';
  fieldPath?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  objectName?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  wrongId?: Maybe<Scalars['ObjectScalar']['output']>;
};

export type ExportTask = {
  __typename?: 'ExportTask';
  alreadyStarted?: Maybe<Scalars['Boolean']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ExportTypes =
  | 'CONTACTS_XLS'
  | 'FIELD_DEFINITIONS_XLS'
  | 'GROUPS_XLS'
  | 'LEADS_XLS'
  | 'SPECIALIZATIONS_XLS'
  | 'WORKPLACES_XLS';

/** Operators defining an expression type */
export type ExpressionOperator =
  /** AND operator, (needs only 'expressions') */
  | 'AND'
  /** SQL 'LIKE' operator, with '%' applied to the beginig and end of the value (needs only 'key' and 'value') */
  | 'CONTAINS'
  /** Projection operator, defines a new join for the object (needs 'key' and only one expression on '0' index of 'expressions') */
  | 'DOT'
  /** Operator not implemented */
  | 'ENDS_WITH'
  /** SQL EQUAL operator, (needs only 'key' and 'value') */
  | 'EQUALS'
  /** SQL GREATER operator, (needs only 'key' and 'value') */
  | 'GREATER_THAN'
  /** SQL GREATER_EQUAL operator, (needs only 'key' and 'value') */
  | 'GREATER_THAN_OR_EQUALS_TO'
  /** SQL 'IN' operator, checks for multiple values (uses ';' as value separator, needs only 'key' and 'value') */
  | 'IN'
  /** SQL 'IS NOT NULL' operator, (needs only 'key') */
  | 'IS_NOT_NULL'
  /** SQL 'IS NULL' operator (needs only 'key') */
  | 'IS_NULL'
  /** SQL LESS operator, (needs only 'key' and 'value') */
  | 'LESS_THAN'
  /** SQL LESS_EQUAL operator, (needs only 'key' and 'value') */
  | 'LESS_THAN_OR_EQUALS_TO'
  /** SQL LIKE operator, (needs only 'key' and 'value') */
  | 'LIKE'
  /** The negation operator, only works on the first expression (needs only one expression on '0' index of 'expressions') */
  | 'NOT'
  /** SQL 'NOT LIKE' operator, with '%' applied to the beginig and end of the value (needs only 'key' and 'value') */
  | 'NOT_CONTAINS'
  /** Operator not implemented */
  | 'NOT_ENDS_WITH'
  /** SQL NOT EQUAL operator, (needs only 'key' and 'value') */
  | 'NOT_EQUALS'
  /** SQL NOT LIKE operator, (needs only 'key' and 'value') */
  | 'NOT_LIKE'
  /** Operator not implemented */
  | 'NOT_STARTS_WITH'
  /** OR operator, (needs only 'expressions') */
  | 'OR'
  /** Operator not implemented */
  | 'STARTS_WITH';

export type ExpressionType =
  | 'AND'
  | 'EXCEPT'
  | 'OR';

export type FieldOrder = {
  __typename?: 'FieldOrder';
  objectFieldOrderList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  objectName?: Maybe<Scalars['String']['output']>;
};

export type FilterExpressionInput = {
  compoundFilterList: Array<InputMaybe<CompoundFilterInput>>;
  expressionType: ExpressionType;
};

export type FilterInput = {
  objectField: Scalars['String']['input'];
  objectName: Scalars['String']['input'];
  operator: FilterOperator;
  value?: InputMaybe<Scalars['ObjectScalar']['input']>;
};

export type FilterOperator =
  | 'CONTAINS'
  | 'ENDS_WITH'
  | 'EQUALS'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUALS_TO'
  | 'IN'
  | 'IS_NOT_NULL'
  | 'IS_NULL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUALS_TO'
  | 'NOT_CONTAINS'
  | 'NOT_ENDS_WITH'
  | 'NOT_EQUALS'
  | 'NOT_IN'
  | 'NOT_STARTS_WITH'
  | 'STARTS_WITH';

export type Form = {
  __typename?: 'Form';
  configuration?: Maybe<Scalars['String']['output']>;
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  formType?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastModifiedAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type FormFieldDefinitionType =
  | 'BUTTON'
  | 'CHECKBOX'
  | 'COLOR'
  | 'DATE'
  | 'DATETIME'
  | 'DATETIME_LOCAL'
  | 'EMAIL'
  | 'FILE'
  | 'HIDDEN'
  | 'IMAGE'
  | 'MONTH'
  | 'MULTIPLE_SELECT'
  | 'NUMBER'
  | 'PASSWORD'
  | 'PHONE'
  | 'RADIO'
  | 'RANGE'
  | 'RESET'
  | 'SEARCH'
  | 'SIGNATURE'
  | 'SINGLE_SELECT'
  | 'SUBMIT'
  | 'TEL'
  | 'TEXT'
  | 'TEXTAREA'
  | 'TIME'
  | 'URL'
  | 'WEEK';

export type Gender =
  | 'FEMALE'
  | 'MALE';

export type GraphQlapiException = {
  __typename?: 'GraphQLAPIException';
  message?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GraphQlEnumDefinition = {
  __typename?: 'GraphQLEnumDefinition';
  description?: Maybe<Scalars['String']['output']>;
  enumValues?: Maybe<Array<Maybe<GraphQlEnumValue>>>;
  fields?: Maybe<Scalars['ObjectScalar']['output']>;
  inputFields?: Maybe<Scalars['ObjectScalar']['output']>;
  interfaces?: Maybe<Scalars['ObjectScalar']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  possibleTypes?: Maybe<Scalars['ObjectScalar']['output']>;
};

export type GraphQlEnumDefinitionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enumValues?: InputMaybe<Array<InputMaybe<GraphQlEnumValueInput>>>;
  fields?: InputMaybe<Scalars['ObjectScalar']['input']>;
  inputFields?: InputMaybe<Scalars['ObjectScalar']['input']>;
  interfaces?: InputMaybe<Scalars['ObjectScalar']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  possibleTypes?: InputMaybe<Scalars['ObjectScalar']['input']>;
};

export type GraphQlEnumValue = {
  __typename?: 'GraphQLEnumValue';
  deprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type GraphQlEnumValueInput = {
  deprecated: Scalars['Boolean']['input'];
  deprecationReason?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GraphQlFieldDefinition = {
  __typename?: 'GraphQLFieldDefinition';
  args?: Maybe<Array<Maybe<Scalars['ObjectScalar']['output']>>>;
  deprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<GraphQlTypeDefinition>;
};

export type GraphQlFieldDefinitionInput = {
  args?: InputMaybe<Array<InputMaybe<Scalars['ObjectScalar']['input']>>>;
  deprecated: Scalars['Boolean']['input'];
  deprecationReason?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<GraphQlTypeDefinitionInput>;
};

export type GraphQlTypeDefinition = {
  __typename?: 'GraphQLTypeDefinition';
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ofType?: Maybe<GraphQlTypeDefinition>;
};

export type GraphQlTypeDefinitionInput = {
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ofType?: InputMaybe<GraphQlTypeDefinitionInput>;
};

export type Group = {
  __typename?: 'Group';
  /** The contacts that group is assigned to */
  contactList?: Maybe<Array<Maybe<Contact>>>;
  /** The total number of contacts that group is assigned to */
  contactListTotal: Scalars['Long']['output'];
  /** The id of the group */
  id: Scalars['Int']['output'];
  /** The name of the group */
  name?: Maybe<Scalars['String']['output']>;
  representativeList?: Maybe<Array<Maybe<Representative>>>;
  representativeListTotal: Scalars['Long']['output'];
  version?: Maybe<Scalars['Int']['output']>;
};


export type GroupContactListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type GroupRepresentativeListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupActionReferenceInput = {
  action: ActionReferenceType;
  idList?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type GroupInput = {
  /** The contacts that the group is assigned to */
  contactActionReferenceList?: InputMaybe<Array<InputMaybe<ContactActionReferenceInput>>>;
  /**
   * The name of the group
   * Validations
   * Max length: 255
   */
  name?: InputMaybe<Scalars['String']['input']>;
  representativeActionReferenceList?: InputMaybe<Array<InputMaybe<RepresentativeActionReferenceInput>>>;
};

export type GroupList = ObjectsList & {
  __typename?: 'GroupList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Group>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type InsightAccountBalanceChangedEvent = AccountBalanceChangedEvent & {
  __typename?: 'InsightAccountBalanceChangedEvent';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsChange?: Maybe<Scalars['BigDecimal']['output']>;
  estimationNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  insightPeriodicBill?: Maybe<InsightPeriodicBill>;
  insightPeriodicBillId?: Maybe<Scalars['Long']['output']>;
  periodicBillGQL?: Maybe<PeriodicBillGql>;
  reasonOfChange?: Maybe<Scalars['String']['output']>;
  translationsGQL?: Maybe<TranslationsGql>;
};

export type InsightBillableStatisticsPeriodicReport = {
  __typename?: 'InsightBillableStatisticsPeriodicReport';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  insightLicenceHasBeenEnabled: Scalars['Boolean']['output'];
  numberOfActiveInsightApplication: Scalars['Int']['output'];
  numberOfPublishedForms: Scalars['Int']['output'];
};

export type InsightBillableStatisticsPeriodicReportGql = {
  __typename?: 'InsightBillableStatisticsPeriodicReportGQL';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  insightLicenceHasBeenEnabled: Scalars['Boolean']['output'];
  numberOfActiveInsightApplication: Scalars['Int']['output'];
  numberOfPublishedForms: Scalars['Int']['output'];
};

export type InsightBillableStatisticsPeriodicReportGqlInput = {
  createdAtUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
  id?: InputMaybe<Scalars['Long']['input']>;
  insightLicenceHasBeenEnabled: Scalars['Boolean']['input'];
  numberOfActiveInsightApplication: Scalars['Int']['input'];
  numberOfPublishedForms: Scalars['Int']['input'];
};

export type InsightFeaturesTariff = {
  __typename?: 'InsightFeaturesTariff';
  costOfActiveLicense?: Maybe<Scalars['BigDecimal']['output']>;
  costOfOneActiveApplication?: Maybe<Scalars['BigDecimal']['output']>;
  costOfOnePublishedForm?: Maybe<Scalars['BigDecimal']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastModifiedAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
};

export type InsightFeaturesTariffAtBillingDay = {
  __typename?: 'InsightFeaturesTariffAtBillingDay';
  costOfActiveLicense?: Maybe<Scalars['BigDecimal']['output']>;
  costOfOneActiveApplication?: Maybe<Scalars['BigDecimal']['output']>;
  costOfOnePublishedForm?: Maybe<Scalars['BigDecimal']['output']>;
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
};

export type InsightFeaturesTariffGql = {
  __typename?: 'InsightFeaturesTariffGQL';
  costOfActiveLicense?: Maybe<Scalars['BigDecimal']['output']>;
  costOfOneActiveApplication?: Maybe<Scalars['BigDecimal']['output']>;
  costOfOnePublishedForm?: Maybe<Scalars['BigDecimal']['output']>;
  lastModifiedAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
};

export type InsightFeaturesTariffGqlInput = {
  costOfActiveLicense?: InputMaybe<Scalars['BigDecimal']['input']>;
  costOfOneActiveApplication?: InputMaybe<Scalars['BigDecimal']['input']>;
  costOfOnePublishedForm?: InputMaybe<Scalars['BigDecimal']['input']>;
  lastModifiedAtUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
};

export type InsightPeriodicBill = {
  __typename?: 'InsightPeriodicBill';
  billingPeriod?: Maybe<Scalars['YearMonth']['output']>;
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsForLicenseActivation?: Maybe<Scalars['BigDecimal']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  insightBillableStatisticsPeriodicReport?: Maybe<InsightBillableStatisticsPeriodicReport>;
  insightFeaturesTariffAtBillingDay?: Maybe<InsightFeaturesTariffAtBillingDay>;
  overallSumOfCreditUnits?: Maybe<Scalars['BigDecimal']['output']>;
  sumOfCreditUnitsForAllActiveApplications?: Maybe<Scalars['BigDecimal']['output']>;
  sumOfCreditUnitsForAllPublishedForms?: Maybe<Scalars['BigDecimal']['output']>;
};

export type InsightUserAccountChangeBalanceChangedEvent = AccountBalanceChangedEvent & {
  __typename?: 'InsightUserAccountChangeBalanceChangedEvent';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsChange?: Maybe<Scalars['BigDecimal']['output']>;
  estimationNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  periodicBillGQL?: Maybe<PeriodicBillGql>;
  reasonOfChange?: Maybe<Scalars['String']['output']>;
  translationsGQL?: Maybe<TranslationsGql>;
};

export type InternalUser = {
  __typename?: 'InternalUser';
  userName?: Maybe<Scalars['String']['output']>;
};

export type InternalUserAccountBalanceChangedEvent = AccountBalanceChangedEvent & {
  __typename?: 'InternalUserAccountBalanceChangedEvent';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsChange?: Maybe<Scalars['BigDecimal']['output']>;
  estimationNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  internalUser?: Maybe<InternalUser>;
  periodicBillGQL?: Maybe<PeriodicBillGql>;
  reasonOfChange?: Maybe<Scalars['String']['output']>;
  translationsGQL?: Maybe<TranslationsGql>;
};

export type InvalidVersionGraphQlapiException = {
  __typename?: 'InvalidVersionGraphQLAPIException';
  message?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Lead = {
  __typename?: 'Lead';
  /** The academic rank of the lead */
  academicRank?: Maybe<AcademicRank>;
  /** The business title of the lead */
  businessTitle?: Maybe<Scalars['String']['output']>;
  /** The custom fields of the leads defined in the system */
  customFieldList?: Maybe<Array<Maybe<LeadCustomField>>>;
  /** The email of the lead */
  email?: Maybe<Scalars['String']['output']>;
  /** The external id of the lead (e.g. id from external system that data was imported from) */
  externalId?: Maybe<Scalars['String']['output']>;
  /** The first name of the lead */
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  /** The gender of the lead */
  gender?: Maybe<Gender>;
  /** The id of the lead */
  id: Scalars['Int']['output'];
  /** The last name of the lead */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The mobile phone number of the lead */
  phone?: Maybe<Scalars['String']['output']>;
  /** The web form that the lead was crated with */
  sourceForm?: Maybe<Form>;
  sourceformId?: Maybe<Scalars['Int']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type LeadCustomField = {
  __typename?: 'LeadCustomField';
  fieldName?: Maybe<Scalars['String']['output']>;
  fieldType?: Maybe<FormFieldDefinitionType>;
  type?: Maybe<Scalars['String']['output']>;
  valueList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type LeadCustomFieldInput = {
  fieldName: Scalars['String']['input'];
  valueList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LeadInput = {
  /** The academic rank of the lead */
  academicRank?: InputMaybe<AcademicRank>;
  /**
   * The business title of the lead
   * Validations
   * Max length: 250
   */
  businessTitle?: InputMaybe<Scalars['String']['input']>;
  /** The custom fields of the leads defined in the system */
  customFieldList?: InputMaybe<Array<InputMaybe<LeadCustomFieldInput>>>;
  /**
   * The email of the lead
   * Validations
   * Format: Value has to in email format e.g. name@company.com
   * Max length: 255
   */
  email?: InputMaybe<Scalars['String']['input']>;
  /**
   * The external id of the lead (e.g. id from external system that data was imported from)
   * Validations
   * Max length: 50
   */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The first name of the lead
   * Validations
   * Max length: 255
   */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The gender of the lead */
  gender?: InputMaybe<Gender>;
  /**
   * The last name of the lead
   * Validations
   * Max length: 255
   */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /**
   * The mobile phone number of the lead
   * Validations
   * Max length: 16
   */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type LeadList = ObjectsList & {
  __typename?: 'LeadList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Lead>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type Meeting = {
  __typename?: 'Meeting';
  /** The actual duration of the meeting */
  duration?: Maybe<Scalars['Int']['output']>;
  /** The end time of the meeting */
  endTimeUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  /** The id of the meeting */
  id: Scalars['Int']['output'];
  /** The meeting events related to the meeting */
  meetingEventList?: Maybe<Array<Maybe<MeetingEvent>>>;
  /** The total number of meeting events related to the meeting */
  meetingEventListTotal: Scalars['Long']['output'];
  /** The moderator of the meeting */
  moderator?: Maybe<Representative>;
  /** The moderator id of the meeting */
  moderatorId?: Maybe<Scalars['Int']['output']>;
  /** The participants of the meeting */
  participantList?: Maybe<Array<Maybe<Contact>>>;
  /** The total number of participants of the meeting */
  participantListTotal: Scalars['Long']['output'];
  /** The starting presentation of the meeting */
  startPresentation?: Maybe<Presentation>;
  /** The starting presentation id of the meeting */
  startPresentationId?: Maybe<Scalars['Int']['output']>;
  /** The start time of the meeting */
  startTimeUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  /** The title of the meeting */
  title?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
  zoneId?: Maybe<Scalars['ZoneId']['output']>;
};


export type MeetingMeetingEventListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type MeetingParticipantListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MeetingActionReferenceInput = {
  action: ActionReferenceType;
  idList?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MeetingEvent = {
  __typename?: 'MeetingEvent';
  /** The creation date of the meeting event */
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  /** The custom action id of the meeting event */
  customActionId?: Maybe<Scalars['Int']['output']>;
  /** The type of the meeting event */
  eventType?: Maybe<MeetingEventType>;
  /** The value of the meeting event */
  eventValue?: Maybe<Scalars['String']['output']>;
  /** The id of the meeting event */
  id: Scalars['Int']['output'];
  /** The meeting related to the meeting event */
  meeting?: Maybe<Meeting>;
  /** The meeting id related to the meeting event */
  meetingId?: Maybe<Scalars['Int']['output']>;
  /** The meeting moderator related to the meeting event */
  meetingModerator?: Maybe<Representative>;
  /** The meeting moderator id related to the meeting event */
  meetingModeratorId?: Maybe<Scalars['Int']['output']>;
  /** The meeting note id related to the meeting event */
  meetingNoteId?: Maybe<Scalars['Int']['output']>;
  /** The meeting participant related to the meeting event */
  meetingParticipant?: Maybe<Contact>;
  /** The meeting participant id related to the meeting event */
  meetingParticipantId?: Maybe<Scalars['Int']['output']>;
  /** The presentation related to the meeting event */
  presentation?: Maybe<Presentation>;
  /** The presentation chapter id related to the meeting event */
  presentationChapterId?: Maybe<Scalars['Int']['output']>;
  /** The presentation id related to the meeting event */
  presentationId?: Maybe<Scalars['Int']['output']>;
  /** The presentation slide id related to the meeting event */
  presentationSlideId?: Maybe<Scalars['Int']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type MeetingEventInput = {
  /** The type of the meeting event */
  eventType?: InputMaybe<MeetingEventType>;
  /** The value of the meeting event */
  eventValue?: InputMaybe<Scalars['String']['input']>;
  /** The meeting moderator related to the meeting event */
  meetingModeratorReference?: InputMaybe<RepresentativeReferenceInput>;
  /** The meeting participant related to the meeting event */
  meetingParticipantReference?: InputMaybe<ContactReferenceInput>;
  /** The meeting related to the meeting event */
  meetingReference?: InputMaybe<MeetingReferenceInput>;
  /** The presentation related to the meeting event */
  presentationReference?: InputMaybe<PresentationReferenceInput>;
};

export type MeetingEventList = ObjectsList & {
  __typename?: 'MeetingEventList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<MeetingEvent>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type MeetingEventType =
  | 'EXTERNAL_DISPLAY_CONNECTED'
  | 'EXTERNAL_DISPLAY_DISCONNECTED'
  | 'MEETING_CREATED'
  | 'MEETING_EDITED'
  | 'MEETING_ENDED'
  | 'MEETING_REMOVED'
  | 'MEETING_STARTED'
  | 'NOTE_CREATED'
  | 'NOTE_EDITED'
  | 'NOTE_REMOVED'
  | 'PARTICIPANT_ADDED'
  | 'PARTICIPANT_CLICKED_CUSTOM_ACTION'
  | 'PARTICIPANT_FAILED_TO_JOIN_MEETING'
  | 'PARTICIPANT_JOINED_MEETING'
  | 'PARTICIPANT_LEFT_MEETING'
  | 'PARTICIPANT_OPENED_PRESENTATION_SLIDE'
  | 'PARTICIPANT_REMOVED'
  | 'PARTICIPANT_STARTED_AWAITING_FOR_MODERATOR'
  | 'PARTICIPANT_WATCHED_VIDEO_ON_SLIDE'
  | 'PRESENTATION_CHAPTER_CLOSED'
  | 'PRESENTATION_CHAPTER_OPENED'
  | 'PRESENTATION_CLOSED'
  | 'PRESENTATION_OPENED'
  | 'PRESENTATION_SLIDE_CLOSED'
  | 'PRESENTATION_SLIDE_OPENED';

export type MeetingInput = {
  /**
   * The end time of the meeting
   * Validations
   * Format: Value has to be in ISO8601 format
   */
  endTimeUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
  /** The moderator of the meeting */
  moderatorReference?: InputMaybe<RepresentativeReferenceInput>;
  /** The participants of the meeting */
  participantActionReferenceList?: InputMaybe<Array<InputMaybe<ContactActionReferenceInput>>>;
  /** The starting presentation of the meeting */
  startPresentationReference?: InputMaybe<PresentationReferenceInput>;
  /**
   * The start time of the meeting
   * Validations
   * Format: Value has to be in ISO8601 format
   */
  startTimeUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
  /**
   * The title of the meeting
   * Validations
   * Max length: 255
   */
  title?: InputMaybe<Scalars['String']['input']>;
  zoneId?: InputMaybe<Scalars['ZoneId']['input']>;
};

export type MeetingList = ObjectsList & {
  __typename?: 'MeetingList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Meeting>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type MeetingReferenceInput = {
  id: Scalars['Int']['input'];
};

export type Message = {
  __typename?: 'Message';
  /** The campaign related to the message */
  campaign?: Maybe<MessageCampaign>;
  /** The campaign id related to the message */
  campaignId?: Maybe<Scalars['Int']['output']>;
  campaignName?: Maybe<Scalars['String']['output']>;
  /** The flags of the message */
  flags?: Maybe<Scalars['Int']['output']>;
  /** The id of the message */
  id: Scalars['Int']['output'];
  lastUpdatedUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  /** The media asset id related to the message */
  mediaAssetId?: Maybe<Scalars['Int']['output']>;
  /** The receiver of the message */
  receiver?: Maybe<Contact>;
  /** The receiver id of the message */
  receiverId?: Maybe<Scalars['Int']['output']>;
  /** The sender of the message */
  sender?: Maybe<Representative>;
  /** The sender id of the message */
  senderId?: Maybe<Scalars['Int']['output']>;
  /** The status of the message */
  status?: Maybe<MessageStatus>;
  /** The token of the message */
  token?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type MessageAccountBalanceChangedEvent = AccountBalanceChangedEvent & {
  __typename?: 'MessageAccountBalanceChangedEvent';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsChange?: Maybe<Scalars['BigDecimal']['output']>;
  estimationNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  messagePeriodicBill?: Maybe<MessagePeriodicBill>;
  messagePeriodicBillId?: Maybe<Scalars['Long']['output']>;
  periodicBillGQL?: Maybe<PeriodicBillGql>;
  reasonOfChange?: Maybe<Scalars['String']['output']>;
  translationsGQL?: Maybe<TranslationsGql>;
};

export type MessageBillableStatisticsPeriodicReport = {
  __typename?: 'MessageBillableStatisticsPeriodicReport';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  messageLicenceHasBeenEnabled: Scalars['Boolean']['output'];
  numberOfActiveMessageApplication: Scalars['Int']['output'];
  numberOfSentMessages: Scalars['Int']['output'];
};

export type MessageBillableStatisticsPeriodicReportGql = {
  __typename?: 'MessageBillableStatisticsPeriodicReportGQL';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  messageLicenceHasBeenEnabled: Scalars['Boolean']['output'];
  numberOfActiveMessageApplication: Scalars['Int']['output'];
  numberOfSentMessages: Scalars['Int']['output'];
};

export type MessageBillableStatisticsPeriodicReportGqlInput = {
  createdAtUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
  messageLicenceHasBeenEnabled: Scalars['Boolean']['input'];
  numberOfActiveMessageApplication: Scalars['Int']['input'];
  numberOfSentMessages: Scalars['Int']['input'];
};

export type MessageCampaign = {
  __typename?: 'MessageCampaign';
  dateEndUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  dateStartUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  enableMessagesToRepresentativesContacts?: Maybe<Scalars['Boolean']['output']>;
  enableMessagesWithPersonalizedVideo?: Maybe<Scalars['Boolean']['output']>;
  enableMultipleMessagesToOneRecipient?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  isTrial?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastModifiedAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  messageElementList?: Maybe<Array<Maybe<MessageElement>>>;
  messageElementListTotal: Scalars['Long']['output'];
  messageList?: Maybe<Array<Maybe<Message>>>;
  messageListTotal: Scalars['Long']['output'];
  name?: Maybe<Scalars['String']['output']>;
  statistics?: Maybe<MessageCampaignStatistics>;
  status?: Maybe<MessageCampaignStatus>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<MessageCampaignType>;
  version?: Maybe<Scalars['Int']['output']>;
  videoScript?: Maybe<Scalars['String']['output']>;
};


export type MessageCampaignMessageElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type MessageCampaignMessageListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MessageCampaignInput = {
  dateEndUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
  dateStartUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
  enableMessagesToRepresentativesContacts?: InputMaybe<Scalars['Boolean']['input']>;
  enableMessagesWithPersonalizedVideo?: InputMaybe<Scalars['Boolean']['input']>;
  enableMultipleMessagesToOneRecipient?: InputMaybe<Scalars['Boolean']['input']>;
  isTrial: Scalars['Boolean']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  lastModifiedAtUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MessageCampaignStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MessageCampaignType>;
  videoScript?: InputMaybe<Scalars['String']['input']>;
};

export type MessageCampaignList = ObjectsList & {
  __typename?: 'MessageCampaignList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<MessageCampaign>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type MessageCampaignNotStartedGraphQlapiException = {
  __typename?: 'MessageCampaignNotStartedGraphQLAPIException';
  message?: Maybe<Scalars['String']['output']>;
  messageCampaignId?: Maybe<Scalars['ObjectScalar']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MessageCampaignReferenceInput = {
  id: Scalars['Int']['input'];
};

export type MessageCampaignStatistics = {
  __typename?: 'MessageCampaignStatistics';
  numberOfCancelledMessages: Scalars['Int']['output'];
  numberOfOpenedMessages: Scalars['Int']['output'];
  numberOfOrderedMessages: Scalars['Int']['output'];
  numberOfSentMessages: Scalars['Int']['output'];
};

export type MessageCampaignStatus =
  | 'ARCHIVED'
  | 'CANCELLED'
  | 'DELETING'
  | 'DRAFT'
  | 'SAVING'
  | 'SCHEDULED'
  | 'STARTED'
  | 'STARTING';

export type MessageCampaignType =
  | 'ENGAGE'
  | 'ENGAGE_MODERATED'
  | 'EXPRESS';

export type MessageElement = {
  __typename?: 'MessageElement';
  avatar?: Maybe<Scalars['String']['output']>;
  campaign?: Maybe<MessageCampaign>;
  campaignId?: Maybe<Scalars['Int']['output']>;
  challengeId?: Maybe<Scalars['Int']['output']>;
  configuration?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discriminator?: Maybe<Scalars['String']['output']>;
  elementType?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  flags?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  messageEventList?: Maybe<Array<Maybe<MessageEvent>>>;
  messageEventListTotal: Scalars['Long']['output'];
  name?: Maybe<Scalars['String']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  preference?: Maybe<Scalars['String']['output']>;
  presentationPublicId?: Maybe<Scalars['Int']['output']>;
  screenWidth?: Maybe<Scalars['String']['output']>;
  script?: Maybe<Scalars['String']['output']>;
  senderName?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
  visibleName?: Maybe<Scalars['String']['output']>;
};


export type MessageElementMessageEventListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MessageElementInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  challengeId?: InputMaybe<Scalars['Int']['input']>;
  configuration?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discriminator: Scalars['String']['input'];
  elementType: Scalars['String']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  flags?: InputMaybe<Scalars['Int']['input']>;
  messageCampaignReference?: InputMaybe<MessageCampaignReferenceInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  preference?: InputMaybe<Scalars['String']['input']>;
  presentationPublicId?: InputMaybe<Scalars['Int']['input']>;
  screenWidth?: InputMaybe<Scalars['String']['input']>;
  script?: InputMaybe<Scalars['String']['input']>;
  senderName?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  visibleName?: InputMaybe<Scalars['String']['input']>;
};

export type MessageElementList = ObjectsList & {
  __typename?: 'MessageElementList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<MessageElement>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type MessageElementReferenceInput = {
  id: Scalars['Int']['input'];
};

export type MessageEvent = {
  __typename?: 'MessageEvent';
  /** The creation date of the message event */
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  /** The type of the message event */
  eventType?: Maybe<MessageEventType>;
  /** The value of the message event */
  eventValue?: Maybe<Scalars['String']['output']>;
  /** The id of the message event */
  id: Scalars['Int']['output'];
  /** The message related to the message event */
  message?: Maybe<Message>;
  /** The message element related to the message event */
  messageElement?: Maybe<MessageElement>;
  /** The message element id related to the message event */
  messageElementId?: Maybe<Scalars['Int']['output']>;
  /** The message id related to the message event */
  messageId?: Maybe<Scalars['Int']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type MessageEventInput = {
  /** The type of the message event */
  eventType: MessageEventType;
  /**
   * The value of the message event
   * Validations
   * Max length: 255
   */
  eventValue: Scalars['String']['input'];
  /** The message element related to the message event */
  messageElementReference?: InputMaybe<MessageElementReferenceInput>;
  /** The message related to the message event */
  messageReference: MessageReferenceInput;
};

export type MessageEventList = ObjectsList & {
  __typename?: 'MessageEventList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<MessageEvent>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type MessageEventType =
  | 'HIGHP_EVENT_ANSWER'
  | 'HIGHP_EVENT_BOUNCE_EMAIL'
  | 'HIGHP_EVENT_BOUNCE_SMS'
  | 'HIGHP_EVENT_CANCEL'
  | 'HIGHP_EVENT_CLICK'
  | 'HIGHP_EVENT_FEEDBACK'
  | 'HIGHP_EVENT_FINISH'
  | 'HIGHP_EVENT_MOVE_TO_SCREEN'
  | 'HIGHP_EVENT_OPEN_VIA_EMAIL'
  | 'HIGHP_EVENT_OPEN_VIA_LINK'
  | 'HIGHP_EVENT_OPEN_VIA_PROXY'
  | 'HIGHP_EVENT_OPEN_VIA_SMS'
  | 'HIGHP_EVENT_ORDER'
  | 'HIGHP_EVENT_PAUSE'
  | 'HIGHP_EVENT_PLAY'
  | 'HIGHP_EVENT_RESEND_EMAIL'
  | 'HIGHP_EVENT_RESEND_SMS'
  | 'HIGHP_EVENT_SEND_EMAIL'
  | 'HIGHP_EVENT_SEND_SMS'
  | 'HIGHP_EVENT_START'
  | 'HIGHP_EVENT_UNSUBSCRIBE'
  | 'HIGHP_EVENT_UNSUBSCRIBE_EMAIL'
  | 'HIGHP_EVENT_UNSUBSCRIBE_SMS'
  | 'PROXY_ADDVIDEO'
  | 'PROXY_CREATED'
  | 'PROXY_CREATEDBYVIEWER'
  | 'PROXY_SAVEVIDEO';

export type MessageFeaturesTariff = {
  __typename?: 'MessageFeaturesTariff';
  costOfActiveLicense?: Maybe<Scalars['BigDecimal']['output']>;
  costOfOneActiveApplication?: Maybe<Scalars['BigDecimal']['output']>;
  costOfOneSentMessage?: Maybe<Scalars['BigDecimal']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastModifiedAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
};

export type MessageFeaturesTariffAtBillingDay = {
  __typename?: 'MessageFeaturesTariffAtBillingDay';
  costOfActiveLicense?: Maybe<Scalars['BigDecimal']['output']>;
  costOfOneActiveApplication?: Maybe<Scalars['BigDecimal']['output']>;
  costOfOneSentMessage?: Maybe<Scalars['BigDecimal']['output']>;
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
};

export type MessageFeaturesTariffGql = {
  __typename?: 'MessageFeaturesTariffGQL';
  costOfActiveLicense?: Maybe<Scalars['BigDecimal']['output']>;
  costOfOneActiveApplication?: Maybe<Scalars['BigDecimal']['output']>;
  costOfOneSentMessage?: Maybe<Scalars['BigDecimal']['output']>;
  lastModifiedAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
};

export type MessageFeaturesTariffGqlInput = {
  costOfActiveLicense?: InputMaybe<Scalars['BigDecimal']['input']>;
  costOfOneActiveApplication?: InputMaybe<Scalars['BigDecimal']['input']>;
  costOfOneSentMessage?: InputMaybe<Scalars['BigDecimal']['input']>;
  lastModifiedAtUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
};

export type MessageInput = {
  /** The campaign related to the message */
  campaignReference?: InputMaybe<MessageCampaignReferenceInput>;
  /** The flags of the message */
  flags?: InputMaybe<Scalars['Int']['input']>;
  /** The media asset id related to the message */
  mediaAssetId?: InputMaybe<Scalars['Int']['input']>;
  /** The receiver of the message */
  receiverReference?: InputMaybe<ContactReferenceInput>;
  /** The sender of the message */
  senderReference?: InputMaybe<RepresentativeReferenceInput>;
  /** The status of the message */
  status?: InputMaybe<MessageStatus>;
  /**
   * The token of the message
   * Validations
   * Unique: Value has to be unique across all messages
   * Max length: 6
   */
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MessageList = ObjectsList & {
  __typename?: 'MessageList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Message>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type MessagePeriodicBill = {
  __typename?: 'MessagePeriodicBill';
  billingPeriod?: Maybe<Scalars['YearMonth']['output']>;
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsForLicenseActivation?: Maybe<Scalars['BigDecimal']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  messageBillableStatisticsPeriodicReport?: Maybe<MessageBillableStatisticsPeriodicReport>;
  messageFeaturesTariffAtBillingDay?: Maybe<MessageFeaturesTariffAtBillingDay>;
  overallSumOfCreditUnits?: Maybe<Scalars['BigDecimal']['output']>;
  sumOfCreditUnitsForAllActiveApplications?: Maybe<Scalars['BigDecimal']['output']>;
  sumOfCreditUnitsForAllSentMessages?: Maybe<Scalars['BigDecimal']['output']>;
};

export type MessageReferenceInput = {
  id: Scalars['Int']['input'];
};

export type MessageStatus =
  | 'OPENED'
  | 'ORDERED'
  | 'READY'
  | 'SENT';

export type MessageUserAccountChangeBalanceChangedEvent = AccountBalanceChangedEvent & {
  __typename?: 'MessageUserAccountChangeBalanceChangedEvent';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsChange?: Maybe<Scalars['BigDecimal']['output']>;
  estimationNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  periodicBillGQL?: Maybe<PeriodicBillGql>;
  reasonOfChange?: Maybe<Scalars['String']['output']>;
  translationsGQL?: Maybe<TranslationsGql>;
};

export type MobileApp = {
  __typename?: 'MobileApp';
  appName?: Maybe<MobileAppName>;
  system?: Maybe<MobileSystem>;
  version?: Maybe<Scalars['String']['output']>;
};

export type MobileAppName =
  | 'CREATOR'
  | 'INSIGHTS'
  | 'MESSAGES'
  | 'PRESENTATIONS';

export type MobileSystem =
  | 'ANDROID'
  | 'IOS';

export type Mutation = {
  __typename?: 'Mutation';
  addAccountBalanceChangedEvent: Scalars['Boolean']['output'];
  addFilteredContactsToCampaignById: Scalars['Boolean']['output'];
  addFilteredContactsToCampaignByUuid: Scalars['Boolean']['output'];
  archiveMessageCampaign?: Maybe<MessageCampaign>;
  cloneMessageCampaign?: Maybe<MessageCampaign>;
  convertLeadToContact?: Maybe<Contact>;
  convertLeadsToContacts?: Maybe<Array<Maybe<Contact>>>;
  copyAutomatorWorkflow?: Maybe<AutomatorWorkflow>;
  copyFromGalleryAutomatorWorkflow?: Maybe<AutomatorWorkflow>;
  createAutomatorConnectionAddedEventTriggerWorkflowElement?: Maybe<AutomatorConnectionAddedEventTriggerWorkflowElement>;
  createAutomatorConnectionRemovedEventTriggerWorkflowElement?: Maybe<AutomatorConnectionRemovedEventTriggerWorkflowElement>;
  createAutomatorCreateObjectActionWorkflowElement?: Maybe<AutomatorCreateObjectActionWorkflowElement>;
  createAutomatorDelayFlowWorkflowElement?: Maybe<AutomatorDelayFlowWorkflowElement>;
  createAutomatorDeleteObjectActionWorkflowElement?: Maybe<AutomatorDeleteObjectActionWorkflowElement>;
  createAutomatorGetObjectListActionWorkflowElement?: Maybe<AutomatorGetObjectListActionWorkflowElement>;
  createAutomatorGetOneObjectActionWorkflowElement?: Maybe<AutomatorGetOneObjectActionWorkflowElement>;
  createAutomatorLocalChoiceFlowWorkflowElement?: Maybe<AutomatorLocalChoiceFlowWorkflowElement>;
  createAutomatorLocalFlowFilterFlowWorkflowElement?: Maybe<AutomatorLocalFlowFilterFlowWorkflowElement>;
  createAutomatorMeetingEventTriggerWorkflowElement?: Maybe<AutomatorMeetingEventTriggerWorkflowElement>;
  createAutomatorMessageEventTriggerWorkflowElement?: Maybe<AutomatorMessageEventTriggerWorkflowElement>;
  createAutomatorObjectCreatedEventTriggerWorkflowElement?: Maybe<AutomatorObjectCreatedEventTriggerWorkflowElement>;
  createAutomatorObjectDeletedEventTriggerWorkflowElement?: Maybe<AutomatorObjectDeletedEventTriggerWorkflowElement>;
  createAutomatorObjectUpdatedEventTriggerWorkflowElement?: Maybe<AutomatorObjectUpdatedEventTriggerWorkflowElement>;
  createAutomatorQueryObjectExistsActionWorkflowElement?: Maybe<AutomatorQueryObjectExistsActionWorkflowElement>;
  createAutomatorSendEmailActionWorkflowElement?: Maybe<AutomatorSendEmailActionWorkflowElement>;
  createAutomatorSendMessageActionWorkflowElement?: Maybe<AutomatorSendMessageActionWorkflowElement>;
  createAutomatorSendSmsActionWorkflowElement?: Maybe<AutomatorSendSmsActionWorkflowElement>;
  createAutomatorSfdcAddToNestedUploadJobActionWorkflowElement?: Maybe<AutomatorSfdcAddToNestedUploadJobActionWorkflowElement>;
  createAutomatorSfdcAddToUploadJobActionWorkflowElement?: Maybe<AutomatorSfdcAddToUploadJobActionWorkflowElement>;
  createAutomatorSfdcObjectCreatedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcObjectCreatedEventTriggerWorkflowElement>;
  createAutomatorSfdcObjectDeletedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcObjectDeletedEventTriggerWorkflowElement>;
  createAutomatorSfdcObjectUndeletedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcObjectUndeletedEventTriggerWorkflowElement>;
  createAutomatorSfdcObjectUpdatedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcObjectUpdatedEventTriggerWorkflowElement>;
  createAutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElement>;
  createAutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElement>;
  createAutomatorTimeEventTriggerWorkflowElement?: Maybe<AutomatorTimeEventTriggerWorkflowElement>;
  createAutomatorUpdateObjectActionWorkflowElement?: Maybe<AutomatorUpdateObjectActionWorkflowElement>;
  createAutomatorWaitForMessageEventFlowWorkflowElement?: Maybe<AutomatorWaitForMessageEventFlowWorkflowElement>;
  createAutomatorWebhookActionWorkflowElement?: Maybe<AutomatorWebhookActionWorkflowElement>;
  createAutomatorWebhookEventTriggerWorkflowElement?: Maybe<AutomatorWebhookEventTriggerWorkflowElement>;
  createAutomatorWorkflow?: Maybe<AutomatorWorkflow>;
  createBatchJob?: Maybe<BatchJob>;
  createContact?: Maybe<Contact>;
  createCustomActionDefinition?: Maybe<CustomActionDefinition>;
  createCustomFieldDefinition?: Maybe<CustomFieldDefinition>;
  createGroup?: Maybe<Group>;
  createLead?: Maybe<Lead>;
  createMeeting?: Maybe<Meeting>;
  createMeetingEvent?: Maybe<MeetingEvent>;
  createMessage?: Maybe<Message>;
  createMessageCampaign?: Maybe<MessageCampaign>;
  createMessageElement?: Maybe<MessageElement>;
  createMessageEvent?: Maybe<MessageEvent>;
  createOrUpdate?: Maybe<Contact>;
  createPresentation?: Maybe<Presentation>;
  createPresentationChapter?: Maybe<PresentationChapter>;
  createPresentationSlide?: Maybe<PresentationSlide>;
  createRepresentative?: Maybe<Representative>;
  /** Create new segment */
  createSegment?: Maybe<Scalars['Boolean']['output']>;
  createSpecialization?: Maybe<Specialization>;
  createWorkplace?: Maybe<Workplace>;
  deleteAutomatorConnectionAddedEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorConnectionRemovedEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorCreateObjectActionWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorDelayFlowWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorDeleteObjectActionWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorGetObjectListActionWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorGetOneObjectActionWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorLocalChoiceFlowWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorLocalFlowFilterFlowWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorMeetingEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorMessageEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorObjectCreatedEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorObjectDeletedEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorObjectUpdatedEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorQueryObjectExistsActionWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorSendEmailActionWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorSendMessageActionWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorSendSmsActionWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorSfdcAddToNestedUploadJobActionWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorSfdcAddToUploadJobActionWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorSfdcObjectCreatedEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorSfdcObjectDeletedEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorSfdcObjectUndeletedEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorSfdcObjectUpdatedEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorTimeEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorUpdateObjectActionWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorWaitForMessageEventFlowWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorWebhookActionWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorWebhookEventTriggerWorkflowElement: Scalars['Boolean']['output'];
  deleteAutomatorWorkflow: Scalars['Boolean']['output'];
  deleteAutomatorWorkflowElement: Scalars['Boolean']['output'];
  deleteBatchJob?: Maybe<Scalars['Boolean']['output']>;
  deleteBulkContact?: Maybe<Scalars['Boolean']['output']>;
  deleteBulkCustomFieldDefinition?: Maybe<Scalars['Boolean']['output']>;
  deleteBulkGroup?: Maybe<Scalars['Boolean']['output']>;
  deleteBulkLead?: Maybe<Scalars['Boolean']['output']>;
  deleteBulkSpecialisation?: Maybe<Scalars['Boolean']['output']>;
  deleteBulkWorkplace?: Maybe<Scalars['Boolean']['output']>;
  deleteContact?: Maybe<Scalars['Boolean']['output']>;
  deleteCustomActionDefinition?: Maybe<Scalars['Boolean']['output']>;
  deleteCustomFieldDefinition?: Maybe<Scalars['Boolean']['output']>;
  deleteGroup?: Maybe<Scalars['Boolean']['output']>;
  deleteLead?: Maybe<Scalars['Boolean']['output']>;
  deleteMeeting?: Maybe<Scalars['Boolean']['output']>;
  deleteMeetingEvent?: Maybe<Scalars['Boolean']['output']>;
  deleteMessage?: Maybe<Scalars['Boolean']['output']>;
  deleteMessageCampaign?: Maybe<Scalars['Boolean']['output']>;
  deleteMessageElement?: Maybe<Scalars['Boolean']['output']>;
  deleteMessageEvent?: Maybe<Scalars['Boolean']['output']>;
  deletePresentation?: Maybe<Scalars['Boolean']['output']>;
  deletePresentationChapter?: Maybe<Scalars['Boolean']['output']>;
  deletePresentationSlide?: Maybe<Scalars['Boolean']['output']>;
  deleteRepresentative?: Maybe<Scalars['Boolean']['output']>;
  /** Deleting segment by id (list of ids) */
  deleteSegmentById: Scalars['Boolean']['output'];
  /** Deleting segment by uuid (list of uuids) */
  deleteSegmentByUuid: Scalars['Boolean']['output'];
  deleteSpecialization?: Maybe<Scalars['Boolean']['output']>;
  deleteWorkplace?: Maybe<Scalars['Boolean']['output']>;
  /** Edit existing segment */
  editSegment?: Maybe<Scalars['Boolean']['output']>;
  /** Recalculating segment matched records based on segment filters */
  recalculateSegmentById?: Maybe<Scalars['Long']['output']>;
  /** Recalculating segment matched records based on segment filters */
  recalculateSegmentByUuid?: Maybe<Scalars['Long']['output']>;
  removeFilteredContactsFromCampaignById: Scalars['Boolean']['output'];
  removeFilteredContactsFromCampaignByUuid: Scalars['Boolean']['output'];
  /** Execute segment query expression for search contacts */
  saveAndExecuteSegment?: Maybe<PageData_SegmentContact>;
  sendMessage?: Maybe<Message>;
  startAutomatorWorkflow?: Maybe<AutomatorWorkflowStateChangeResponse>;
  stopAutomatorWorkflow?: Maybe<AutomatorWorkflowStateChangeResponse>;
  updateAccountBalanceChangedEvent: Scalars['Boolean']['output'];
  updateAutomatorConnectionAddedEventTriggerWorkflowElement?: Maybe<AutomatorConnectionAddedEventTriggerWorkflowElement>;
  updateAutomatorConnectionRemovedEventTriggerWorkflowElement?: Maybe<AutomatorConnectionRemovedEventTriggerWorkflowElement>;
  updateAutomatorCreateObjectActionWorkflowElement?: Maybe<AutomatorCreateObjectActionWorkflowElement>;
  updateAutomatorDelayFlowWorkflowElement?: Maybe<AutomatorDelayFlowWorkflowElement>;
  updateAutomatorDeleteObjectActionWorkflowElement?: Maybe<AutomatorDeleteObjectActionWorkflowElement>;
  updateAutomatorGetObjectListActionWorkflowElement?: Maybe<AutomatorGetObjectListActionWorkflowElement>;
  updateAutomatorGetOneObjectActionWorkflowElement?: Maybe<AutomatorGetOneObjectActionWorkflowElement>;
  updateAutomatorLocalChoiceFlowWorkflowElement?: Maybe<AutomatorLocalChoiceFlowWorkflowElement>;
  updateAutomatorLocalFlowFilterFlowWorkflowElement?: Maybe<AutomatorLocalFlowFilterFlowWorkflowElement>;
  updateAutomatorMeetingEventTriggerWorkflowElement?: Maybe<AutomatorMeetingEventTriggerWorkflowElement>;
  updateAutomatorMessageEventTriggerWorkflowElement?: Maybe<AutomatorMessageEventTriggerWorkflowElement>;
  updateAutomatorObjectCreatedEventTriggerWorkflowElement?: Maybe<AutomatorObjectCreatedEventTriggerWorkflowElement>;
  updateAutomatorObjectDeletedEventTriggerWorkflowElement?: Maybe<AutomatorObjectDeletedEventTriggerWorkflowElement>;
  updateAutomatorObjectUpdatedEventTriggerWorkflowElement?: Maybe<AutomatorObjectUpdatedEventTriggerWorkflowElement>;
  updateAutomatorQueryObjectExistsActionWorkflowElement?: Maybe<AutomatorQueryObjectExistsActionWorkflowElement>;
  updateAutomatorSendEmailActionWorkflowElement?: Maybe<AutomatorSendEmailActionWorkflowElement>;
  updateAutomatorSendMessageActionWorkflowElement?: Maybe<AutomatorSendMessageActionWorkflowElement>;
  updateAutomatorSendSmsActionWorkflowElement?: Maybe<AutomatorSendSmsActionWorkflowElement>;
  updateAutomatorSfdcAddToNestedUploadJobActionWorkflowElement?: Maybe<AutomatorSfdcAddToNestedUploadJobActionWorkflowElement>;
  updateAutomatorSfdcAddToUploadJobActionWorkflowElement?: Maybe<AutomatorSfdcAddToUploadJobActionWorkflowElement>;
  updateAutomatorSfdcObjectCreatedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcObjectCreatedEventTriggerWorkflowElement>;
  updateAutomatorSfdcObjectDeletedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcObjectDeletedEventTriggerWorkflowElement>;
  updateAutomatorSfdcObjectUndeletedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcObjectUndeletedEventTriggerWorkflowElement>;
  updateAutomatorSfdcObjectUpdatedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcObjectUpdatedEventTriggerWorkflowElement>;
  updateAutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElement>;
  updateAutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElement>;
  updateAutomatorTimeEventTriggerWorkflowElement?: Maybe<AutomatorTimeEventTriggerWorkflowElement>;
  updateAutomatorUpdateObjectActionWorkflowElement?: Maybe<AutomatorUpdateObjectActionWorkflowElement>;
  updateAutomatorWaitForMessageEventFlowWorkflowElement?: Maybe<AutomatorWaitForMessageEventFlowWorkflowElement>;
  updateAutomatorWebhookActionWorkflowElement?: Maybe<AutomatorWebhookActionWorkflowElement>;
  updateAutomatorWebhookEventTriggerWorkflowElement?: Maybe<AutomatorWebhookEventTriggerWorkflowElement>;
  updateAutomatorWorkflow?: Maybe<AutomatorWorkflow>;
  updateAutomatorWorkflowElement?: Maybe<AutomatorWorkflowElement>;
  updateBatchJob?: Maybe<BatchJob>;
  updateContact?: Maybe<Contact>;
  updateCustomActionDefinition?: Maybe<CustomActionDefinition>;
  updateCustomFieldDefinition?: Maybe<CustomFieldDefinition>;
  updateGroup?: Maybe<Group>;
  updateLead?: Maybe<Lead>;
  updateMeeting?: Maybe<Meeting>;
  updateMeetingEvent?: Maybe<MeetingEvent>;
  updateMessage?: Maybe<Message>;
  updateMessageCampaign?: Maybe<MessageCampaign>;
  updateMessageElement?: Maybe<MessageElement>;
  updateMessageEvent?: Maybe<MessageEvent>;
  updatePresentation?: Maybe<Presentation>;
  updatePresentationChapter?: Maybe<PresentationChapter>;
  updatePresentationSlide?: Maybe<PresentationSlide>;
  updateRepresentative?: Maybe<Representative>;
  updateSpecialization?: Maybe<Specialization>;
  updateTenant?: Maybe<Tenant>;
  updateWorkplace?: Maybe<Workplace>;
};


export type MutationAddAccountBalanceChangedEventArgs = {
  accountBalanceChangedEvent: AddAccountBalanceChangedEventGraphQldtoInput;
  tenantId: Scalars['String']['input'];
};


export type MutationAddFilteredContactsToCampaignByIdArgs = {
  campaignId: Scalars['Int']['input'];
  segmentId: Scalars['Long']['input'];
};


export type MutationAddFilteredContactsToCampaignByUuidArgs = {
  campaignUuid: Scalars['UUID']['input'];
  segmentUuid: Scalars['UUID']['input'];
};


export type MutationArchiveMessageCampaignArgs = {
  id: Scalars['Int']['input'];
};


export type MutationCloneMessageCampaignArgs = {
  toBeClonedMessageCampaignId: Scalars['Int']['input'];
};


export type MutationConvertLeadToContactArgs = {
  leadId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationConvertLeadsToContactsArgs = {
  leadIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type MutationCopyAutomatorWorkflowArgs = {
  copyName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Long']['input'];
};


export type MutationCopyFromGalleryAutomatorWorkflowArgs = {
  copyName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Long']['input'];
};


export type MutationCreateAutomatorConnectionAddedEventTriggerWorkflowElementArgs = {
  data: AutomatorConnectionAddedEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorConnectionRemovedEventTriggerWorkflowElementArgs = {
  data: AutomatorConnectionRemovedEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorCreateObjectActionWorkflowElementArgs = {
  data: AutomatorCreateObjectActionWorkflowElementInput;
};


export type MutationCreateAutomatorDelayFlowWorkflowElementArgs = {
  data: AutomatorDelayFlowWorkflowElementInput;
};


export type MutationCreateAutomatorDeleteObjectActionWorkflowElementArgs = {
  data: AutomatorDeleteObjectActionWorkflowElementInput;
};


export type MutationCreateAutomatorGetObjectListActionWorkflowElementArgs = {
  data: AutomatorGetObjectListActionWorkflowElementInput;
};


export type MutationCreateAutomatorGetOneObjectActionWorkflowElementArgs = {
  data: AutomatorGetOneObjectActionWorkflowElementInput;
};


export type MutationCreateAutomatorLocalChoiceFlowWorkflowElementArgs = {
  data: AutomatorLocalChoiceFlowWorkflowElementInput;
};


export type MutationCreateAutomatorLocalFlowFilterFlowWorkflowElementArgs = {
  data: AutomatorLocalFlowFilterFlowWorkflowElementInput;
};


export type MutationCreateAutomatorMeetingEventTriggerWorkflowElementArgs = {
  data: AutomatorMeetingEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorMessageEventTriggerWorkflowElementArgs = {
  data: AutomatorMessageEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorObjectCreatedEventTriggerWorkflowElementArgs = {
  data: AutomatorObjectCreatedEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorObjectDeletedEventTriggerWorkflowElementArgs = {
  data: AutomatorObjectDeletedEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorObjectUpdatedEventTriggerWorkflowElementArgs = {
  data: AutomatorObjectUpdatedEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorQueryObjectExistsActionWorkflowElementArgs = {
  data: AutomatorQueryObjectExistsActionWorkflowElementInput;
};


export type MutationCreateAutomatorSendEmailActionWorkflowElementArgs = {
  data: AutomatorSendEmailActionWorkflowElementInput;
};


export type MutationCreateAutomatorSendMessageActionWorkflowElementArgs = {
  data: AutomatorSendMessageActionWorkflowElementInput;
};


export type MutationCreateAutomatorSendSmsActionWorkflowElementArgs = {
  data: AutomatorSendSmsActionWorkflowElementInput;
};


export type MutationCreateAutomatorSfdcAddToNestedUploadJobActionWorkflowElementArgs = {
  data: AutomatorSfdcAddToNestedUploadJobActionWorkflowElementInput;
};


export type MutationCreateAutomatorSfdcAddToUploadJobActionWorkflowElementArgs = {
  data: AutomatorSfdcAddToUploadJobActionWorkflowElementInput;
};


export type MutationCreateAutomatorSfdcObjectCreatedEventTriggerWorkflowElementArgs = {
  data: AutomatorSfdcObjectCreatedEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorSfdcObjectDeletedEventTriggerWorkflowElementArgs = {
  data: AutomatorSfdcObjectDeletedEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorSfdcObjectUndeletedEventTriggerWorkflowElementArgs = {
  data: AutomatorSfdcObjectUndeletedEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorSfdcObjectUpdatedEventTriggerWorkflowElementArgs = {
  data: AutomatorSfdcObjectUpdatedEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementArgs = {
  data: AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementArgs = {
  data: AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorTimeEventTriggerWorkflowElementArgs = {
  data: AutomatorTimeEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorUpdateObjectActionWorkflowElementArgs = {
  data: AutomatorUpdateObjectActionWorkflowElementInput;
};


export type MutationCreateAutomatorWaitForMessageEventFlowWorkflowElementArgs = {
  data: AutomatorWaitForMessageEventFlowWorkflowElementInput;
};


export type MutationCreateAutomatorWebhookActionWorkflowElementArgs = {
  data: AutomatorWebhookActionWorkflowElementInput;
};


export type MutationCreateAutomatorWebhookEventTriggerWorkflowElementArgs = {
  data: AutomatorWebhookEventTriggerWorkflowElementInput;
};


export type MutationCreateAutomatorWorkflowArgs = {
  data: AutomatorWorkflowInput;
};


export type MutationCreateBatchJobArgs = {
  batchJob?: InputMaybe<BatchJobInput>;
};


export type MutationCreateContactArgs = {
  contact?: InputMaybe<ContactInput>;
};


export type MutationCreateCustomActionDefinitionArgs = {
  customActionDefinition?: InputMaybe<CustomActionDefinitionInput>;
};


export type MutationCreateCustomFieldDefinitionArgs = {
  newObject?: InputMaybe<CustomFieldDefinitionInput>;
};


export type MutationCreateGroupArgs = {
  group?: InputMaybe<GroupInput>;
};


export type MutationCreateLeadArgs = {
  lead?: InputMaybe<LeadInput>;
};


export type MutationCreateMeetingArgs = {
  meeting?: InputMaybe<MeetingInput>;
};


export type MutationCreateMeetingEventArgs = {
  meetingEvent?: InputMaybe<MeetingEventInput>;
};


export type MutationCreateMessageArgs = {
  message?: InputMaybe<MessageInput>;
};


export type MutationCreateMessageCampaignArgs = {
  messageCampaign?: InputMaybe<MessageCampaignInput>;
};


export type MutationCreateMessageElementArgs = {
  messageElement?: InputMaybe<MessageElementInput>;
};


export type MutationCreateMessageEventArgs = {
  messageEvent?: InputMaybe<MessageEventInput>;
};


export type MutationCreateOrUpdateArgs = {
  newObject?: InputMaybe<ContactInput>;
};


export type MutationCreatePresentationArgs = {
  presentation?: InputMaybe<PresentationInput>;
};


export type MutationCreatePresentationChapterArgs = {
  presentationChapter?: InputMaybe<PresentationChapterInput>;
};


export type MutationCreatePresentationSlideArgs = {
  presentationSlide?: InputMaybe<PresentationSlideInput>;
};


export type MutationCreateRepresentativeArgs = {
  representative?: InputMaybe<RepresentativeInput>;
};


export type MutationCreateSegmentArgs = {
  data: SegmentInput;
};


export type MutationCreateSpecializationArgs = {
  specialization?: InputMaybe<SpecializationInput>;
};


export type MutationCreateWorkplaceArgs = {
  workplace?: InputMaybe<WorkplaceInput>;
};


export type MutationDeleteAutomatorConnectionAddedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorConnectionRemovedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorCreateObjectActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorDelayFlowWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorDeleteObjectActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorGetObjectListActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorGetOneObjectActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorLocalChoiceFlowWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorLocalFlowFilterFlowWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorMeetingEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorMessageEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorObjectCreatedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorObjectDeletedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorObjectUpdatedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorQueryObjectExistsActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorSendEmailActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorSendMessageActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorSendSmsActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorSfdcAddToNestedUploadJobActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorSfdcAddToUploadJobActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorSfdcObjectCreatedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorSfdcObjectDeletedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorSfdcObjectUndeletedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorSfdcObjectUpdatedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorTimeEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorUpdateObjectActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorWaitForMessageEventFlowWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorWebhookActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorWebhookEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorWorkflowArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteAutomatorWorkflowElementArgs = {
  id?: InputMaybe<Scalars['Long']['input']>;
};


export type MutationDeleteBatchJobArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteBulkContactArgs = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type MutationDeleteBulkCustomFieldDefinitionArgs = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type MutationDeleteBulkGroupArgs = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type MutationDeleteBulkLeadArgs = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type MutationDeleteBulkSpecialisationArgs = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type MutationDeleteBulkWorkplaceArgs = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type MutationDeleteContactArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCustomActionDefinitionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCustomFieldDefinitionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteGroupArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteLeadArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteMeetingArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteMeetingEventArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteMessageArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteMessageCampaignArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteMessageElementArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteMessageEventArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePresentationArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePresentationChapterArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePresentationSlideArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRepresentativeArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteSegmentByIdArgs = {
  ids: Array<InputMaybe<Scalars['Long']['input']>>;
};


export type MutationDeleteSegmentByUuidArgs = {
  uuids: Array<InputMaybe<Scalars['UUID']['input']>>;
};


export type MutationDeleteSpecializationArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteWorkplaceArgs = {
  id: Scalars['Int']['input'];
};


export type MutationEditSegmentArgs = {
  data: SegmentInput;
};


export type MutationRecalculateSegmentByIdArgs = {
  segmentId: Scalars['Long']['input'];
};


export type MutationRecalculateSegmentByUuidArgs = {
  segmentUuid: Scalars['UUID']['input'];
};


export type MutationRemoveFilteredContactsFromCampaignByIdArgs = {
  campaignId: Scalars['Int']['input'];
  segmentId: Scalars['Long']['input'];
};


export type MutationRemoveFilteredContactsFromCampaignByUuidArgs = {
  campaignUuid: Scalars['UUID']['input'];
  segmentUuid: Scalars['UUID']['input'];
};


export type MutationSaveAndExecuteSegmentArgs = {
  data: SegmentInput;
  pagination?: InputMaybe<PaginationRequestInput>;
};


export type MutationSendMessageArgs = {
  campaignId: Scalars['Int']['input'];
  receiverId: Scalars['Int']['input'];
  senderId: Scalars['Int']['input'];
};


export type MutationStartAutomatorWorkflowArgs = {
  id?: InputMaybe<Scalars['Long']['input']>;
};


export type MutationStopAutomatorWorkflowArgs = {
  id: Scalars['Long']['input'];
};


export type MutationUpdateAccountBalanceChangedEventArgs = {
  accountBalanceChangedEvent: UpdateAccountBalanceChangedEventGraphQldtoInput;
  id?: InputMaybe<Scalars['Long']['input']>;
  tenantId: Scalars['String']['input'];
};


export type MutationUpdateAutomatorConnectionAddedEventTriggerWorkflowElementArgs = {
  data: AutomatorConnectionAddedEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorConnectionRemovedEventTriggerWorkflowElementArgs = {
  data: AutomatorConnectionRemovedEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorCreateObjectActionWorkflowElementArgs = {
  data: AutomatorCreateObjectActionWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorDelayFlowWorkflowElementArgs = {
  data: AutomatorDelayFlowWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorDeleteObjectActionWorkflowElementArgs = {
  data: AutomatorDeleteObjectActionWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorGetObjectListActionWorkflowElementArgs = {
  data: AutomatorGetObjectListActionWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorGetOneObjectActionWorkflowElementArgs = {
  data: AutomatorGetOneObjectActionWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorLocalChoiceFlowWorkflowElementArgs = {
  data: AutomatorLocalChoiceFlowWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorLocalFlowFilterFlowWorkflowElementArgs = {
  data: AutomatorLocalFlowFilterFlowWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorMeetingEventTriggerWorkflowElementArgs = {
  data: AutomatorMeetingEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorMessageEventTriggerWorkflowElementArgs = {
  data: AutomatorMessageEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorObjectCreatedEventTriggerWorkflowElementArgs = {
  data: AutomatorObjectCreatedEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorObjectDeletedEventTriggerWorkflowElementArgs = {
  data: AutomatorObjectDeletedEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorObjectUpdatedEventTriggerWorkflowElementArgs = {
  data: AutomatorObjectUpdatedEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorQueryObjectExistsActionWorkflowElementArgs = {
  data: AutomatorQueryObjectExistsActionWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorSendEmailActionWorkflowElementArgs = {
  data: AutomatorSendEmailActionWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorSendMessageActionWorkflowElementArgs = {
  data: AutomatorSendMessageActionWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorSendSmsActionWorkflowElementArgs = {
  data: AutomatorSendSmsActionWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorSfdcAddToNestedUploadJobActionWorkflowElementArgs = {
  data: AutomatorSfdcAddToNestedUploadJobActionWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorSfdcAddToUploadJobActionWorkflowElementArgs = {
  data: AutomatorSfdcAddToUploadJobActionWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorSfdcObjectCreatedEventTriggerWorkflowElementArgs = {
  data: AutomatorSfdcObjectCreatedEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorSfdcObjectDeletedEventTriggerWorkflowElementArgs = {
  data: AutomatorSfdcObjectDeletedEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorSfdcObjectUndeletedEventTriggerWorkflowElementArgs = {
  data: AutomatorSfdcObjectUndeletedEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorSfdcObjectUpdatedEventTriggerWorkflowElementArgs = {
  data: AutomatorSfdcObjectUpdatedEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementArgs = {
  data: AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementArgs = {
  data: AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorTimeEventTriggerWorkflowElementArgs = {
  data: AutomatorTimeEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorUpdateObjectActionWorkflowElementArgs = {
  data: AutomatorUpdateObjectActionWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorWaitForMessageEventFlowWorkflowElementArgs = {
  data: AutomatorWaitForMessageEventFlowWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorWebhookActionWorkflowElementArgs = {
  data: AutomatorWebhookActionWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorWebhookEventTriggerWorkflowElementArgs = {
  data: AutomatorWebhookEventTriggerWorkflowElementInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorWorkflowArgs = {
  data: AutomatorWorkflowInput;
  id: Scalars['Long']['input'];
};


export type MutationUpdateAutomatorWorkflowElementArgs = {
  data?: InputMaybe<AutomatorUpdateWorkflowElementInput>;
  id?: InputMaybe<Scalars['Long']['input']>;
};


export type MutationUpdateBatchJobArgs = {
  batchJob?: InputMaybe<BatchJobInput>;
  id: Scalars['Int']['input'];
  version: Scalars['Int']['input'];
};


export type MutationUpdateContactArgs = {
  contact?: InputMaybe<ContactInput>;
  id: Scalars['Int']['input'];
  version: Scalars['Int']['input'];
};


export type MutationUpdateCustomActionDefinitionArgs = {
  customActionDefinition?: InputMaybe<CustomActionDefinitionInput>;
  id: Scalars['Int']['input'];
  version: Scalars['Int']['input'];
};


export type MutationUpdateCustomFieldDefinitionArgs = {
  id: Scalars['Int']['input'];
  updatedObject?: InputMaybe<CustomFieldDefinitionInput>;
};


export type MutationUpdateGroupArgs = {
  group?: InputMaybe<GroupInput>;
  id: Scalars['Int']['input'];
  version: Scalars['Int']['input'];
};


export type MutationUpdateLeadArgs = {
  id: Scalars['Int']['input'];
  lead?: InputMaybe<LeadInput>;
  version: Scalars['Int']['input'];
};


export type MutationUpdateMeetingArgs = {
  id: Scalars['Int']['input'];
  meeting?: InputMaybe<MeetingInput>;
  version: Scalars['Int']['input'];
};


export type MutationUpdateMeetingEventArgs = {
  id: Scalars['Int']['input'];
  meetingEvent?: InputMaybe<MeetingEventInput>;
  version: Scalars['Int']['input'];
};


export type MutationUpdateMessageArgs = {
  id: Scalars['Int']['input'];
  message?: InputMaybe<MessageInput>;
  version: Scalars['Int']['input'];
};


export type MutationUpdateMessageCampaignArgs = {
  id: Scalars['Int']['input'];
  messageCampaign?: InputMaybe<MessageCampaignInput>;
  version: Scalars['Int']['input'];
};


export type MutationUpdateMessageElementArgs = {
  id: Scalars['Int']['input'];
  messageElement?: InputMaybe<MessageElementInput>;
  version: Scalars['Int']['input'];
};


export type MutationUpdateMessageEventArgs = {
  id: Scalars['Int']['input'];
  messageEvent?: InputMaybe<MessageEventInput>;
  version: Scalars['Int']['input'];
};


export type MutationUpdatePresentationArgs = {
  id: Scalars['Int']['input'];
  presentation?: InputMaybe<PresentationInput>;
  version: Scalars['Int']['input'];
};


export type MutationUpdatePresentationChapterArgs = {
  id: Scalars['Int']['input'];
  presentationChapter?: InputMaybe<PresentationChapterInput>;
  version: Scalars['Int']['input'];
};


export type MutationUpdatePresentationSlideArgs = {
  id: Scalars['Int']['input'];
  presentationSlide?: InputMaybe<PresentationSlideInput>;
  version: Scalars['Int']['input'];
};


export type MutationUpdateRepresentativeArgs = {
  id: Scalars['Int']['input'];
  representative?: InputMaybe<RepresentativeInput>;
  version: Scalars['Int']['input'];
};


export type MutationUpdateSpecializationArgs = {
  id: Scalars['Int']['input'];
  specialization?: InputMaybe<SpecializationInput>;
  version: Scalars['Int']['input'];
};


export type MutationUpdateTenantArgs = {
  id: Scalars['Long']['input'];
  tenant: UpdateTenantInput;
};


export type MutationUpdateWorkplaceArgs = {
  id: Scalars['Int']['input'];
  version: Scalars['Int']['input'];
  workplace?: InputMaybe<WorkplaceInput>;
};

export type NoSuchObjectExistsGraphQlapiException = {
  __typename?: 'NoSuchObjectExistsGraphQLAPIException';
  id?: Maybe<Scalars['ObjectScalar']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  objectName?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type NullValueInFilterGraphQlapiException = {
  __typename?: 'NullValueInFilterGraphQLAPIException';
  message?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ObjectsList = {
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type Order = {
  __typename?: 'Order';
  field?: Maybe<Scalars['String']['output']>;
  order?: Maybe<OrderType>;
};

export type OrderInput = {
  field?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<OrderType>;
};

export type OrderType =
  | 'ASCENDING'
  | 'DESCENDING';

export type PageData_SegmentContact = {
  __typename?: 'PageData_SegmentContact';
  /** Returns the page content as List. */
  content?: Maybe<Array<Maybe<SegmentContact>>>;
  /** Returns whether the current Slice is the first one. */
  first: Scalars['Boolean']['output'];
  /** Returns whether the Slice has content at all. */
  hasContent: Scalars['Boolean']['output'];
  /** Returns if there is a next Slice. */
  hasNext: Scalars['Boolean']['output'];
  /** Returns if there is a previous Slice. */
  hasPrevious: Scalars['Boolean']['output'];
  /** Returns whether the current Slice is the last one. */
  last: Scalars['Boolean']['output'];
  /** Returns the number of the current Slice. */
  number: Scalars['Int']['output'];
  /** Returns the number of elements currently on this Slice. */
  numberOfElements: Scalars['Int']['output'];
  /** Returns the size of the Slice. */
  size: Scalars['Int']['output'];
  /** Returns the total amount of elements. */
  totalElements: Scalars['Long']['output'];
  /** Returns the number of total pages. */
  totalPages: Scalars['Int']['output'];
};

export type PageData_SegmentData = {
  __typename?: 'PageData_SegmentData';
  /** Returns the page content as List. */
  content?: Maybe<Array<Maybe<SegmentData>>>;
  /** Returns whether the current Slice is the first one. */
  first: Scalars['Boolean']['output'];
  /** Returns whether the Slice has content at all. */
  hasContent: Scalars['Boolean']['output'];
  /** Returns if there is a next Slice. */
  hasNext: Scalars['Boolean']['output'];
  /** Returns if there is a previous Slice. */
  hasPrevious: Scalars['Boolean']['output'];
  /** Returns whether the current Slice is the last one. */
  last: Scalars['Boolean']['output'];
  /** Returns the number of the current Slice. */
  number: Scalars['Int']['output'];
  /** Returns the number of elements currently on this Slice. */
  numberOfElements: Scalars['Int']['output'];
  /** Returns the size of the Slice. */
  size: Scalars['Int']['output'];
  /** Returns the total amount of elements. */
  totalElements: Scalars['Long']['output'];
  /** Returns the number of total pages. */
  totalPages: Scalars['Int']['output'];
};

/** Page request with pagination information. */
export type PaginationRequestInput = {
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  sort?: InputMaybe<Array<InputMaybe<SortOrderDataInput>>>;
};

export type PeriodicBillGql = {
  __typename?: 'PeriodicBillGQL';
  billingPeriod?: Maybe<Scalars['YearMonth']['output']>;
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsForLicenseActivation?: Maybe<Scalars['BigDecimal']['output']>;
  insightBillableStatisticsPeriodicReportGQL?: Maybe<InsightBillableStatisticsPeriodicReportGql>;
  insightFeaturesTariffGQL?: Maybe<InsightFeaturesTariffGql>;
  messageBillableStatisticsPeriodicReportGQL?: Maybe<MessageBillableStatisticsPeriodicReportGql>;
  messageFeaturesTariffGQL?: Maybe<MessageFeaturesTariffGql>;
  presentationBillableStatisticsPeriodicReportGQL?: Maybe<PresentationBillableStatisticsPeriodicReportGql>;
  presentationFeaturesTariffGQL?: Maybe<PresentationFeaturesTariffGql>;
  sumOfCreditUnitsForAllActiveApplications?: Maybe<Scalars['BigDecimal']['output']>;
  sumOfCreditUnitsForAllAdditionalServiceUsage?: Maybe<Scalars['BigDecimal']['output']>;
  sumOfCreditUnitsForAllPublishedForms?: Maybe<Scalars['BigDecimal']['output']>;
  sumOfCreditUnitsForAllSentMessages?: Maybe<Scalars['BigDecimal']['output']>;
};

export type PeriodicBillGqlInput = {
  billingPeriod?: InputMaybe<Scalars['YearMonth']['input']>;
  createdAtUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
  creditUnitsForLicenseActivation?: InputMaybe<Scalars['BigDecimal']['input']>;
  insightBillableStatisticsPeriodicReportGQL?: InputMaybe<InsightBillableStatisticsPeriodicReportGqlInput>;
  insightFeaturesTariffGQL?: InputMaybe<InsightFeaturesTariffGqlInput>;
  messageBillableStatisticsPeriodicReportGQL?: InputMaybe<MessageBillableStatisticsPeriodicReportGqlInput>;
  messageFeaturesTariffGQL?: InputMaybe<MessageFeaturesTariffGqlInput>;
  presentationBillableStatisticsPeriodicReportGQL?: InputMaybe<PresentationBillableStatisticsPeriodicReportGqlInput>;
  presentationFeaturesTariffGQL?: InputMaybe<PresentationFeaturesTariffGqlInput>;
  sumOfCreditUnitsForAllActiveApplications?: InputMaybe<Scalars['BigDecimal']['input']>;
  sumOfCreditUnitsForAllAdditionalServiceUsage?: InputMaybe<Scalars['BigDecimal']['input']>;
  sumOfCreditUnitsForAllPublishedForms?: InputMaybe<Scalars['BigDecimal']['input']>;
  sumOfCreditUnitsForAllSentMessages?: InputMaybe<Scalars['BigDecimal']['input']>;
};

export type PhoneNumberStatus =
  | 'CORRECT'
  | 'INCORRECT'
  | 'UNKNOWN';

export type Presentation = {
  __typename?: 'Presentation';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PresentationStatus>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type PresentationAccountBalanceChangedEvent = AccountBalanceChangedEvent & {
  __typename?: 'PresentationAccountBalanceChangedEvent';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsChange?: Maybe<Scalars['BigDecimal']['output']>;
  estimationNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  periodicBillGQL?: Maybe<PeriodicBillGql>;
  presentationPeriodicBill?: Maybe<PresentationPeriodicBill>;
  presentationPeriodicBillId?: Maybe<Scalars['Long']['output']>;
  reasonOfChange?: Maybe<Scalars['String']['output']>;
  translationsGQL?: Maybe<TranslationsGql>;
};

export type PresentationAdditionalServiceStatistic = {
  __typename?: 'PresentationAdditionalServiceStatistic';
  additionalServiceType?: Maybe<PresentationAdditionalServiceType>;
  id?: Maybe<Scalars['Long']['output']>;
  numberOfAdditionalServiceUsage: Scalars['Int']['output'];
  report?: Maybe<PresentationBillableStatisticsPeriodicReport>;
};

export type PresentationAdditionalServiceStatisticGql = {
  __typename?: 'PresentationAdditionalServiceStatisticGQL';
  additionalServiceType?: Maybe<PresentationAdditionalServiceType>;
  id?: Maybe<Scalars['Long']['output']>;
  numberOfAdditionalServiceUsage: Scalars['Int']['output'];
};

export type PresentationAdditionalServiceStatisticGqlInput = {
  additionalServiceType?: InputMaybe<PresentationAdditionalServiceType>;
  id?: InputMaybe<Scalars['Long']['input']>;
  numberOfAdditionalServiceUsage: Scalars['Int']['input'];
};

export type PresentationAdditionalServiceTariff = {
  __typename?: 'PresentationAdditionalServiceTariff';
  additionalServiceType?: Maybe<PresentationAdditionalServiceType>;
  costOfSingleAdditionalServiceUsage?: Maybe<Scalars['BigDecimal']['output']>;
  featuresTariff?: Maybe<PresentationFeaturesTariff>;
  id?: Maybe<Scalars['Long']['output']>;
};

export type PresentationAdditionalServiceTariffAtBillingDay = {
  __typename?: 'PresentationAdditionalServiceTariffAtBillingDay';
  additionalServiceType?: Maybe<PresentationAdditionalServiceType>;
  costOfSingleAdditionalServiceUsage?: Maybe<Scalars['BigDecimal']['output']>;
  featuresTariffAtBillingDay?: Maybe<PresentationFeaturesTariffAtBillingDay>;
  id?: Maybe<Scalars['Long']['output']>;
};

export type PresentationAdditionalServiceTariffGql = {
  __typename?: 'PresentationAdditionalServiceTariffGQL';
  additionalServiceType?: Maybe<PresentationAdditionalServiceType>;
  costOfSingleAdditionalServiceUsage?: Maybe<Scalars['BigDecimal']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
};

export type PresentationAdditionalServiceTariffGqlInput = {
  additionalServiceType?: InputMaybe<PresentationAdditionalServiceType>;
  costOfSingleAdditionalServiceUsage?: InputMaybe<Scalars['BigDecimal']['input']>;
  id?: InputMaybe<Scalars['Long']['input']>;
};

export type PresentationAdditionalServiceTariffInput = {
  additionalServiceType?: InputMaybe<PresentationAdditionalServiceType>;
  costOfSingleAdditionalServiceUsage?: InputMaybe<Scalars['BigDecimal']['input']>;
  featuresTariff?: InputMaybe<PresentationFeaturesTariffInput>;
  id?: InputMaybe<Scalars['Long']['input']>;
};

export type PresentationAdditionalServiceType =
  | 'EXPORT_PRESENTATION_TO_VEEVA'
  | 'EXPORT_PRESENTATION_TO_ZIP_FILE'
  | 'GENERATE_EXTERNAL_ACCESS_LINK'
  | 'GENERATE_INTERNAL_ACCESS_LINK';

export type PresentationApplicationType =
  | 'PRESENTATION_APP_BASIC'
  | 'PRESENTATION_APP_REMOTE'
  | 'PRESENTATION_APP_REMOTE_UNLIMITED';

export type PresentationBillableStatisticsPeriodicReport = {
  __typename?: 'PresentationBillableStatisticsPeriodicReport';
  activePresentationApplicationStatisticSet?: Maybe<Array<Maybe<ActivePresentationApplicationStatistic>>>;
  additionalServiceStatisticSet?: Maybe<Array<Maybe<PresentationAdditionalServiceStatistic>>>;
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  presentationLicenceHasBeenEnabled: Scalars['Boolean']['output'];
};

export type PresentationBillableStatisticsPeriodicReportGql = {
  __typename?: 'PresentationBillableStatisticsPeriodicReportGQL';
  activePresentationApplicationStatisticSet?: Maybe<Array<Maybe<ActivePresentationApplicationStatisticGql>>>;
  additionalServiceStatisticSet?: Maybe<Array<Maybe<PresentationAdditionalServiceStatisticGql>>>;
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  presentationLicenceHasBeenEnabled: Scalars['Boolean']['output'];
};

export type PresentationBillableStatisticsPeriodicReportGqlInput = {
  activePresentationApplicationStatisticSet?: InputMaybe<Array<InputMaybe<ActivePresentationApplicationStatisticGqlInput>>>;
  additionalServiceStatisticSet?: InputMaybe<Array<InputMaybe<PresentationAdditionalServiceStatisticGqlInput>>>;
  createdAtUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
  id?: InputMaybe<Scalars['Long']['input']>;
  presentationLicenceHasBeenEnabled: Scalars['Boolean']['input'];
};

export type PresentationChapter = {
  __typename?: 'PresentationChapter';
  beginningSlideId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  presentationId?: Maybe<Scalars['Int']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type PresentationChapterInput = {
  beginningSlideId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  presentationId?: InputMaybe<Scalars['Int']['input']>;
};

export type PresentationChapterList = ObjectsList & {
  __typename?: 'PresentationChapterList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<PresentationChapter>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type PresentationFeaturesTariff = {
  __typename?: 'PresentationFeaturesTariff';
  activePresentationApplicationTariffSet?: Maybe<Array<Maybe<ActivePresentationApplicationTariff>>>;
  costOfActiveLicense?: Maybe<Scalars['BigDecimal']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastModifiedAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  presentationAdditionalServiceTariffSet?: Maybe<Array<Maybe<PresentationAdditionalServiceTariff>>>;
};

export type PresentationFeaturesTariffAtBillingDay = {
  __typename?: 'PresentationFeaturesTariffAtBillingDay';
  activePresentationApplicationTariffSet?: Maybe<Array<Maybe<ActivePresentationApplicationTariffAtBillingDay>>>;
  costOfActiveLicense?: Maybe<Scalars['BigDecimal']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastModifiedAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  presentationAdditionalServiceTariffSet?: Maybe<Array<Maybe<PresentationAdditionalServiceTariffAtBillingDay>>>;
};

export type PresentationFeaturesTariffGql = {
  __typename?: 'PresentationFeaturesTariffGQL';
  activePresentationApplicationTariffSet?: Maybe<Array<Maybe<ActivePresentationApplicationTariffGql>>>;
  costOfActiveLicense?: Maybe<Scalars['BigDecimal']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastModifiedAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  presentationAdditionalServiceTariffSet?: Maybe<Array<Maybe<PresentationAdditionalServiceTariffGql>>>;
};

export type PresentationFeaturesTariffGqlInput = {
  activePresentationApplicationTariffSet?: InputMaybe<Array<InputMaybe<ActivePresentationApplicationTariffGqlInput>>>;
  costOfActiveLicense?: InputMaybe<Scalars['BigDecimal']['input']>;
  id?: InputMaybe<Scalars['Long']['input']>;
  lastModifiedAtUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
  presentationAdditionalServiceTariffSet?: InputMaybe<Array<InputMaybe<PresentationAdditionalServiceTariffGqlInput>>>;
};

export type PresentationFeaturesTariffInput = {
  activePresentationApplicationTariffSet?: InputMaybe<Array<InputMaybe<ActivePresentationApplicationTariffInput>>>;
  costOfActiveLicense?: InputMaybe<Scalars['BigDecimal']['input']>;
  lastModifiedAtUTC?: InputMaybe<Scalars['LocalDateTime']['input']>;
  presentationAdditionalServiceTariffSet?: InputMaybe<Array<InputMaybe<PresentationAdditionalServiceTariffInput>>>;
};

export type PresentationInput = {
  name: Scalars['String']['input'];
  status: PresentationStatus;
};

export type PresentationList = ObjectsList & {
  __typename?: 'PresentationList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Presentation>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type PresentationPeriodicBill = {
  __typename?: 'PresentationPeriodicBill';
  billingPeriod?: Maybe<Scalars['YearMonth']['output']>;
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsForLicenseActivation?: Maybe<Scalars['BigDecimal']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  overallSumOfCreditUnits?: Maybe<Scalars['BigDecimal']['output']>;
  presentationBillableStatisticsPeriodicReport?: Maybe<PresentationBillableStatisticsPeriodicReport>;
  presentationFeaturesTariffAtBillingDay?: Maybe<PresentationFeaturesTariffAtBillingDay>;
  sumOfCreditUnitsForAllActiveApplications?: Maybe<Scalars['BigDecimal']['output']>;
  sumOfCreditUnitsForAllAdditionalServiceUsage?: Maybe<Scalars['BigDecimal']['output']>;
};

export type PresentationReferenceInput = {
  id: Scalars['Int']['input'];
};

export type PresentationSlide = {
  __typename?: 'PresentationSlide';
  angle?: Maybe<Scalars['String']['output']>;
  chapterId?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  extraAssetId?: Maybe<Scalars['Int']['output']>;
  fromSlideId?: Maybe<Scalars['Int']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  layer?: Maybe<Scalars['Int']['output']>;
  mainAssetId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  toSlideId?: Maybe<Scalars['Int']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['String']['output']>;
  x?: Maybe<Scalars['String']['output']>;
  y?: Maybe<Scalars['String']['output']>;
};

export type PresentationSlideInput = {
  angle?: InputMaybe<Scalars['String']['input']>;
  chapterId?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['String']['input']>;
  extraAssetId?: InputMaybe<Scalars['Int']['input']>;
  fromSlideId?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  layer?: InputMaybe<Scalars['Int']['input']>;
  mainAssetId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  toSlideId?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['String']['input']>;
  x?: InputMaybe<Scalars['String']['input']>;
  y?: InputMaybe<Scalars['String']['input']>;
};

export type PresentationSlideList = ObjectsList & {
  __typename?: 'PresentationSlideList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<PresentationSlide>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type PresentationStatus =
  | 'DRAFT'
  | 'PUBLISHED';

export type PresentationUserAccountAddBalanceChangedEvent = AccountBalanceChangedEvent & {
  __typename?: 'PresentationUserAccountAddBalanceChangedEvent';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsChange?: Maybe<Scalars['BigDecimal']['output']>;
  estimationNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  periodicBillGQL?: Maybe<PeriodicBillGql>;
  reasonOfChange?: Maybe<Scalars['String']['output']>;
  translationsGQL?: Maybe<TranslationsGql>;
};

export type ProductManager = {
  __typename?: 'ProductManager';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type ProductManagerList = ObjectsList & {
  __typename?: 'ProductManagerList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<ProductManager>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type Query = {
  __typename?: 'Query';
  createXlsExport?: Maybe<ExportTask>;
  /** Execute segment query expression for search contacts */
  executeContactsFilter?: Maybe<PageData_SegmentContact>;
  /** Getting contacts filtered by segment filter */
  executeSegmentById?: Maybe<PageData_SegmentContact>;
  /** Getting contacts filtered by segment filter */
  executeSegmentByUuid?: Maybe<PageData_SegmentContact>;
  exportInProgress: Scalars['Boolean']['output'];
  extractAutomatorWebhookSchema?: Maybe<AutomatorWebhookSchema>;
  getAccountAggregates?: Maybe<AccountAggregates>;
  getApiFile?: Maybe<ApiFile>;
  getAutomatorApiVersion?: Maybe<AutomatorApiVersion>;
  getAutomatorAvailableWorkflowElementTypes?: Maybe<Array<Maybe<AutomatorAvailableWorkflowElementType>>>;
  getAutomatorBillableStatisticsPeriodicReportList?: Maybe<Array<Maybe<AutomatorBillableStatisticsPeriodicReport>>>;
  getAutomatorConnectionAddedEventTriggerWorkflowElement?: Maybe<AutomatorConnectionAddedEventTriggerWorkflowElement>;
  getAutomatorConnectionAddedEventTriggerWorkflowElementList?: Maybe<AutomatorConnectionAddedEventTriggerWorkflowElementList>;
  getAutomatorConnectionRemovedEventTriggerWorkflowElement?: Maybe<AutomatorConnectionRemovedEventTriggerWorkflowElement>;
  getAutomatorConnectionRemovedEventTriggerWorkflowElementList?: Maybe<AutomatorConnectionRemovedEventTriggerWorkflowElementList>;
  getAutomatorCreateObjectActionWorkflowElement?: Maybe<AutomatorCreateObjectActionWorkflowElement>;
  getAutomatorCreateObjectActionWorkflowElementList?: Maybe<AutomatorCreateObjectActionWorkflowElementList>;
  getAutomatorDelayFlowWorkflowElement?: Maybe<AutomatorDelayFlowWorkflowElement>;
  getAutomatorDelayFlowWorkflowElementList?: Maybe<AutomatorDelayFlowWorkflowElementList>;
  getAutomatorDeleteObjectActionWorkflowElement?: Maybe<AutomatorDeleteObjectActionWorkflowElement>;
  getAutomatorDeleteObjectActionWorkflowElementList?: Maybe<AutomatorDeleteObjectActionWorkflowElementList>;
  getAutomatorFeaturesTariff?: Maybe<AutomatorFeaturesTariff>;
  getAutomatorGetObjectListActionWorkflowElement?: Maybe<AutomatorGetObjectListActionWorkflowElement>;
  getAutomatorGetObjectListActionWorkflowElementList?: Maybe<AutomatorGetObjectListActionWorkflowElementList>;
  getAutomatorGetOneObjectActionWorkflowElement?: Maybe<AutomatorGetOneObjectActionWorkflowElement>;
  getAutomatorGetOneObjectActionWorkflowElementList?: Maybe<AutomatorGetOneObjectActionWorkflowElementList>;
  getAutomatorLocalChoiceFlowWorkflowElement?: Maybe<AutomatorLocalChoiceFlowWorkflowElement>;
  getAutomatorLocalChoiceFlowWorkflowElementList?: Maybe<AutomatorLocalChoiceFlowWorkflowElementList>;
  getAutomatorLocalFlowFilterFlowWorkflowElement?: Maybe<AutomatorLocalFlowFilterFlowWorkflowElement>;
  getAutomatorLocalFlowFilterFlowWorkflowElementList?: Maybe<AutomatorLocalFlowFilterFlowWorkflowElementList>;
  getAutomatorMeetingEventTriggerWorkflowElement?: Maybe<AutomatorMeetingEventTriggerWorkflowElement>;
  getAutomatorMeetingEventTriggerWorkflowElementList?: Maybe<AutomatorMeetingEventTriggerWorkflowElementList>;
  getAutomatorMessageEventTriggerWorkflowElement?: Maybe<AutomatorMessageEventTriggerWorkflowElement>;
  getAutomatorMessageEventTriggerWorkflowElementList?: Maybe<AutomatorMessageEventTriggerWorkflowElementList>;
  getAutomatorObjectCreatedEventTriggerWorkflowElement?: Maybe<AutomatorObjectCreatedEventTriggerWorkflowElement>;
  getAutomatorObjectCreatedEventTriggerWorkflowElementList?: Maybe<AutomatorObjectCreatedEventTriggerWorkflowElementList>;
  getAutomatorObjectDeletedEventTriggerWorkflowElement?: Maybe<AutomatorObjectDeletedEventTriggerWorkflowElement>;
  getAutomatorObjectDeletedEventTriggerWorkflowElementList?: Maybe<AutomatorObjectDeletedEventTriggerWorkflowElementList>;
  /** Retrieves a list of object descriptions for specified objects by name. If empty list or no list is provided the method will return list of all available object descriptions. */
  getAutomatorObjectDescriptionList?: Maybe<AutomatorObjectDescriptionList>;
  getAutomatorObjectUpdatedEventTriggerWorkflowElement?: Maybe<AutomatorObjectUpdatedEventTriggerWorkflowElement>;
  getAutomatorObjectUpdatedEventTriggerWorkflowElementList?: Maybe<AutomatorObjectUpdatedEventTriggerWorkflowElementList>;
  getAutomatorPeriodicBill?: Maybe<AutomatorPeriodicBill>;
  getAutomatorPeriodicBillList?: Maybe<Array<Maybe<AutomatorPeriodicBill>>>;
  getAutomatorQueryObjectExistsActionWorkflowElement?: Maybe<AutomatorQueryObjectExistsActionWorkflowElement>;
  getAutomatorQueryObjectExistsActionWorkflowElementList?: Maybe<AutomatorQueryObjectExistsActionWorkflowElementList>;
  getAutomatorSendEmailActionWorkflowElement?: Maybe<AutomatorSendEmailActionWorkflowElement>;
  getAutomatorSendEmailActionWorkflowElementList?: Maybe<AutomatorSendEmailActionWorkflowElementList>;
  getAutomatorSendMessageActionWorkflowElement?: Maybe<AutomatorSendMessageActionWorkflowElement>;
  getAutomatorSendMessageActionWorkflowElementList?: Maybe<AutomatorSendMessageActionWorkflowElementList>;
  getAutomatorSendSmsActionWorkflowElement?: Maybe<AutomatorSendSmsActionWorkflowElement>;
  getAutomatorSendSmsActionWorkflowElementList?: Maybe<AutomatorSendSmsActionWorkflowElementList>;
  getAutomatorSfdcAddToNestedUploadJobActionWorkflowElement?: Maybe<AutomatorSfdcAddToNestedUploadJobActionWorkflowElement>;
  getAutomatorSfdcAddToNestedUploadJobActionWorkflowElementList?: Maybe<AutomatorSfdcAddToNestedUploadJobActionWorkflowElementList>;
  getAutomatorSfdcAddToUploadJobActionWorkflowElement?: Maybe<AutomatorSfdcAddToUploadJobActionWorkflowElement>;
  getAutomatorSfdcAddToUploadJobActionWorkflowElementList?: Maybe<AutomatorSfdcAddToUploadJobActionWorkflowElementList>;
  getAutomatorSfdcObjectCreatedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcObjectCreatedEventTriggerWorkflowElement>;
  getAutomatorSfdcObjectCreatedEventTriggerWorkflowElementList?: Maybe<AutomatorSfdcObjectCreatedEventTriggerWorkflowElementList>;
  getAutomatorSfdcObjectDeletedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcObjectDeletedEventTriggerWorkflowElement>;
  getAutomatorSfdcObjectDeletedEventTriggerWorkflowElementList?: Maybe<AutomatorSfdcObjectDeletedEventTriggerWorkflowElementList>;
  getAutomatorSfdcObjectUndeletedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcObjectUndeletedEventTriggerWorkflowElement>;
  getAutomatorSfdcObjectUndeletedEventTriggerWorkflowElementList?: Maybe<AutomatorSfdcObjectUndeletedEventTriggerWorkflowElementList>;
  getAutomatorSfdcObjectUpdatedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcObjectUpdatedEventTriggerWorkflowElement>;
  getAutomatorSfdcObjectUpdatedEventTriggerWorkflowElementList?: Maybe<AutomatorSfdcObjectUpdatedEventTriggerWorkflowElementList>;
  getAutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElement>;
  getAutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementList?: Maybe<AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementList>;
  getAutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElement?: Maybe<AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElement>;
  getAutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementList?: Maybe<AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementList>;
  getAutomatorTimeEventTriggerWorkflowElement?: Maybe<AutomatorTimeEventTriggerWorkflowElement>;
  getAutomatorTimeEventTriggerWorkflowElementList?: Maybe<AutomatorTimeEventTriggerWorkflowElementList>;
  getAutomatorUpdateObjectActionWorkflowElement?: Maybe<AutomatorUpdateObjectActionWorkflowElement>;
  getAutomatorUpdateObjectActionWorkflowElementList?: Maybe<AutomatorUpdateObjectActionWorkflowElementList>;
  getAutomatorWaitForMessageEventFlowWorkflowElement?: Maybe<AutomatorWaitForMessageEventFlowWorkflowElement>;
  getAutomatorWaitForMessageEventFlowWorkflowElementList?: Maybe<AutomatorWaitForMessageEventFlowWorkflowElementList>;
  getAutomatorWebhookActionWorkflowElement?: Maybe<AutomatorWebhookActionWorkflowElement>;
  getAutomatorWebhookActionWorkflowElementList?: Maybe<AutomatorWebhookActionWorkflowElementList>;
  getAutomatorWebhookEventTriggerWorkflowElement?: Maybe<AutomatorWebhookEventTriggerWorkflowElement>;
  getAutomatorWebhookEventTriggerWorkflowElementList?: Maybe<AutomatorWebhookEventTriggerWorkflowElementList>;
  getAutomatorWorkflow?: Maybe<AutomatorWorkflow>;
  getAutomatorWorkflowElement?: Maybe<AutomatorWorkflowElement>;
  getAutomatorWorkflowElementExecutionStateChangedEventList?: Maybe<AutomatorWorkflowElementExecutionStateChangedEventList>;
  getAutomatorWorkflowElementExecutionStateChangedEventListByScrollId?: Maybe<AutomatorWorkflowElementExecutionStateChangedEventList>;
  getAutomatorWorkflowElementExecutionSummaryCountsByStateAggregation?: Maybe<AutomatorWorkflowElementExecutionSummaryCountsByStateAggregation>;
  getAutomatorWorkflowElementExecutionSummaryList?: Maybe<AutomatorWorkflowElementExecutionSummaryList>;
  getAutomatorWorkflowElementExecutionSummaryListByScrollId?: Maybe<AutomatorWorkflowElementExecutionSummaryList>;
  getAutomatorWorkflowElementList?: Maybe<AutomatorWorkflowElementList>;
  getAutomatorWorkflowExecutionSummaryCountsByStateAggregation?: Maybe<AutomatorWorkflowExecutionSummaryCountsByStateAggregation>;
  getAutomatorWorkflowExecutionSummaryList?: Maybe<AutomatorWorkflowExecutionSummaryList>;
  getAutomatorWorkflowExecutionSummaryListByScrollId?: Maybe<AutomatorWorkflowExecutionSummaryList>;
  getAutomatorWorkflowList?: Maybe<AutomatorWorkflowList>;
  getAutomatorWorkflowWithoutElementsList?: Maybe<AutomatorWorkflowShortList>;
  getAutomatorWorkflowsFromGalleryList?: Maybe<AutomatorWorkflowList>;
  getAvailableTimezoneList?: Maybe<Array<Maybe<Timezone>>>;
  getBatchJob?: Maybe<BatchJob>;
  getBatchJobList?: Maybe<BatchJobList>;
  getBatchJobProgressList?: Maybe<Array<Maybe<BatchJobProgress>>>;
  getConstraintViolationGraphQLAPIException?: Maybe<ConstraintViolationGraphQlapiException>;
  getContact?: Maybe<Contact>;
  getContactList?: Maybe<ContactList>;
  getCurrentAccountBalance?: Maybe<Scalars['AccountBalance']['output']>;
  getCustomActionDefinition?: Maybe<CustomActionDefinition>;
  getCustomActionDefinitionList?: Maybe<CustomActionDefinitionList>;
  getCustomFieldDefinition?: Maybe<CustomFieldDefinition>;
  getCustomFieldDefinitionList?: Maybe<CustomFieldDefinitionList>;
  getCustomFieldDefinitionResponse?: Maybe<CustomFieldDefinitionResponse>;
  getDefinedFieldOrderList?: Maybe<Array<Maybe<FieldOrder>>>;
  getDegreeOfSeparationList?: Maybe<Array<Maybe<DegreeOfSeparation>>>;
  getExistsActionReferenceConstraintViolationGraphQLAPIException?: Maybe<ExistsActionReferenceConstraintViolationGraphQlapiException>;
  getExistsWithIdConstraintViolationGraphQLAPIException?: Maybe<ExistsWithIdConstraintViolationGraphQlapiException>;
  getFieldOrder?: Maybe<FieldOrder>;
  getFileSize: Scalars['Long']['output'];
  getForm?: Maybe<Form>;
  getGraphQLAPIException?: Maybe<GraphQlapiException>;
  getGroup?: Maybe<Group>;
  getGroupList?: Maybe<GroupList>;
  getInsightBillableStatisticsPeriodicReportList?: Maybe<Array<Maybe<InsightBillableStatisticsPeriodicReport>>>;
  getInsightFeaturesTariff?: Maybe<InsightFeaturesTariff>;
  getInsightPeriodicBill?: Maybe<InsightPeriodicBill>;
  getInsightPeriodicBillList?: Maybe<Array<Maybe<InsightPeriodicBill>>>;
  getInvalidVersionGraphQLAPIException?: Maybe<InvalidVersionGraphQlapiException>;
  getLead?: Maybe<Lead>;
  getLeadList?: Maybe<LeadList>;
  getMeeting?: Maybe<Meeting>;
  getMeetingEvent?: Maybe<MeetingEvent>;
  getMeetingEventList?: Maybe<MeetingEventList>;
  getMeetingList?: Maybe<MeetingList>;
  getMessage?: Maybe<Message>;
  getMessageBillableStatisticsPeriodicReportList?: Maybe<Array<Maybe<MessageBillableStatisticsPeriodicReport>>>;
  getMessageCampaign?: Maybe<MessageCampaign>;
  getMessageCampaignList?: Maybe<MessageCampaignList>;
  getMessageCampaignNotStartedGraphQLAPIException?: Maybe<MessageCampaignNotStartedGraphQlapiException>;
  getMessageElement?: Maybe<MessageElement>;
  getMessageElementList?: Maybe<MessageElementList>;
  getMessageEvent?: Maybe<MessageEvent>;
  getMessageEventList?: Maybe<MessageEventList>;
  getMessageFeaturesTariff?: Maybe<MessageFeaturesTariff>;
  getMessageList?: Maybe<MessageList>;
  getMessagePeriodicBill?: Maybe<MessagePeriodicBill>;
  getMessagePeriodicBillList?: Maybe<Array<Maybe<MessagePeriodicBill>>>;
  getMinimumBalanceDate?: Maybe<AccountBalanceDate>;
  getMobileApp?: Maybe<MobileApp>;
  getMobileAppList?: Maybe<Array<Maybe<MobileApp>>>;
  getNoSuchObjectExistsGraphQLAPIException?: Maybe<NoSuchObjectExistsGraphQlapiException>;
  getNullValueInFilterGraphQLAPIException?: Maybe<NullValueInFilterGraphQlapiException>;
  getPresentation?: Maybe<Presentation>;
  getPresentationBillableStatisticsPeriodicReportList?: Maybe<Array<Maybe<PresentationBillableStatisticsPeriodicReport>>>;
  getPresentationChapter?: Maybe<PresentationChapter>;
  getPresentationChapterList?: Maybe<PresentationChapterList>;
  getPresentationFeaturesTariff?: Maybe<PresentationFeaturesTariff>;
  getPresentationList?: Maybe<PresentationList>;
  getPresentationPeriodicBill?: Maybe<PresentationPeriodicBill>;
  getPresentationPeriodicBillList?: Maybe<Array<Maybe<PresentationPeriodicBill>>>;
  getPresentationSlide?: Maybe<PresentationSlide>;
  getPresentationSlideList?: Maybe<PresentationSlideList>;
  getProductManager?: Maybe<ProductManager>;
  getProductManagerList?: Maybe<ProductManagerList>;
  getRepresentative?: Maybe<Representative>;
  getRepresentativeList?: Maybe<RepresentativeList>;
  getSizeConstraintViolationGraphQLAPIException?: Maybe<SizeConstraintViolationGraphQlapiException>;
  getSpecialization?: Maybe<Specialization>;
  getSpecializationList?: Maybe<SpecializationList>;
  getTranslation?: Maybe<Translation>;
  getTranslationList?: Maybe<Array<Maybe<Translation>>>;
  getWhatsNewList?: Maybe<WhatsNew>;
  getWorkplace?: Maybe<Workplace>;
  getWorkplaceList?: Maybe<WorkplaceList>;
  highpEvents?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  messagePeriodicBill?: Maybe<MessagePeriodicBill>;
  recordsByDomain?: Maybe<Scalars['ConcurrentMap_String_TenantDomainRecordScalar']['output']>;
  recordsByName?: Maybe<Scalars['ConcurrentMap_String_TenantDomainRecordScalar']['output']>;
  searchAccountBalanceChangedEvent?: Maybe<AccountBalanceChangedEventPage>;
  searchAccountBalanceChangedEventReport?: Maybe<ReportGraphQldto>;
  searchTenant?: Maybe<TenantPage>;
  searchTenantReport?: Maybe<ReportGraphQldto>;
  /** Getting segment by id */
  segmentById?: Maybe<SegmentData>;
  /** Getting segment by uuid */
  segmentByUuid?: Maybe<SegmentData>;
  /** Get available SegmentKey as SegmentKeyElement */
  segmentKeyElements?: Maybe<Array<Maybe<SegmentKeyElement>>>;
  /** Get available SegmentKey.Type as SegmentKeyTypeElement */
  segmentKeyTypeElements?: Maybe<Array<Maybe<SegmentKeyTypeElement>>>;
  /** Get available SegmentKey.Type (all or for key) */
  segmentKeyTypes?: Maybe<Array<Maybe<Type>>>;
  /** Get available SegmentKey (all or for type) */
  segmentKeys?: Maybe<Array<Maybe<SegmentKey>>>;
  /** Getting segments by search criteria */
  segmentList?: Maybe<PageData_SegmentData>;
  tenantsDomainKeys?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tenantsDomainValues?: Maybe<Array<Maybe<TenantDomainRecord>>>;
  tenantsNameKeys?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  validateAutomatorConnectionAddedEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorConnectionRemovedEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorCreateObjectActionWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorDelayFlowWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorDeleteObjectActionWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorGetObjectListActionWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorGetOneObjectActionWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorLocalChoiceFlowWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorLocalFlowFilterFlowWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorMeetingEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorMessageEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorObjectCreatedEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorObjectDeletedEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorObjectUpdatedEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorQueryObjectExistsActionWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorSendEmailActionWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorSendMessageActionWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorSendSmsActionWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorSfdcAddToNestedUploadJobActionWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorSfdcAddToUploadJobActionWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorSfdcObjectCreatedEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorSfdcObjectDeletedEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorSfdcObjectUndeletedEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorSfdcObjectUpdatedEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorTimeEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorUpdateObjectActionWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorWaitForMessageEventFlowWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorWebhookActionWorkflowElement?: Maybe<AutomatorValidation>;
  validateAutomatorWebhookEventTriggerWorkflowElement?: Maybe<AutomatorValidation>;
};


export type QueryCreateXlsExportArgs = {
  exportType?: InputMaybe<ExportTypes>;
};


export type QueryExecuteContactsFilterArgs = {
  expression: SegmentExpressionInput;
  pagination?: InputMaybe<PaginationRequestInput>;
};


export type QueryExecuteSegmentByIdArgs = {
  id: Scalars['Long']['input'];
  pagination?: InputMaybe<PaginationRequestInput>;
};


export type QueryExecuteSegmentByUuidArgs = {
  pagination?: InputMaybe<PaginationRequestInput>;
  uuid: Scalars['UUID']['input'];
};


export type QueryExportInProgressArgs = {
  jonId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};


export type QueryExtractAutomatorWebhookSchemaArgs = {
  payload?: InputMaybe<Scalars['AutomatorObjectScalar']['input']>;
};


export type QueryGetAccountAggregatesArgs = {
  accountEventType?: InputMaybe<AccountEventType>;
  beginTime: Scalars['LocalDateTime']['input'];
  endTime: Scalars['LocalDateTime']['input'];
  tenantId: Scalars['String']['input'];
};


export type QueryGetApiFileArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetAutomatorBillableStatisticsPeriodicReportListArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetAutomatorConnectionAddedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorConnectionAddedEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorConnectionRemovedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorConnectionRemovedEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorCreateObjectActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorCreateObjectActionWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorDelayFlowWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorDelayFlowWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorDeleteObjectActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorDeleteObjectActionWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorFeaturesTariffArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetAutomatorGetObjectListActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorGetObjectListActionWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorGetOneObjectActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorGetOneObjectActionWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorLocalChoiceFlowWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorLocalChoiceFlowWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorLocalFlowFilterFlowWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorLocalFlowFilterFlowWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorMeetingEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorMeetingEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorMessageEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorMessageEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorObjectCreatedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorObjectCreatedEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorObjectDeletedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorObjectDeletedEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorObjectDescriptionListArgs = {
  objectNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetAutomatorObjectUpdatedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorObjectUpdatedEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorPeriodicBillArgs = {
  id: Scalars['Long']['input'];
  tenantId: Scalars['String']['input'];
};


export type QueryGetAutomatorPeriodicBillListArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetAutomatorQueryObjectExistsActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorQueryObjectExistsActionWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorSendEmailActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorSendEmailActionWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorSendMessageActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorSendMessageActionWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorSendSmsActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorSendSmsActionWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorSfdcAddToNestedUploadJobActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorSfdcAddToNestedUploadJobActionWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorSfdcAddToUploadJobActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorSfdcAddToUploadJobActionWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorSfdcObjectCreatedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorSfdcObjectCreatedEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorSfdcObjectDeletedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorSfdcObjectDeletedEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorSfdcObjectUndeletedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorSfdcObjectUndeletedEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorSfdcObjectUpdatedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorSfdcObjectUpdatedEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorTimeEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorTimeEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorUpdateObjectActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorUpdateObjectActionWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorWaitForMessageEventFlowWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorWaitForMessageEventFlowWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorWebhookActionWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorWebhookActionWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorWebhookEventTriggerWorkflowElementArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorWebhookEventTriggerWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorWorkflowArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetAutomatorWorkflowElementArgs = {
  id?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryGetAutomatorWorkflowElementExecutionStateChangedEventListArgs = {
  filter: AutomatorWorkflowElementExecutionStateChangedEventFilterInput;
  order: AutomatorReportsSortingOrder;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAutomatorWorkflowElementExecutionStateChangedEventListByScrollIdArgs = {
  scrollId: Scalars['String']['input'];
};


export type QueryGetAutomatorWorkflowElementExecutionSummaryCountsByStateAggregationArgs = {
  filter?: InputMaybe<AutomatorWorkflowElementExecutionSummaryFilterInput>;
};


export type QueryGetAutomatorWorkflowElementExecutionSummaryListArgs = {
  filter: AutomatorWorkflowElementExecutionSummaryFilterInput;
  order: AutomatorReportsSortingOrder;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAutomatorWorkflowElementExecutionSummaryListByScrollIdArgs = {
  scrollId: Scalars['String']['input'];
};


export type QueryGetAutomatorWorkflowElementListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorWorkflowExecutionSummaryCountsByStateAggregationArgs = {
  filter?: InputMaybe<AutomatorWorkflowExecutionSummaryFilterInput>;
};


export type QueryGetAutomatorWorkflowExecutionSummaryListArgs = {
  filter: AutomatorWorkflowExecutionSummaryFilterInput;
  order: AutomatorReportsSortingOrder;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAutomatorWorkflowExecutionSummaryListByScrollIdArgs = {
  scrollId: Scalars['String']['input'];
};


export type QueryGetAutomatorWorkflowListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorWorkflowWithoutElementsListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAutomatorWorkflowsFromGalleryListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AutomatorOrderInput>;
};


export type QueryGetAvailableTimezoneListArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetBatchJobArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetBatchJobListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetContactArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetContactListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetCurrentAccountBalanceArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetCustomActionDefinitionArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCustomActionDefinitionListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetCustomFieldDefinitionArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCustomFieldDefinitionListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetCustomFieldDefinitionResponseArgs = {
  objectName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDegreeOfSeparationListArgs = {
  maxDegreeOfSeparation?: InputMaybe<Scalars['Int']['input']>;
  objectName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetFieldOrderArgs = {
  dtoClassName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetFileSizeArgs = {
  fileId: Scalars['String']['input'];
};


export type QueryGetFormArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetGroupArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetGroupListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetInsightBillableStatisticsPeriodicReportListArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetInsightFeaturesTariffArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetInsightPeriodicBillArgs = {
  id: Scalars['Long']['input'];
  tenantId: Scalars['String']['input'];
};


export type QueryGetInsightPeriodicBillListArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetLeadArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetLeadListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetMeetingArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetMeetingEventArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetMeetingEventListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetMeetingListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetMessageArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetMessageBillableStatisticsPeriodicReportListArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetMessageCampaignArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetMessageCampaignListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetMessageElementArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetMessageElementListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetMessageEventArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetMessageEventListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetMessageFeaturesTariffArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetMessageListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetMessagePeriodicBillArgs = {
  id: Scalars['Long']['input'];
  tenantId: Scalars['String']['input'];
};


export type QueryGetMessagePeriodicBillListArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetMinimumBalanceDateArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetMobileAppArgs = {
  appName: MobileAppName;
  system: MobileSystem;
};


export type QueryGetPresentationArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetPresentationBillableStatisticsPeriodicReportListArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetPresentationChapterArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetPresentationChapterListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetPresentationFeaturesTariffArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetPresentationListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetPresentationPeriodicBillArgs = {
  id: Scalars['Long']['input'];
  tenantId: Scalars['String']['input'];
};


export type QueryGetPresentationPeriodicBillListArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryGetPresentationSlideArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetPresentationSlideListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetProductManagerArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetProductManagerListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetRepresentativeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetRepresentativeListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetSpecializationArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetSpecializationListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryGetTranslationArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetTranslationListArgs = {
  application: ApplicationInput;
  language: Scalars['String']['input'];
  systemKeyList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetWorkplaceArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetWorkplaceListArgs = {
  filter?: InputMaybe<CompoundFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
};


export type QueryMessagePeriodicBillArgs = {
  id: Scalars['Long']['input'];
  tenantId: Scalars['String']['input'];
};


export type QuerySearchAccountBalanceChangedEventArgs = {
  accountEventType?: InputMaybe<AccountEventType>;
  searchRequest: SearchRequestInput;
  tenantId: Scalars['String']['input'];
};


export type QuerySearchAccountBalanceChangedEventReportArgs = {
  accountEventType?: InputMaybe<AccountEventType>;
  fileName: Scalars['String']['input'];
  isAsyncOn?: InputMaybe<Scalars['Boolean']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  searchRequest: SearchRequestInput;
  tenantId: Scalars['String']['input'];
};


export type QuerySearchTenantArgs = {
  searchRequest: SearchRequestInput;
};


export type QuerySearchTenantReportArgs = {
  fileName: Scalars['String']['input'];
  isAsyncOn?: InputMaybe<Scalars['Boolean']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  searchRequest: SearchRequestInput;
};


export type QuerySegmentByIdArgs = {
  id: Scalars['Long']['input'];
};


export type QuerySegmentByUuidArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QuerySegmentKeyElementsArgs = {
  key?: InputMaybe<SegmentKey>;
  type?: InputMaybe<Type>;
};


export type QuerySegmentKeyTypeElementsArgs = {
  key?: InputMaybe<SegmentKey>;
  type?: InputMaybe<Type>;
};


export type QuerySegmentKeyTypesArgs = {
  key?: InputMaybe<SegmentKey>;
};


export type QuerySegmentKeysArgs = {
  type?: InputMaybe<Type>;
};


export type QuerySegmentListArgs = {
  searchRequest: SearchRequestInput;
};


export type QueryValidateAutomatorConnectionAddedEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorConnectionAddedEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorConnectionRemovedEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorConnectionRemovedEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorCreateObjectActionWorkflowElementArgs = {
  data?: InputMaybe<AutomatorCreateObjectActionWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorDelayFlowWorkflowElementArgs = {
  data?: InputMaybe<AutomatorDelayFlowWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorDeleteObjectActionWorkflowElementArgs = {
  data?: InputMaybe<AutomatorDeleteObjectActionWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorGetObjectListActionWorkflowElementArgs = {
  data?: InputMaybe<AutomatorGetObjectListActionWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorGetOneObjectActionWorkflowElementArgs = {
  data?: InputMaybe<AutomatorGetOneObjectActionWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorLocalChoiceFlowWorkflowElementArgs = {
  data?: InputMaybe<AutomatorLocalChoiceFlowWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorLocalFlowFilterFlowWorkflowElementArgs = {
  data?: InputMaybe<AutomatorLocalFlowFilterFlowWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorMeetingEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorMeetingEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorMessageEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorMessageEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorObjectCreatedEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorObjectCreatedEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorObjectDeletedEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorObjectDeletedEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorObjectUpdatedEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorObjectUpdatedEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorQueryObjectExistsActionWorkflowElementArgs = {
  data?: InputMaybe<AutomatorQueryObjectExistsActionWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorSendEmailActionWorkflowElementArgs = {
  data?: InputMaybe<AutomatorSendEmailActionWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorSendMessageActionWorkflowElementArgs = {
  data?: InputMaybe<AutomatorSendMessageActionWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorSendSmsActionWorkflowElementArgs = {
  data?: InputMaybe<AutomatorSendSmsActionWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorSfdcAddToNestedUploadJobActionWorkflowElementArgs = {
  data?: InputMaybe<AutomatorSfdcAddToNestedUploadJobActionWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorSfdcAddToUploadJobActionWorkflowElementArgs = {
  data?: InputMaybe<AutomatorSfdcAddToUploadJobActionWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorSfdcObjectCreatedEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorSfdcObjectCreatedEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorSfdcObjectDeletedEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorSfdcObjectDeletedEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorSfdcObjectUndeletedEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorSfdcObjectUndeletedEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorSfdcObjectUpdatedEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorSfdcObjectUpdatedEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorTimeEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorTimeEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorUpdateObjectActionWorkflowElementArgs = {
  data?: InputMaybe<AutomatorUpdateObjectActionWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorWaitForMessageEventFlowWorkflowElementArgs = {
  data?: InputMaybe<AutomatorWaitForMessageEventFlowWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorWebhookActionWorkflowElementArgs = {
  data?: InputMaybe<AutomatorWebhookActionWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryValidateAutomatorWebhookEventTriggerWorkflowElementArgs = {
  data?: InputMaybe<AutomatorWebhookEventTriggerWorkflowElementInput>;
  elementId?: InputMaybe<Scalars['Long']['input']>;
};

/** Generated report information. */
export type ReportGraphQldto = {
  __typename?: 'ReportGraphQLDTO';
  /** Generated report id for getting progress */
  exportProgressFileId: Scalars['String']['output'];
  /** Generated report file id. */
  fileId: Scalars['String']['output'];
};

export type Representative = {
  __typename?: 'Representative';
  /** The business title of the representative */
  businessTitle?: Maybe<Scalars['String']['output']>;
  /** The contacts that representative is assigned to */
  contactList?: Maybe<Array<Maybe<Contact>>>;
  /** The total number of contacts that representative is assigned to */
  contactListTotal: Scalars['Long']['output'];
  /** The email of the representative */
  email?: Maybe<Scalars['String']['output']>;
  /** The external id of the representative (e.g. id from external system that data was imported from) */
  externalId?: Maybe<Scalars['String']['output']>;
  /** The first name of the representative */
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  /** The gender of the representative */
  gender?: Maybe<Gender>;
  groupList?: Maybe<Array<Maybe<Group>>>;
  /** The id of the representative */
  id: Scalars['Int']['output'];
  /** The last name of the representative */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The meetings that the representative participated in */
  meetingList?: Maybe<Array<Maybe<Meeting>>>;
  /** The total number of meeting that the representative participated in */
  meetingListTotal: Scalars['Long']['output'];
  /** The mobile phone number of the representative */
  phone?: Maybe<Scalars['String']['output']>;
  /** The photo of the representative */
  photo?: Maybe<ApiFile>;
  /** The external id from Salesforce.com */
  salesforceId?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};


export type RepresentativeContactListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RepresentativeGroupListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RepresentativeMeetingListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RepresentativeActionReferenceInput = {
  action: ActionReferenceType;
  idList?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RepresentativeInput = {
  /**
   * The business title of the representative
   * Validations
   * Max length: 250
   */
  businessTitle?: InputMaybe<Scalars['String']['input']>;
  /** The contacts that the representative is assigned to */
  contactActionReferenceList?: InputMaybe<Array<InputMaybe<ContactActionReferenceInput>>>;
  /**
   * The email of the representative
   * Validations
   * Unique: Value has to be unique across all contacts, representatives and product managers
   * Format: Value has to in email format e.g. name@company.com
   * Max length: 255
   */
  email?: InputMaybe<Scalars['String']['input']>;
  /**
   * The external id of the representative (e.g. id from external system that data was imported from)
   * Validations
   * Unique: Value has to be unique across all contacts, representatives and product managers
   * Max length: 50
   */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The first name of the representative
   * Validations
   * Max length: 255
   */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The gender of the representative */
  gender?: InputMaybe<Gender>;
  groupActionReferenceList?: InputMaybe<Array<InputMaybe<GroupActionReferenceInput>>>;
  /**
   * The last name of the representative
   * Validations
   * Max length: 255
   */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /**
   * The mobile phone number of the representative
   * Validations
   * Max length: 50
   */
  phone?: InputMaybe<Scalars['String']['input']>;
  /**
   * The external id from Salesforce.com
   * Validations
   * Max length: 255
   */
  salesforceId?: InputMaybe<Scalars['String']['input']>;
};

export type RepresentativeList = ObjectsList & {
  __typename?: 'RepresentativeList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Representative>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type RepresentativeReferenceInput = {
  id: Scalars['Int']['input'];
};

/** Search group. */
export type SearchLogicExpressionInput = {
  /** Logic operator. */
  operator: ELogicOperator;
  /** List of lists of expression elements. */
  valueLogicListList: Array<InputMaybe<ValueLogicListInput>>;
};

/** Search request group. */
export type SearchRequestInput = {
  pagination?: InputMaybe<PaginationRequestInput>;
  search?: InputMaybe<SearchLogicExpressionInput>;
};

/** Search group. */
export type SearchValueExpressionInput = {
  /** Key argument for operands. */
  key: Scalars['String']['input'];
  /** Value operator. */
  operator: EValueOperator;
  /** Value argument for operands, coresponding to type of key. */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Contact data */
export type SegmentContact = {
  __typename?: 'SegmentContact';
  academicRank?: Maybe<Scalars['String']['output']>;
  businessTitle?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailStatus?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  highpCancelUntilUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneNumberStatus?: Maybe<Scalars['String']['output']>;
  salesforceId?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

/** Segment data */
export type SegmentData = {
  __typename?: 'SegmentData';
  active?: Maybe<Scalars['Boolean']['output']>;
  filters?: Maybe<Scalars['SegmentExpressionScalar']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  matchedRecordsCount?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

/** An expression describing a filter operation on a contact defines the part of the segment */
export type SegmentExpressionInput = {
  /** List of nested expressions */
  expressions?: InputMaybe<Array<InputMaybe<SegmentExpressionInput>>>;
  /** The key to which the operation relates */
  key?: InputMaybe<SegmentKey>;
  /** Parameter controlling operations */
  operation?: InputMaybe<ExpressionOperator>;
  /** The value that will participate in the operation */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Segment input data */
export type SegmentInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<SegmentExpressionInput>;
  id?: InputMaybe<Scalars['Long']['input']>;
  matchedRecordsCount?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

/** Keys defining the selection of objects and their fields for operation */
export type SegmentKey =
  | 'CONTACT_ACADEMIC_RANK'
  | 'CONTACT_BUSINESS_TITLE'
  | 'CONTACT_EMAIL'
  | 'CONTACT_EMAIL_STATUS'
  | 'CONTACT_EXTERNAL_ID'
  | 'CONTACT_FIRST_NAME'
  | 'CONTACT_GENDER'
  | 'CONTACT_HIGHP_CANCEL_UNTIL_UTC'
  | 'CONTACT_ID'
  | 'CONTACT_LAST_NAME'
  | 'CONTACT_PHONE'
  | 'CONTACT_PHONE_NUMBER_STATUS'
  | 'CONTACT_SALESFORCE_ID'
  | 'CONTACT_UUID'
  | 'GROUP_ID'
  | 'GROUP_NAME'
  | 'GROUP_UUID'
  | 'MEETING_EVENT_ID'
  | 'MEETING_EVENT_TYPEIDENTIFIER'
  | 'MEETING_EVENT_UUID'
  | 'MEETING_EVENT_VALUE'
  | 'MEETING_ID'
  | 'MEETING_TITLE'
  | 'MEETING_UUID'
  | 'MEETING_ZONE_ID'
  | 'MESSAGE_CAMPAIGN_CAMPAIGNLANGUAGE'
  | 'MESSAGE_CAMPAIGN_DATEEND'
  | 'MESSAGE_CAMPAIGN_DATESTART'
  | 'MESSAGE_CAMPAIGN_FLAGS'
  | 'MESSAGE_CAMPAIGN_ID'
  | 'MESSAGE_CAMPAIGN_ISTRIAL'
  | 'MESSAGE_CAMPAIGN_NAME'
  | 'MESSAGE_CAMPAIGN_SCRIPT'
  | 'MESSAGE_CAMPAIGN_STATUS'
  | 'MESSAGE_CAMPAIGN_TITLE'
  | 'MESSAGE_CAMPAIGN_TYPE'
  | 'MESSAGE_CAMPAIGN_UUID'
  | 'REPRESENTATIVE_ACADEMIC_RANK'
  | 'REPRESENTATIVE_BUSINESS_TITLE'
  | 'REPRESENTATIVE_EMAIL'
  | 'REPRESENTATIVE_EMAIL_STATUS'
  | 'REPRESENTATIVE_EXTERNAL_ID'
  | 'REPRESENTATIVE_FIRST_NAME'
  | 'REPRESENTATIVE_GENDER'
  | 'REPRESENTATIVE_HIGHP_CANCEL_UNTIL_UTC'
  | 'REPRESENTATIVE_ID'
  | 'REPRESENTATIVE_LAST_NAME'
  | 'REPRESENTATIVE_PHONE'
  | 'REPRESENTATIVE_PHONE_NUMBER_STATUS'
  | 'REPRESENTATIVE_SALESFORCE_ID'
  | 'REPRESENTATIVE_UUID'
  | 'SPECIALIZATION_ID'
  | 'SPECIALIZATION_NAME'
  | 'SPECIALIZATION_UUID'
  | 'WORKPLACE_CITY'
  | 'WORKPLACE_EXTERNAL_ID'
  | 'WORKPLACE_ID'
  | 'WORKPLACE_NAME'
  | 'WORKPLACE_POSTALCODE'
  | 'WORKPLACE_STREET'
  | 'WORKPLACE_UUID'
  | 'WORKPLACE_WARD';

/** SegmentKey element description data */
export type SegmentKeyElement = {
  __typename?: 'SegmentKeyElement';
  attributeName?: Maybe<Scalars['String']['output']>;
  key?: Maybe<SegmentKey>;
  type?: Maybe<Type>;
};

/** SegmentKey.Type element description data */
export type SegmentKeyTypeElement = {
  __typename?: 'SegmentKeyTypeElement';
  keys?: Maybe<Array<Maybe<SegmentKey>>>;
  type?: Maybe<Type>;
};

export type SizeConstraintViolationGraphQlapiException = {
  __typename?: 'SizeConstraintViolationGraphQLAPIException';
  fieldPath?: Maybe<Scalars['String']['output']>;
  maxLength: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  minLength: Scalars['Int']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

/** Sort direction */
export type SortDirection =
  | 'ASC'
  | 'DESC';

/** Implements the pairing of an Sort.Direction and a property. It is used to provide input for Sort */
export type SortOrderDataInput = {
  direction?: InputMaybe<SortDirection>;
  property?: InputMaybe<Scalars['String']['input']>;
};

export type Specialization = {
  __typename?: 'Specialization';
  /** The contacts that specialization is assigned to */
  contactList?: Maybe<Array<Maybe<Contact>>>;
  /** The total number of contacts that specialization is assigned to */
  contactListTotal: Scalars['Long']['output'];
  /** The id of the specialization */
  id: Scalars['Int']['output'];
  /** The name of the specialization */
  name?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};


export type SpecializationContactListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SpecializationActionReferenceInput = {
  action: ActionReferenceType;
  idList?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SpecializationInput = {
  /** The contacts that the specialization is assigned to */
  contactActionReferenceList?: InputMaybe<Array<InputMaybe<ContactActionReferenceInput>>>;
  /**
   * The name of the specialization
   * Validations
   * Max length: 255
   */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SpecializationList = ObjectsList & {
  __typename?: 'SpecializationList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Specialization>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  accountBalance?: Maybe<Scalars['AccountBalance']['output']>;
  agreementDescription?: Maybe<Scalars['String']['output']>;
  agreementType?: Maybe<BillingAgreementType>;
  clientName?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['LocalDateTime']['output']>;
  estimateNumbers?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  insightsAccountBalance?: Maybe<Scalars['AccountBalance']['output']>;
  messagesAccountBalance?: Maybe<Scalars['AccountBalance']['output']>;
  presentationsAccountBalance?: Maybe<Scalars['AccountBalance']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  tenantName?: Maybe<Scalars['String']['output']>;
};

export type TenantDomainRecord = {
  __typename?: 'TenantDomainRecord';
  active: Scalars['Boolean']['output'];
  domainUrl?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['ID']['output']>;
  tenantName?: Maybe<Scalars['String']['output']>;
};

/** A page is a sublist of a list of objects. It allows gain information about the position of it in the containing entire list. */
export type TenantPage = {
  __typename?: 'TenantPage';
  /** Returns the page content as List. */
  content?: Maybe<Array<Maybe<Tenant>>>;
  /** File ID for download. */
  fileId?: Maybe<Scalars['String']['output']>;
  /** Returns whether the current Slice is the first one. */
  first: Scalars['Boolean']['output'];
  /** Returns whether the Slice has content at all. */
  hasContent: Scalars['Boolean']['output'];
  /** Returns if there is a next Slice. */
  hasNext: Scalars['Boolean']['output'];
  /** Returns if there is a previous Slice. */
  hasPrevious: Scalars['Boolean']['output'];
  /** Returns whether the current Slice is the last one. */
  last: Scalars['Boolean']['output'];
  /** Returns the number of the current Slice. */
  number: Scalars['Int']['output'];
  /** Returns the number of elements currently on this Slice. */
  numberOfElements: Scalars['Int']['output'];
  /** Returns the size of the Slice. */
  size: Scalars['Int']['output'];
  /** Returns the total amount of elements. */
  totalElements: Scalars['Long']['output'];
  /** Returns the number of total pages. */
  totalPages: Scalars['Int']['output'];
};

export type Timezone = {
  __typename?: 'Timezone';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  offset?: Maybe<Scalars['String']['output']>;
};

export type Translation = {
  __typename?: 'Translation';
  application?: Maybe<Application>;
  comment?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  systemKey?: Maybe<Scalars['String']['output']>;
  updatedAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type TranslationsGql = {
  __typename?: 'TranslationsGQL';
  translations?: Maybe<Scalars['StringTranslationValueGQLMapScalar']['output']>;
};

export type TranslationsGqlInput = {
  translations?: InputMaybe<Scalars['StringTranslationValueGQLMapScalar']['input']>;
};

export type Type =
  | 'CONTACT'
  | 'GROUP'
  | 'MEETING'
  | 'MEETING_EVENT'
  | 'MESSAGE_CAMPAIGN'
  | 'REPRESENTATIVE'
  | 'SPECIALIZATION'
  | 'WORKPLACE';

export type UpdateAccountBalanceChangedEventGraphQldto = AccountBalanceChangedEvent & {
  __typename?: 'UpdateAccountBalanceChangedEventGraphQLDTO';
  createdAtUTC?: Maybe<Scalars['LocalDateTime']['output']>;
  creditUnitsChange?: Maybe<Scalars['BigDecimal']['output']>;
  estimationNumber?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<AccountEventType>;
  id?: Maybe<Scalars['Long']['output']>;
  periodicBillGQL?: Maybe<PeriodicBillGql>;
  reasonOfChange?: Maybe<Scalars['String']['output']>;
  translationsGQL?: Maybe<TranslationsGql>;
};

export type UpdateAccountBalanceChangedEventGraphQldtoInput = {
  creditUnitsChange?: InputMaybe<Scalars['BigDecimal']['input']>;
  estimationNumber?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<AccountEventType>;
  id?: InputMaybe<Scalars['Long']['input']>;
  periodicBillGQL?: InputMaybe<PeriodicBillGqlInput>;
  reasonOfChange?: InputMaybe<Scalars['String']['input']>;
  translationsGQL?: InputMaybe<TranslationsGqlInput>;
};

export type UpdateTenantInput = {
  clientName?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['LocalDateTime']['input']>;
  estimateNumbers?: InputMaybe<Scalars['String']['input']>;
};

/** List of serch expressions elements. */
export type ValueLogicListInput = {
  searchLogicExpressionList?: InputMaybe<Array<InputMaybe<SearchLogicExpressionInput>>>;
  searchValueExpressionList?: InputMaybe<Array<InputMaybe<SearchValueExpressionInput>>>;
};

export type WhatsNew = {
  __typename?: 'WhatsNew';
  whatsNewItems?: Maybe<Array<Maybe<WhatsNewItem>>>;
};

export type WhatsNewItem = {
  __typename?: 'WhatsNewItem';
  appName?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  descriptions?: Maybe<Scalars['StringWhatsNewDescriptionMapScalar']['output']>;
  type?: Maybe<WhatsNewItemType>;
  url?: Maybe<Scalars['String']['output']>;
};

export type WhatsNewItemType =
  | 'COMING'
  | 'FIX'
  | 'NEW';

export type Workplace = {
  __typename?: 'Workplace';
  /** The city of the workplace */
  city?: Maybe<Scalars['String']['output']>;
  /** The contacts that workplace is assigned to */
  contactList?: Maybe<Array<Maybe<Contact>>>;
  /** The total number of contacts that workplace is assigned to */
  contactListTotal: Scalars['Long']['output'];
  /** The external id of the workplace (e.g. id from external system that data was imported from) */
  externalId?: Maybe<Scalars['String']['output']>;
  /** The id of the workplace */
  id: Scalars['Int']['output'];
  /** The name of the workplace */
  name?: Maybe<Scalars['String']['output']>;
  /** The postal code of the workplace */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** The street of the workplace */
  street?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
  /** The ward of the workplace */
  ward?: Maybe<Scalars['String']['output']>;
};


export type WorkplaceContactListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkplaceActionReferenceInput = {
  action: ActionReferenceType;
  idList?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type WorkplaceInput = {
  /**
   * The city of the workplace
   * Validations
   * Max length: 255
   */
  city?: InputMaybe<Scalars['String']['input']>;
  /** The contacts that the workplace is assigned to */
  contactActionReferenceList?: InputMaybe<Array<InputMaybe<ContactActionReferenceInput>>>;
  /**
   * The external id of the workplace (e.g. id from external system that data was imported from)
   * Validations
   * Unique: Value has to be unique across all workplaces
   * Max length: 50
   */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The name of the workplace
   * Validations
   * Max length: 255
   */
  name?: InputMaybe<Scalars['String']['input']>;
  /**
   * The postal code of the workplace
   * Validations
   * Max length: 255
   */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /**
   * The street of the workplace
   * Validations
   * Max length: 255
   */
  street?: InputMaybe<Scalars['String']['input']>;
  /**
   * The ward of the workplace
   * Validations
   * Max length: 255
   */
  ward?: InputMaybe<Scalars['String']['input']>;
};

export type WorkplaceList = ObjectsList & {
  __typename?: 'WorkplaceList';
  DATA_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  TOTAL_FIELD_NAME?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Workplace>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  order?: Maybe<Order>;
  total?: Maybe<Scalars['Long']['output']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: '__Type';
  kind: __TypeKind;
  name?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  specifiedByURL?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<__Field>>;
  interfaces?: Maybe<Array<__Type>>;
  possibleTypes?: Maybe<Array<__Type>>;
  enumValues?: Maybe<Array<__EnumValue>>;
  inputFields?: Maybe<Array<__InputValue>>;
  ofType?: Maybe<__Type>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export type __TypeKind =
  /** Indicates this type is a scalar. */
  | 'SCALAR'
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  | 'OBJECT'
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  | 'INTERFACE'
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  | 'UNION'
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  | 'ENUM'
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  | 'INPUT_OBJECT'
  /** Indicates this type is a list. `ofType` is a valid field. */
  | 'LIST'
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  | 'NON_NULL';

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: '__Field';
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  args: Array<__InputValue>;
  type: __Type;
  isDeprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
};


/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: '__InputValue';
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  isDeprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
};

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: '__EnumValue';
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isDeprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
};

export type MeetingFragment = { __typename?: 'Meeting', id: number, title?: string | null, startTimeUTC?: string | null, endTimeUTC?: string | null, duration?: number | null, participantListTotal: any, startPresentation?: { __typename?: 'Presentation', name?: string | null } | null, moderator?: { __typename?: 'Representative', firstName?: string | null, lastName?: string | null } | null };

export type MessageCampaignFragment = { __typename?: 'MessageCampaign', id: number, name?: string | null, language?: string | null, dateStartUTC?: string | null, status?: MessageCampaignStatus | null, type?: MessageCampaignType | null, lastModifiedAtUTC?: string | null, statistics?: { __typename?: 'MessageCampaignStatistics', numberOfOrderedMessages: number, numberOfSentMessages: number, numberOfOpenedMessages: number, numberOfCancelledMessages: number } | null };

export type ArchiveMessageCampaignMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type ArchiveMessageCampaignMutation = { __typename?: 'Mutation', archiveMessageCampaign?: { __typename?: 'MessageCampaign', id: number } | null };

export type CloneMessageCampaignMutationVariables = Exact<{
  campaignId: Scalars['Int']['input'];
}>;


export type CloneMessageCampaignMutation = { __typename?: 'Mutation', cloneMessageCampaign?: { __typename?: 'MessageCampaign', id: number } | null };

export type DeleteMessageCampaignMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteMessageCampaignMutation = { __typename?: 'Mutation', deleteMessageCampaign?: boolean | null };

export type GetBatchJobsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy?: InputMaybe<OrderInput>;
}>;


export type GetBatchJobsQuery = { __typename?: 'Query', getBatchJobList?: { __typename?: 'BatchJobList', total?: any | null, data?: Array<{ __typename?: 'BatchJob', details?: string | null, id?: number | null, instanceSynchronizable?: boolean | null, lastUpdatedAt?: any | null, name?: string | null, progress?: number | null, startedAt?: any | null, startedById?: number | null, type?: string | null, uuid?: string | null, working?: boolean | null, userName?: string | null } | null> | null } | null };

export type GetBatchJobProgressListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBatchJobProgressListQuery = { __typename?: 'Query', getBatchJobProgressList?: Array<{ __typename?: 'BatchJobProgress', jobId?: number | null, progress?: number | null } | null> | null };

export type AccountAggregatesQueryVariables = Exact<{
  beginTime: Scalars['LocalDateTime']['input'];
  endTime: Scalars['LocalDateTime']['input'];
  tenantId: Scalars['String']['input'];
}>;


export type AccountAggregatesQuery = { __typename?: 'Query', getAccountAggregates?: { __typename?: 'AccountAggregates', asOfFirst?: any | null, currentState?: any | null, usedAtPeriod?: any | null } | null };

export type ContactFragment = { __typename?: 'Contact', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, externalId?: string | null, academicRank?: AcademicRank | null, businessTitle?: string | null, gender?: Gender | null, highpCancelUntilUTC?: string | null, phone?: string | null, salesforceId?: string | null, version?: number | null, groupList?: Array<{ __typename?: 'Group', id: number } | null> | null, meetingList?: Array<{ __typename?: 'Meeting', id: number } | null> | null, representativeList?: Array<{ __typename?: 'Representative', id: number } | null> | null, specializationList?: Array<{ __typename?: 'Specialization', id: number } | null> | null, workplaceList?: Array<{ __typename?: 'Workplace', id: number } | null> | null, customFieldList?: Array<{ __typename?: 'ContactCustomField', fieldName?: string | null, valueList?: Array<string | null> | null } | null> | null };

export type ContactListItemFragment = { __typename?: 'Contact', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, externalId?: string | null, academicRank?: AcademicRank | null, businessTitle?: string | null, gender?: Gender | null, highpCancelUntilUTC?: string | null, phone?: string | null, salesforceId?: string | null, version?: number | null, phoneNumberStatus?: PhoneNumberStatus | null };

export type ContactWithTotalRelationsFragment = { __typename?: 'Contact', id: number, groupListTotal: any, specializationListTotal: any, workplaceListTotal: any };

export type GetContactQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetContactQuery = { __typename?: 'Query', getContact?: { __typename?: 'Contact', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, externalId?: string | null, academicRank?: AcademicRank | null, businessTitle?: string | null, gender?: Gender | null, highpCancelUntilUTC?: string | null, phone?: string | null, salesforceId?: string | null, version?: number | null, groupList?: Array<{ __typename?: 'Group', id: number } | null> | null, meetingList?: Array<{ __typename?: 'Meeting', id: number } | null> | null, representativeList?: Array<{ __typename?: 'Representative', id: number } | null> | null, specializationList?: Array<{ __typename?: 'Specialization', id: number } | null> | null, workplaceList?: Array<{ __typename?: 'Workplace', id: number } | null> | null, customFieldList?: Array<{ __typename?: 'ContactCustomField', fieldName?: string | null, valueList?: Array<string | null> | null } | null> | null } | null };

export type GetContactListWithTotalsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: OrderInput;
  filter?: InputMaybe<CompoundFilterInput>;
}>;


export type GetContactListWithTotalsQuery = { __typename?: 'Query', getContactList?: { __typename?: 'ContactList', total?: any | null, data?: Array<{ __typename?: 'Contact', id: number, groupListTotal: any, specializationListTotal: any, workplaceListTotal: any } | null> | null } | null };

export type GetContactListQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: OrderInput;
  filter?: InputMaybe<CompoundFilterInput>;
}>;


export type GetContactListQuery = { __typename?: 'Query', getContactList?: { __typename?: 'ContactList', total?: any | null, data?: Array<{ __typename?: 'Contact', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, externalId?: string | null, academicRank?: AcademicRank | null, businessTitle?: string | null, gender?: Gender | null, highpCancelUntilUTC?: string | null, phone?: string | null, salesforceId?: string | null, version?: number | null, phoneNumberStatus?: PhoneNumberStatus | null, groupList?: Array<{ __typename?: 'Group', id: number } | null> | null, specializationList?: Array<{ __typename?: 'Specialization', id: number } | null> | null, workplaceList?: Array<{ __typename?: 'Workplace', id: number } | null> | null, customFieldList?: Array<{ __typename?: 'ContactCustomField', fieldName?: string | null, fieldType?: FormFieldDefinitionType | null, valueList?: Array<string | null> | null } | null> | null } | null> | null } | null };

export type GetContactListWithRelationLimitsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: OrderInput;
  filter?: InputMaybe<CompoundFilterInput>;
  relationLimit: Scalars['Int']['input'];
  relationOffset: Scalars['Int']['input'];
}>;


export type GetContactListWithRelationLimitsQuery = { __typename?: 'Query', getContactList?: { __typename?: 'ContactList', total?: any | null, data?: Array<{ __typename?: 'Contact', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, externalId?: string | null, academicRank?: AcademicRank | null, businessTitle?: string | null, gender?: Gender | null, highpCancelUntilUTC?: string | null, phone?: string | null, salesforceId?: string | null, version?: number | null, phoneNumberStatus?: PhoneNumberStatus | null, groupList?: Array<{ __typename?: 'Group', id: number } | null> | null, specializationList?: Array<{ __typename?: 'Specialization', id: number } | null> | null, workplaceList?: Array<{ __typename?: 'Workplace', id: number } | null> | null, customFieldList?: Array<{ __typename?: 'ContactCustomField', fieldName?: string | null, fieldType?: FormFieldDefinitionType | null, valueList?: Array<string | null> | null } | null> | null } | null> | null } | null };

export type CreateContactMutationVariables = Exact<{
  data: ContactInput;
}>;


export type CreateContactMutation = { __typename?: 'Mutation', createContact?: { __typename?: 'Contact', id: number } | null };

export type UpdateContactMutationVariables = Exact<{
  data: ContactInput;
  id: Scalars['Int']['input'];
  version: Scalars['Int']['input'];
}>;


export type UpdateContactMutation = { __typename?: 'Mutation', updateContact?: { __typename?: 'Contact', id: number } | null };

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteContactMutation = { __typename?: 'Mutation', deleteContact?: boolean | null };

export type DeleteBulkContactMutationVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type DeleteBulkContactMutation = { __typename?: 'Mutation', deleteBulkContact?: boolean | null };

export type CustomFieldValuesFragment = { __typename?: 'CustomFieldDefinition', values?: Array<{ __typename?: 'EnumValue', label?: string | null, name?: string | null } | null> | null };

export type CustomFieldFragment = { __typename?: 'CustomFieldDefinition', id?: number | null, isactive?: boolean | null, label?: string | null, name?: string | null, type?: FormFieldDefinitionType | null, values?: Array<{ __typename?: 'EnumValue', label?: string | null, name?: string | null } | null> | null };

export type CreateCustomFieldMutationVariables = Exact<{
  data: CustomFieldDefinitionInput;
}>;


export type CreateCustomFieldMutation = { __typename?: 'Mutation', createCustomFieldDefinition?: { __typename?: 'CustomFieldDefinition', id?: number | null, isactive?: boolean | null, label?: string | null, name?: string | null, type?: FormFieldDefinitionType | null, values?: Array<{ __typename?: 'EnumValue', label?: string | null, name?: string | null } | null> | null } | null };

export type UpdateCustomFieldMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  data: CustomFieldDefinitionInput;
}>;


export type UpdateCustomFieldMutation = { __typename?: 'Mutation', updateCustomFieldDefinition?: { __typename?: 'CustomFieldDefinition', id?: number | null, isactive?: boolean | null, label?: string | null, name?: string | null, type?: FormFieldDefinitionType | null, values?: Array<{ __typename?: 'EnumValue', label?: string | null, name?: string | null } | null> | null } | null };

export type GetCustomFieldListQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy?: InputMaybe<OrderInput>;
  filter?: InputMaybe<CompoundFilterInput>;
}>;


export type GetCustomFieldListQuery = { __typename?: 'Query', getCustomFieldDefinitionList?: { __typename?: 'CustomFieldDefinitionList', total?: any | null, data?: Array<{ __typename?: 'CustomFieldDefinition', id?: number | null, isactive?: boolean | null, label?: string | null, name?: string | null, type?: FormFieldDefinitionType | null, values?: Array<{ __typename?: 'EnumValue', label?: string | null, name?: string | null } | null> | null } | null> | null } | null };

export type GetCustomFieldQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetCustomFieldQuery = { __typename?: 'Query', getCustomFieldDefinition?: { __typename?: 'CustomFieldDefinition', id?: number | null, isactive?: boolean | null, label?: string | null, name?: string | null, type?: FormFieldDefinitionType | null, values?: Array<{ __typename?: 'EnumValue', label?: string | null, name?: string | null } | null> | null } | null };

export type DeleteBulkCustomFieldsMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteBulkCustomFieldsMutation = { __typename?: 'Mutation', deleteBulkCustomFieldDefinition?: boolean | null };

export type GroupFragment = { __typename?: 'Group', id: number, name?: string | null, version?: number | null, contactListTotal: any, representativeListTotal: any };

export type GetGroupQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetGroupQuery = { __typename?: 'Query', getGroup?: { __typename?: 'Group', id: number, name?: string | null, version?: number | null, contactListTotal: any, representativeListTotal: any } | null };

export type GetGroupListQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
  filter?: InputMaybe<CompoundFilterInput>;
}>;


export type GetGroupListQuery = { __typename?: 'Query', getGroupList?: { __typename?: 'GroupList', total?: any | null, data?: Array<{ __typename?: 'Group', id: number, name?: string | null, version?: number | null, contactListTotal: any, representativeListTotal: any } | null> | null } | null };

export type CreateGroupMutationVariables = Exact<{
  data: GroupInput;
}>;


export type CreateGroupMutation = { __typename?: 'Mutation', createGroup?: { __typename?: 'Group', id: number } | null };

export type UpdateGroupMutationVariables = Exact<{
  data: GroupInput;
  id: Scalars['Int']['input'];
  version: Scalars['Int']['input'];
}>;


export type UpdateGroupMutation = { __typename?: 'Mutation', updateGroup?: { __typename?: 'Group', id: number } | null };

export type DeleteBulkGroupMutationVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type DeleteBulkGroupMutation = { __typename?: 'Mutation', deleteBulkGroup?: boolean | null };

export type LeadFragment = { __typename?: 'Lead', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, externalId?: string | null, academicRank?: AcademicRank | null, businessTitle?: string | null, gender?: Gender | null, phone?: string | null, version?: number | null, customFieldList?: Array<{ __typename?: 'LeadCustomField', fieldName?: string | null, valueList?: Array<string | null> | null } | null> | null };

export type GetLeadQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetLeadQuery = { __typename?: 'Query', getLead?: { __typename?: 'Lead', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, externalId?: string | null, academicRank?: AcademicRank | null, businessTitle?: string | null, gender?: Gender | null, phone?: string | null, version?: number | null, customFieldList?: Array<{ __typename?: 'LeadCustomField', fieldName?: string | null, valueList?: Array<string | null> | null } | null> | null } | null };

export type GetLeadListQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: OrderInput;
  filter?: InputMaybe<CompoundFilterInput>;
}>;


export type GetLeadListQuery = { __typename?: 'Query', getLeadList?: { __typename?: 'LeadList', total?: any | null, data?: Array<{ __typename?: 'Lead', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, externalId?: string | null, academicRank?: AcademicRank | null, businessTitle?: string | null, gender?: Gender | null, phone?: string | null, version?: number | null, customFieldList?: Array<{ __typename?: 'LeadCustomField', fieldName?: string | null, fieldType?: FormFieldDefinitionType | null, valueList?: Array<string | null> | null } | null> | null } | null> | null } | null };

export type CreateLeadMutationVariables = Exact<{
  data: LeadInput;
}>;


export type CreateLeadMutation = { __typename?: 'Mutation', createLead?: { __typename?: 'Lead', id: number } | null };

export type UpdateLeadMutationVariables = Exact<{
  data: LeadInput;
  id: Scalars['Int']['input'];
  version: Scalars['Int']['input'];
}>;


export type UpdateLeadMutation = { __typename?: 'Mutation', updateLead?: { __typename?: 'Lead', id: number } | null };

export type DeleteBulkLeadMutationVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type DeleteBulkLeadMutation = { __typename?: 'Mutation', deleteBulkLead?: boolean | null };

export type ConvertLeadsToContactsMutationVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type ConvertLeadsToContactsMutation = { __typename?: 'Mutation', convertLeadsToContacts?: Array<{ __typename?: 'Contact', id: number } | null> | null };

export type ConvertLeadToContactMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type ConvertLeadToContactMutation = { __typename?: 'Mutation', convertLeadToContact?: { __typename?: 'Contact', id: number, email?: string | null } | null };

export type RepresentativeFragment = { __typename?: 'Representative', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, groupList?: Array<{ __typename?: 'Group', id: number } | null> | null };

export type GetRepresentativeListQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: OrderInput;
  filter?: InputMaybe<CompoundFilterInput>;
}>;


export type GetRepresentativeListQuery = { __typename?: 'Query', getRepresentativeList?: { __typename?: 'RepresentativeList', total?: any | null, data?: Array<{ __typename?: 'Representative', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, groupList?: Array<{ __typename?: 'Group', id: number } | null> | null } | null> | null } | null };

export type SegmentFragment = { __typename?: 'SegmentData', id?: any | null, name?: string | null, version?: number | null, filters?: any | null, active?: boolean | null, matchedRecordsCount?: number | null };

export type SegmentContactFragment = { __typename?: 'SegmentContact', id?: any | null, email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null };

export type GetSegmentQueryVariables = Exact<{
  id: Scalars['Long']['input'];
}>;


export type GetSegmentQuery = { __typename?: 'Query', segmentById?: { __typename?: 'SegmentData', id?: any | null, name?: string | null, version?: number | null, filters?: any | null, active?: boolean | null, matchedRecordsCount?: number | null } | null };

export type GetSegmentListQueryVariables = Exact<{
  Request: SearchRequestInput;
}>;


export type GetSegmentListQuery = { __typename?: 'Query', segmentList?: { __typename?: 'PageData_SegmentData', totalElements: any, totalPages: number, content?: Array<{ __typename?: 'SegmentData', id?: any | null, name?: string | null, version?: number | null, filters?: any | null, active?: boolean | null, matchedRecordsCount?: number | null } | null> | null } | null };

export type CreateSegmentMutationVariables = Exact<{
  data: SegmentInput;
}>;


export type CreateSegmentMutation = { __typename?: 'Mutation', createSegment?: boolean | null };

export type EditSegmentMutationVariables = Exact<{
  data: SegmentInput;
}>;


export type EditSegmentMutation = { __typename?: 'Mutation', editSegment?: boolean | null };

export type DeleteSegmentMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars['Long']['input']>> | InputMaybe<Scalars['Long']['input']>;
}>;


export type DeleteSegmentMutation = { __typename?: 'Mutation', deleteSegmentById: boolean };

export type RecalculateSegmentMutationVariables = Exact<{
  segmentId: Scalars['Long']['input'];
}>;


export type RecalculateSegmentMutation = { __typename?: 'Mutation', recalculateSegmentById?: any | null };

export type ExecuteContactsFilterQueryVariables = Exact<{
  request: SegmentExpressionInput;
  pagination: PaginationRequestInput;
}>;


export type ExecuteContactsFilterQuery = { __typename?: 'Query', executeContactsFilter?: { __typename?: 'PageData_SegmentContact', totalElements: any, totalPages: number, content?: Array<{ __typename?: 'SegmentContact', id?: any | null, email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null } | null> | null } | null };

export type GetSegmentKeysQueryVariables = Exact<{
  request?: InputMaybe<Type>;
}>;


export type GetSegmentKeysQuery = { __typename?: 'Query', segmentKeys?: Array<SegmentKey | null> | null };

export type GetSegmentKeyTypesQueryVariables = Exact<{
  request?: InputMaybe<SegmentKey>;
}>;


export type GetSegmentKeyTypesQuery = { __typename?: 'Query', segmentKeyTypes?: Array<Type | null> | null };

export type GetSegmentKeyTypeElementsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSegmentKeyTypeElementsQuery = { __typename?: 'Query', segmentKeyTypeElements?: Array<{ __typename?: 'SegmentKeyTypeElement', type?: Type | null, keys?: Array<SegmentKey | null> | null } | null> | null };

export type SpecializationFragment = { __typename?: 'Specialization', id: number, name?: string | null, contactListTotal: any, version?: number | null };

export type GetSpecializationQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetSpecializationQuery = { __typename?: 'Query', getSpecialization?: { __typename?: 'Specialization', id: number, name?: string | null, contactListTotal: any, version?: number | null } | null };

export type GetSpecializationListQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: OrderInput;
  filter?: InputMaybe<CompoundFilterInput>;
}>;


export type GetSpecializationListQuery = { __typename?: 'Query', getSpecializationList?: { __typename?: 'SpecializationList', total?: any | null, data?: Array<{ __typename?: 'Specialization', id: number, name?: string | null, contactListTotal: any, version?: number | null } | null> | null } | null };

export type CreateSpecializationMutationVariables = Exact<{
  data: SpecializationInput;
}>;


export type CreateSpecializationMutation = { __typename?: 'Mutation', createSpecialization?: { __typename?: 'Specialization', id: number } | null };

export type UpdateSpecializationMutationVariables = Exact<{
  data: SpecializationInput;
  id: Scalars['Int']['input'];
  version: Scalars['Int']['input'];
}>;


export type UpdateSpecializationMutation = { __typename?: 'Mutation', updateSpecialization?: { __typename?: 'Specialization', id: number } | null };

export type DeleteBulkSpecializationMutationVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type DeleteBulkSpecializationMutation = { __typename?: 'Mutation', deleteBulkSpecialisation?: boolean | null };

export type GetWhatsNewListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWhatsNewListQuery = { __typename?: 'Query', getWhatsNewList?: { __typename?: 'WhatsNew', whatsNewItems?: Array<{ __typename?: 'WhatsNewItem', appName?: string | null, date?: string | null, descriptions?: any | null, type?: WhatsNewItemType | null, url?: string | null } | null> | null } | null };

export type WhatsNewFragment = { __typename?: 'WhatsNew', whatsNewItems?: Array<{ __typename?: 'WhatsNewItem', appName?: string | null, date?: string | null, descriptions?: any | null, type?: WhatsNewItemType | null, url?: string | null } | null> | null };

export type WorkflowFragment = { __typename?: 'AutomatorWorkflowShort', id?: any | null, name?: string | null, state?: AutomatorWorkflowState | null, version?: number | null, lastUpdatedAtUTC?: any | null };

export type GetAutomatorWorkflowListQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy?: InputMaybe<AutomatorOrderInput>;
}>;


export type GetAutomatorWorkflowListQuery = { __typename?: 'Query', getAutomatorWorkflowWithoutElementsList?: { __typename?: 'AutomatorWorkflowShortList', total?: any | null, data?: Array<{ __typename?: 'AutomatorWorkflowShort', id?: any | null, name?: string | null, state?: AutomatorWorkflowState | null, version?: number | null, lastUpdatedAtUTC?: any | null } | null> | null } | null };

export type WorkplaceFragment = { __typename?: 'Workplace', id: number, name?: string | null, ward?: string | null, street?: string | null, postalCode?: string | null, city?: string | null, externalId?: string | null, version?: number | null };

export type GetWorkplaceQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetWorkplaceQuery = { __typename?: 'Query', getWorkplace?: { __typename?: 'Workplace', id: number, name?: string | null, ward?: string | null, street?: string | null, postalCode?: string | null, city?: string | null, externalId?: string | null, version?: number | null } | null };

export type GetWorkplaceListQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: OrderInput;
  filter?: InputMaybe<CompoundFilterInput>;
}>;


export type GetWorkplaceListQuery = { __typename?: 'Query', getWorkplaceList?: { __typename?: 'WorkplaceList', total?: any | null, data?: Array<{ __typename?: 'Workplace', id: number, name?: string | null, ward?: string | null, street?: string | null, postalCode?: string | null, city?: string | null, externalId?: string | null, version?: number | null } | null> | null } | null };

export type CreateWorkplaceMutationVariables = Exact<{
  data: WorkplaceInput;
}>;


export type CreateWorkplaceMutation = { __typename?: 'Mutation', createWorkplace?: { __typename?: 'Workplace', id: number } | null };

export type UpdateWorkplaceMutationVariables = Exact<{
  data: WorkplaceInput;
  id: Scalars['Int']['input'];
  version: Scalars['Int']['input'];
}>;


export type UpdateWorkplaceMutation = { __typename?: 'Mutation', updateWorkplace?: { __typename?: 'Workplace', id: number } | null };

export type DeleteBulkWorkplaceMutationVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type DeleteBulkWorkplaceMutation = { __typename?: 'Mutation', deleteBulkWorkplace?: boolean | null };

export type ExportTaskFragment = { __typename?: 'ExportTask', alreadyStarted?: boolean | null, success?: boolean | null };

export type CreateXlsExportQueryVariables = Exact<{
  exportType?: InputMaybe<ExportTypes>;
}>;


export type CreateXlsExportQuery = { __typename?: 'Query', createXlsExport?: { __typename?: 'ExportTask', success?: boolean | null, alreadyStarted?: boolean | null } | null };

export type CustomFieldDefinitionFragment = { __typename?: 'CustomFieldDefinition', name?: string | null, type?: FormFieldDefinitionType | null, label?: string | null, values?: Array<{ __typename?: 'EnumValue', label?: string | null, name?: string | null } | null> | null };

export type GetCustomFieldDefinitionResponseQueryVariables = Exact<{
  objectName: Scalars['String']['input'];
}>;


export type GetCustomFieldDefinitionResponseQuery = { __typename?: 'Query', getCustomFieldDefinitionResponse?: { __typename?: 'CustomFieldDefinitionResponse', customFieldDefinitionEntryList?: Array<{ __typename?: 'CustomFieldDefinitionEntry', objectName?: string | null, customFieldDefinitionList?: Array<{ __typename?: 'CustomFieldDefinition', name?: string | null, type?: FormFieldDefinitionType | null, label?: string | null, values?: Array<{ __typename?: 'EnumValue', label?: string | null, name?: string | null } | null> | null } | null> | null } | null> | null } | null };

export type GetFieldOrderQueryVariables = Exact<{
  dtoClassName: Scalars['String']['input'];
}>;


export type GetFieldOrderQuery = { __typename?: 'Query', getFieldOrder?: { __typename?: 'FieldOrder', objectFieldOrderList?: Array<string | null> | null } | null };

export type FieldFragment = { __typename?: '__Field', name: string, type: { __typename?: '__Type', name?: string | null, kind: __TypeKind, enumValues?: Array<{ __typename?: '__EnumValue', name: string }> | null, ofType?: { __typename?: '__Type', name?: string | null, kind: __TypeKind } | null } };

export type GetFieldsQueryVariables = Exact<{
  objectName: Scalars['String']['input'];
}>;


export type GetFieldsQuery = { __typename?: 'Query', __type?: { __typename?: '__Type', fields?: Array<{ __typename?: '__Field', name: string, type: { __typename?: '__Type', name?: string | null, kind: __TypeKind, enumValues?: Array<{ __typename?: '__EnumValue', name: string }> | null, ofType?: { __typename?: '__Type', name?: string | null, kind: __TypeKind } | null } }> | null } | null };

export type HighpEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type HighpEventsQuery = { __typename?: 'Query', highpEvents?: Array<string | null> | null };

export type GetMeetingListQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderInput>;
  filter?: InputMaybe<CompoundFilterInput>;
}>;


export type GetMeetingListQuery = { __typename?: 'Query', getMeetingList?: { __typename?: 'MeetingList', total?: any | null, data?: Array<{ __typename?: 'Meeting', id: number, title?: string | null, startTimeUTC?: string | null, endTimeUTC?: string | null, duration?: number | null, participantListTotal: any, startPresentation?: { __typename?: 'Presentation', name?: string | null } | null, moderator?: { __typename?: 'Representative', firstName?: string | null, lastName?: string | null } | null } | null> | null } | null };

export type GetMessageCampaignListQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: OrderInput;
  filter?: InputMaybe<CompoundFilterInput>;
}>;


export type GetMessageCampaignListQuery = { __typename?: 'Query', getMessageCampaignList?: { __typename?: 'MessageCampaignList', total?: any | null, data?: Array<{ __typename?: 'MessageCampaign', id: number, name?: string | null, language?: string | null, dateStartUTC?: string | null, status?: MessageCampaignStatus | null, type?: MessageCampaignType | null, lastModifiedAtUTC?: string | null, statistics?: { __typename?: 'MessageCampaignStatistics', numberOfOrderedMessages: number, numberOfSentMessages: number, numberOfOpenedMessages: number, numberOfCancelledMessages: number } | null } | null> | null } | null };

export type GetMessageCampaignQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetMessageCampaignQuery = { __typename?: 'Query', getMessageCampaign?: { __typename?: 'MessageCampaign', dateEndUTC?: string | null, dateStartUTC?: string | null, enableMessagesToRepresentativesContacts?: boolean | null, enableMessagesWithPersonalizedVideo?: boolean | null, enableMultipleMessagesToOneRecipient?: boolean | null, id: number, isTrial?: boolean | null, language?: string | null, lastModifiedAtUTC?: string | null, messageElementListTotal: any, messageListTotal: any, name?: string | null, status?: MessageCampaignStatus | null, title?: string | null, type?: MessageCampaignType | null, version?: number | null, videoScript?: string | null } | null };

export const MeetingFragmentDoc = gql`
    fragment meeting on Meeting {
  id
  title
  startPresentation {
    name
  }
  startTimeUTC
  endTimeUTC
  duration
  moderator {
    firstName
    lastName
  }
  participantListTotal
}
    `;
export const MessageCampaignFragmentDoc = gql`
    fragment messageCampaign on MessageCampaign {
  id
  name
  statistics {
    numberOfOrderedMessages
    numberOfSentMessages
    numberOfOpenedMessages
    numberOfCancelledMessages
  }
  language
  dateStartUTC
  status
  type
  lastModifiedAtUTC
}
    `;
export const ContactFragmentDoc = gql`
    fragment Contact on Contact {
  id
  firstName
  lastName
  email
  externalId
  academicRank
  businessTitle
  gender
  highpCancelUntilUTC
  phone
  salesforceId
  groupList {
    id
  }
  meetingList {
    id
  }
  representativeList {
    id
  }
  specializationList {
    id
  }
  workplaceList {
    id
  }
  customFieldList {
    fieldName
    valueList
  }
  version
}
    `;
export const ContactListItemFragmentDoc = gql`
    fragment ContactListItem on Contact {
  id
  firstName
  lastName
  email
  externalId
  academicRank
  businessTitle
  gender
  highpCancelUntilUTC
  phone
  salesforceId
  version
  phoneNumberStatus
}
    `;
export const ContactWithTotalRelationsFragmentDoc = gql`
    fragment ContactWithTotalRelations on Contact {
  id
  groupListTotal
  specializationListTotal
  workplaceListTotal
}
    `;
export const CustomFieldValuesFragmentDoc = gql`
    fragment CustomFieldValues on CustomFieldDefinition {
  values {
    label
    name
  }
}
    `;
export const CustomFieldFragmentDoc = gql`
    fragment CustomField on CustomFieldDefinition {
  id
  isactive
  label
  name
  type
  ...CustomFieldValues
}
    ${CustomFieldValuesFragmentDoc}`;
export const GroupFragmentDoc = gql`
    fragment Group on Group {
  id
  name
  version
  contactListTotal
  representativeListTotal
}
    `;
export const LeadFragmentDoc = gql`
    fragment Lead on Lead {
  id
  firstName
  lastName
  email
  externalId
  academicRank
  businessTitle
  gender
  phone
  customFieldList {
    fieldName
    valueList
  }
  version
}
    `;
export const RepresentativeFragmentDoc = gql`
    fragment Representative on Representative {
  id
  firstName
  lastName
  email
  groupList {
    id
  }
}
    `;
export const SegmentFragmentDoc = gql`
    fragment Segment on SegmentData {
  id
  name
  version
  filters
  active
  matchedRecordsCount
}
    `;
export const SegmentContactFragmentDoc = gql`
    fragment SegmentContact on SegmentContact {
  id
  email
  firstName
  lastName
  phone
}
    `;
export const SpecializationFragmentDoc = gql`
    fragment Specialization on Specialization {
  id
  name
  contactListTotal
  version
}
    `;
export const WhatsNewFragmentDoc = gql`
    fragment whatsNew on WhatsNew {
  whatsNewItems {
    appName
    date
    descriptions
    type
    url
  }
}
    `;
export const WorkflowFragmentDoc = gql`
    fragment Workflow on AutomatorWorkflowShort {
  id
  name
  state
  version
  lastUpdatedAtUTC
}
    `;
export const WorkplaceFragmentDoc = gql`
    fragment Workplace on Workplace {
  id
  name
  ward
  street
  postalCode
  city
  externalId
  version
}
    `;
export const ExportTaskFragmentDoc = gql`
    fragment ExportTask on ExportTask {
  alreadyStarted
  success
}
    `;
export const CustomFieldDefinitionFragmentDoc = gql`
    fragment CustomFieldDefinition on CustomFieldDefinition {
  name
  type
  label
  values {
    label
    name
  }
}
    `;
export const FieldFragmentDoc = gql`
    fragment Field on __Field {
  name
  type {
    name
    kind
    enumValues {
      name
    }
    ofType {
      name
      kind
    }
  }
}
    `;
export const ArchiveMessageCampaignDocument = gql`
    mutation ArchiveMessageCampaign($id: Int!) {
  archiveMessageCampaign(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveMessageCampaignGQL extends Apollo.Mutation<ArchiveMessageCampaignMutation, ArchiveMessageCampaignMutationVariables> {
   override document = ArchiveMessageCampaignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CloneMessageCampaignDocument = gql`
    mutation CloneMessageCampaign($campaignId: Int!) {
  cloneMessageCampaign(toBeClonedMessageCampaignId: $campaignId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CloneMessageCampaignGQL extends Apollo.Mutation<CloneMessageCampaignMutation, CloneMessageCampaignMutationVariables> {
   override document = CloneMessageCampaignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMessageCampaignDocument = gql`
    mutation deleteMessageCampaign($id: Int!) {
  deleteMessageCampaign(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMessageCampaignGQL extends Apollo.Mutation<DeleteMessageCampaignMutation, DeleteMessageCampaignMutationVariables> {
   override document = DeleteMessageCampaignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBatchJobsDocument = gql`
    query getBatchJobs($offset: Int!, $limit: Int!, $orderBy: OrderInput) {
  getBatchJobList(offset: $offset, limit: $limit, orderBy: $orderBy) {
    data {
      details
      id
      instanceSynchronizable
      lastUpdatedAt
      name
      progress
      startedAt
      startedById
      type
      uuid
      working
      userName
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBatchJobsGQL extends Apollo.Query<GetBatchJobsQuery, GetBatchJobsQueryVariables> {
   override document = GetBatchJobsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBatchJobProgressListDocument = gql`
    query getBatchJobProgressList {
  getBatchJobProgressList {
    jobId
    progress
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBatchJobProgressListGQL extends Apollo.Query<GetBatchJobProgressListQuery, GetBatchJobProgressListQueryVariables> {
   override document = GetBatchJobProgressListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountAggregatesDocument = gql`
    query accountAggregates($beginTime: LocalDateTime!, $endTime: LocalDateTime!, $tenantId: String!) {
  getAccountAggregates(
    beginTime: $beginTime
    endTime: $endTime
    tenantId: $tenantId
  ) {
    asOfFirst
    currentState
    usedAtPeriod
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountAggregatesGQL extends Apollo.Query<AccountAggregatesQuery, AccountAggregatesQueryVariables> {
   override document = AccountAggregatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContactDocument = gql`
    query getContact($id: Int!) {
  getContact(id: $id) {
    ...Contact
  }
}
    ${ContactFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContactGQL extends Apollo.Query<GetContactQuery, GetContactQueryVariables> {
   override document = GetContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContactListWithTotalsDocument = gql`
    query getContactListWithTotals($offset: Int!, $limit: Int!, $orderBy: OrderInput!, $filter: CompoundFilterInput) {
  getContactList(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filter: $filter
  ) {
    data {
      ...ContactWithTotalRelations
    }
    total
  }
}
    ${ContactWithTotalRelationsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContactListWithTotalsGQL extends Apollo.Query<GetContactListWithTotalsQuery, GetContactListWithTotalsQueryVariables> {
   override document = GetContactListWithTotalsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContactListDocument = gql`
    query getContactList($offset: Int!, $limit: Int!, $orderBy: OrderInput!, $filter: CompoundFilterInput) {
  getContactList(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filter: $filter
  ) {
    data {
      ...ContactListItem
      groupList {
        id
      }
      specializationList {
        id
      }
      workplaceList {
        id
      }
      customFieldList {
        fieldName
        fieldType
        valueList
      }
    }
    total
  }
}
    ${ContactListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContactListGQL extends Apollo.Query<GetContactListQuery, GetContactListQueryVariables> {
   override document = GetContactListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContactListWithRelationLimitsDocument = gql`
    query getContactListWithRelationLimits($offset: Int!, $limit: Int!, $orderBy: OrderInput!, $filter: CompoundFilterInput, $relationLimit: Int!, $relationOffset: Int!) {
  getContactList(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filter: $filter
  ) {
    data {
      ...ContactListItem
      groupList(limit: $relationLimit, offset: $relationOffset) {
        id
      }
      specializationList(limit: $relationLimit, offset: $relationOffset) {
        id
      }
      workplaceList(limit: $relationLimit, offset: $relationOffset) {
        id
      }
      customFieldList {
        fieldName
        fieldType
        valueList
      }
    }
    total
  }
}
    ${ContactListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContactListWithRelationLimitsGQL extends Apollo.Query<GetContactListWithRelationLimitsQuery, GetContactListWithRelationLimitsQueryVariables> {
   override document = GetContactListWithRelationLimitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateContactDocument = gql`
    mutation createContact($data: ContactInput!) {
  createContact(contact: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateContactGQL extends Apollo.Mutation<CreateContactMutation, CreateContactMutationVariables> {
   override document = CreateContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateContactDocument = gql`
    mutation updateContact($data: ContactInput!, $id: Int!, $version: Int!) {
  updateContact(contact: $data, id: $id, version: $version) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateContactGQL extends Apollo.Mutation<UpdateContactMutation, UpdateContactMutationVariables> {
   override document = UpdateContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteContactDocument = gql`
    mutation deleteContact($id: Int!) {
  deleteContact(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteContactGQL extends Apollo.Mutation<DeleteContactMutation, DeleteContactMutationVariables> {
   override document = DeleteContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBulkContactDocument = gql`
    mutation deleteBulkContact($ids: [Int!]!) {
  deleteBulkContact(ids: $ids)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBulkContactGQL extends Apollo.Mutation<DeleteBulkContactMutation, DeleteBulkContactMutationVariables> {
   override document = DeleteBulkContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCustomFieldDocument = gql`
    mutation createCustomField($data: CustomFieldDefinitionInput!) {
  createCustomFieldDefinition(newObject: $data) {
    ...CustomField
  }
}
    ${CustomFieldFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCustomFieldGQL extends Apollo.Mutation<CreateCustomFieldMutation, CreateCustomFieldMutationVariables> {
   override document = CreateCustomFieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCustomFieldDocument = gql`
    mutation updateCustomField($id: Int!, $data: CustomFieldDefinitionInput!) {
  updateCustomFieldDefinition(id: $id, updatedObject: $data) {
    ...CustomField
  }
}
    ${CustomFieldFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCustomFieldGQL extends Apollo.Mutation<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables> {
   override document = UpdateCustomFieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCustomFieldListDocument = gql`
    query getCustomFieldList($offset: Int!, $limit: Int!, $orderBy: OrderInput, $filter: CompoundFilterInput) {
  getCustomFieldDefinitionList(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filter: $filter
  ) {
    data {
      ...CustomField
    }
    total
  }
}
    ${CustomFieldFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCustomFieldListGQL extends Apollo.Query<GetCustomFieldListQuery, GetCustomFieldListQueryVariables> {
   override document = GetCustomFieldListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCustomFieldDocument = gql`
    query getCustomField($id: Int!) {
  getCustomFieldDefinition(id: $id) {
    ...CustomField
  }
}
    ${CustomFieldFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCustomFieldGQL extends Apollo.Query<GetCustomFieldQuery, GetCustomFieldQueryVariables> {
   override document = GetCustomFieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBulkCustomFieldsDocument = gql`
    mutation deleteBulkCustomFields($ids: [Int]!) {
  deleteBulkCustomFieldDefinition(ids: $ids)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBulkCustomFieldsGQL extends Apollo.Mutation<DeleteBulkCustomFieldsMutation, DeleteBulkCustomFieldsMutationVariables> {
   override document = DeleteBulkCustomFieldsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGroupDocument = gql`
    query getGroup($id: Int!) {
  getGroup(id: $id) {
    ...Group
  }
}
    ${GroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGroupGQL extends Apollo.Query<GetGroupQuery, GetGroupQueryVariables> {
   override document = GetGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGroupListDocument = gql`
    query getGroupList($offset: Int, $limit: Int, $orderBy: OrderInput, $filter: CompoundFilterInput) {
  getGroupList(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
    data {
      ...Group
    }
    total
  }
}
    ${GroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGroupListGQL extends Apollo.Query<GetGroupListQuery, GetGroupListQueryVariables> {
   override document = GetGroupListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateGroupDocument = gql`
    mutation createGroup($data: GroupInput!) {
  createGroup(group: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGroupGQL extends Apollo.Mutation<CreateGroupMutation, CreateGroupMutationVariables> {
   override document = CreateGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGroupDocument = gql`
    mutation updateGroup($data: GroupInput!, $id: Int!, $version: Int!) {
  updateGroup(group: $data, id: $id, version: $version) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGroupGQL extends Apollo.Mutation<UpdateGroupMutation, UpdateGroupMutationVariables> {
   override document = UpdateGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBulkGroupDocument = gql`
    mutation deleteBulkGroup($ids: [Int!]!) {
  deleteBulkGroup(ids: $ids)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBulkGroupGQL extends Apollo.Mutation<DeleteBulkGroupMutation, DeleteBulkGroupMutationVariables> {
   override document = DeleteBulkGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLeadDocument = gql`
    query getLead($id: Int!) {
  getLead(id: $id) {
    ...Lead
  }
}
    ${LeadFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLeadGQL extends Apollo.Query<GetLeadQuery, GetLeadQueryVariables> {
   override document = GetLeadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLeadListDocument = gql`
    query getLeadList($offset: Int!, $limit: Int!, $orderBy: OrderInput!, $filter: CompoundFilterInput) {
  getLeadList(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
    data {
      ...Lead
      customFieldList {
        fieldName
        fieldType
        valueList
      }
    }
    total
  }
}
    ${LeadFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLeadListGQL extends Apollo.Query<GetLeadListQuery, GetLeadListQueryVariables> {
   override document = GetLeadListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateLeadDocument = gql`
    mutation createLead($data: LeadInput!) {
  createLead(lead: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateLeadGQL extends Apollo.Mutation<CreateLeadMutation, CreateLeadMutationVariables> {
   override document = CreateLeadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateLeadDocument = gql`
    mutation updateLead($data: LeadInput!, $id: Int!, $version: Int!) {
  updateLead(lead: $data, id: $id, version: $version) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLeadGQL extends Apollo.Mutation<UpdateLeadMutation, UpdateLeadMutationVariables> {
   override document = UpdateLeadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBulkLeadDocument = gql`
    mutation deleteBulkLead($ids: [Int!]!) {
  deleteBulkLead(ids: $ids)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBulkLeadGQL extends Apollo.Mutation<DeleteBulkLeadMutation, DeleteBulkLeadMutationVariables> {
   override document = DeleteBulkLeadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConvertLeadsToContactsDocument = gql`
    mutation convertLeadsToContacts($ids: [Int!]!) {
  convertLeadsToContacts(leadIds: $ids) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConvertLeadsToContactsGQL extends Apollo.Mutation<ConvertLeadsToContactsMutation, ConvertLeadsToContactsMutationVariables> {
   override document = ConvertLeadsToContactsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConvertLeadToContactDocument = gql`
    mutation convertLeadToContact($id: Int!) {
  convertLeadToContact(leadId: $id) {
    id
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConvertLeadToContactGQL extends Apollo.Mutation<ConvertLeadToContactMutation, ConvertLeadToContactMutationVariables> {
   override document = ConvertLeadToContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRepresentativeListDocument = gql`
    query getRepresentativeList($offset: Int!, $limit: Int!, $orderBy: OrderInput!, $filter: CompoundFilterInput) {
  getRepresentativeList(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filter: $filter
  ) {
    data {
      ...Representative
    }
    total
  }
}
    ${RepresentativeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRepresentativeListGQL extends Apollo.Query<GetRepresentativeListQuery, GetRepresentativeListQueryVariables> {
   override document = GetRepresentativeListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSegmentDocument = gql`
    query getSegment($id: Long!) {
  segmentById(id: $id) {
    ...Segment
  }
}
    ${SegmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSegmentGQL extends Apollo.Query<GetSegmentQuery, GetSegmentQueryVariables> {
   override document = GetSegmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSegmentListDocument = gql`
    query getSegmentList($Request: SearchRequestInput!) {
  segmentList(searchRequest: $Request) {
    totalElements
    totalPages
    content {
      ...Segment
    }
  }
}
    ${SegmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSegmentListGQL extends Apollo.Query<GetSegmentListQuery, GetSegmentListQueryVariables> {
   override document = GetSegmentListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSegmentDocument = gql`
    mutation createSegment($data: SegmentInput!) {
  createSegment(data: $data)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSegmentGQL extends Apollo.Mutation<CreateSegmentMutation, CreateSegmentMutationVariables> {
   override document = CreateSegmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditSegmentDocument = gql`
    mutation editSegment($data: SegmentInput!) {
  editSegment(data: $data)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditSegmentGQL extends Apollo.Mutation<EditSegmentMutation, EditSegmentMutationVariables> {
   override document = EditSegmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSegmentDocument = gql`
    mutation deleteSegment($ids: [Long]!) {
  deleteSegmentById(ids: $ids)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSegmentGQL extends Apollo.Mutation<DeleteSegmentMutation, DeleteSegmentMutationVariables> {
   override document = DeleteSegmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RecalculateSegmentDocument = gql`
    mutation recalculateSegment($segmentId: Long!) {
  recalculateSegmentById(segmentId: $segmentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RecalculateSegmentGQL extends Apollo.Mutation<RecalculateSegmentMutation, RecalculateSegmentMutationVariables> {
   override document = RecalculateSegmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExecuteContactsFilterDocument = gql`
    query executeContactsFilter($request: SegmentExpressionInput!, $pagination: PaginationRequestInput!) {
  executeContactsFilter(expression: $request, pagination: $pagination) {
    totalElements
    totalPages
    content {
      ...SegmentContact
    }
  }
}
    ${SegmentContactFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ExecuteContactsFilterGQL extends Apollo.Query<ExecuteContactsFilterQuery, ExecuteContactsFilterQueryVariables> {
   override document = ExecuteContactsFilterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSegmentKeysDocument = gql`
    query getSegmentKeys($request: Type) {
  segmentKeys(type: $request)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSegmentKeysGQL extends Apollo.Query<GetSegmentKeysQuery, GetSegmentKeysQueryVariables> {
   override document = GetSegmentKeysDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSegmentKeyTypesDocument = gql`
    query getSegmentKeyTypes($request: SegmentKey) {
  segmentKeyTypes(key: $request)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSegmentKeyTypesGQL extends Apollo.Query<GetSegmentKeyTypesQuery, GetSegmentKeyTypesQueryVariables> {
   override document = GetSegmentKeyTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSegmentKeyTypeElementsDocument = gql`
    query getSegmentKeyTypeElements {
  segmentKeyTypeElements(key: null, type: null) {
    type
    keys
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSegmentKeyTypeElementsGQL extends Apollo.Query<GetSegmentKeyTypeElementsQuery, GetSegmentKeyTypeElementsQueryVariables> {
   override document = GetSegmentKeyTypeElementsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSpecializationDocument = gql`
    query getSpecialization($id: Int!) {
  getSpecialization(id: $id) {
    ...Specialization
  }
}
    ${SpecializationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSpecializationGQL extends Apollo.Query<GetSpecializationQuery, GetSpecializationQueryVariables> {
   override document = GetSpecializationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSpecializationListDocument = gql`
    query getSpecializationList($offset: Int!, $limit: Int!, $orderBy: OrderInput!, $filter: CompoundFilterInput) {
  getSpecializationList(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filter: $filter
  ) {
    data {
      ...Specialization
    }
    total
  }
}
    ${SpecializationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSpecializationListGQL extends Apollo.Query<GetSpecializationListQuery, GetSpecializationListQueryVariables> {
   override document = GetSpecializationListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSpecializationDocument = gql`
    mutation createSpecialization($data: SpecializationInput!) {
  createSpecialization(specialization: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSpecializationGQL extends Apollo.Mutation<CreateSpecializationMutation, CreateSpecializationMutationVariables> {
   override document = CreateSpecializationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSpecializationDocument = gql`
    mutation updateSpecialization($data: SpecializationInput!, $id: Int!, $version: Int!) {
  updateSpecialization(specialization: $data, id: $id, version: $version) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSpecializationGQL extends Apollo.Mutation<UpdateSpecializationMutation, UpdateSpecializationMutationVariables> {
   override document = UpdateSpecializationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBulkSpecializationDocument = gql`
    mutation deleteBulkSpecialization($ids: [Int!]!) {
  deleteBulkSpecialisation(ids: $ids)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBulkSpecializationGQL extends Apollo.Mutation<DeleteBulkSpecializationMutation, DeleteBulkSpecializationMutationVariables> {
   override document = DeleteBulkSpecializationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetWhatsNewListDocument = gql`
    query getWhatsNewList {
  getWhatsNewList {
    ...whatsNew
  }
}
    ${WhatsNewFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetWhatsNewListGQL extends Apollo.Query<GetWhatsNewListQuery, GetWhatsNewListQueryVariables> {
   override document = GetWhatsNewListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAutomatorWorkflowListDocument = gql`
    query getAutomatorWorkflowList($offset: Int!, $limit: Int!, $orderBy: AutomatorOrderInput) {
  getAutomatorWorkflowWithoutElementsList(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
  ) {
    data {
      ...Workflow
    }
    total
  }
}
    ${WorkflowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAutomatorWorkflowListGQL extends Apollo.Query<GetAutomatorWorkflowListQuery, GetAutomatorWorkflowListQueryVariables> {
   override document = GetAutomatorWorkflowListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetWorkplaceDocument = gql`
    query getWorkplace($id: Int!) {
  getWorkplace(id: $id) {
    ...Workplace
  }
}
    ${WorkplaceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetWorkplaceGQL extends Apollo.Query<GetWorkplaceQuery, GetWorkplaceQueryVariables> {
   override document = GetWorkplaceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetWorkplaceListDocument = gql`
    query getWorkplaceList($offset: Int!, $limit: Int!, $orderBy: OrderInput!, $filter: CompoundFilterInput) {
  getWorkplaceList(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filter: $filter
  ) {
    data {
      ...Workplace
    }
    total
  }
}
    ${WorkplaceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetWorkplaceListGQL extends Apollo.Query<GetWorkplaceListQuery, GetWorkplaceListQueryVariables> {
   override document = GetWorkplaceListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateWorkplaceDocument = gql`
    mutation createWorkplace($data: WorkplaceInput!) {
  createWorkplace(workplace: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateWorkplaceGQL extends Apollo.Mutation<CreateWorkplaceMutation, CreateWorkplaceMutationVariables> {
   override document = CreateWorkplaceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateWorkplaceDocument = gql`
    mutation updateWorkplace($data: WorkplaceInput!, $id: Int!, $version: Int!) {
  updateWorkplace(workplace: $data, id: $id, version: $version) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateWorkplaceGQL extends Apollo.Mutation<UpdateWorkplaceMutation, UpdateWorkplaceMutationVariables> {
   override document = UpdateWorkplaceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBulkWorkplaceDocument = gql`
    mutation deleteBulkWorkplace($ids: [Int!]!) {
  deleteBulkWorkplace(ids: $ids)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBulkWorkplaceGQL extends Apollo.Mutation<DeleteBulkWorkplaceMutation, DeleteBulkWorkplaceMutationVariables> {
   override document = DeleteBulkWorkplaceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateXlsExportDocument = gql`
    query createXlsExport($exportType: ExportTypes) {
  createXlsExport(exportType: $exportType) {
    success
    alreadyStarted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateXlsExportGQL extends Apollo.Query<CreateXlsExportQuery, CreateXlsExportQueryVariables> {
   override document = CreateXlsExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCustomFieldDefinitionResponseDocument = gql`
    query getCustomFieldDefinitionResponse($objectName: String!) {
  getCustomFieldDefinitionResponse(objectName: $objectName) {
    customFieldDefinitionEntryList {
      objectName
      customFieldDefinitionList {
        ...CustomFieldDefinition
      }
    }
  }
}
    ${CustomFieldDefinitionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCustomFieldDefinitionResponseGQL extends Apollo.Query<GetCustomFieldDefinitionResponseQuery, GetCustomFieldDefinitionResponseQueryVariables> {
   override document = GetCustomFieldDefinitionResponseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFieldOrderDocument = gql`
    query getFieldOrder($dtoClassName: String!) {
  getFieldOrder(dtoClassName: $dtoClassName) {
    objectFieldOrderList
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFieldOrderGQL extends Apollo.Query<GetFieldOrderQuery, GetFieldOrderQueryVariables> {
   override document = GetFieldOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFieldsDocument = gql`
    query getFields($objectName: String!) {
  __type(name: $objectName) {
    fields {
      ...Field
    }
  }
}
    ${FieldFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFieldsGQL extends Apollo.Query<GetFieldsQuery, GetFieldsQueryVariables> {
   override document = GetFieldsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HighpEventsDocument = gql`
    query highpEvents {
  highpEvents
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HighpEventsGQL extends Apollo.Query<HighpEventsQuery, HighpEventsQueryVariables> {
   override document = HighpEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMeetingListDocument = gql`
    query getMeetingList($offset: Int, $limit: Int, $orderBy: OrderInput, $filter: CompoundFilterInput) {
  getMeetingList(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filter: $filter
  ) {
    data {
      ...meeting
    }
    total
  }
}
    ${MeetingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMeetingListGQL extends Apollo.Query<GetMeetingListQuery, GetMeetingListQueryVariables> {
   override document = GetMeetingListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMessageCampaignListDocument = gql`
    query getMessageCampaignList($offset: Int!, $limit: Int!, $orderBy: OrderInput!, $filter: CompoundFilterInput) {
  getMessageCampaignList(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filter: $filter
  ) {
    data {
      ...messageCampaign
    }
    total
  }
}
    ${MessageCampaignFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMessageCampaignListGQL extends Apollo.Query<GetMessageCampaignListQuery, GetMessageCampaignListQueryVariables> {
   override document = GetMessageCampaignListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMessageCampaignDocument = gql`
    query GetMessageCampaign($id: Int!) {
  getMessageCampaign(id: $id) {
    dateEndUTC
    dateStartUTC
    enableMessagesToRepresentativesContacts
    enableMessagesWithPersonalizedVideo
    enableMultipleMessagesToOneRecipient
    id
    isTrial
    language
    lastModifiedAtUTC
    messageElementListTotal
    messageListTotal
    name
    status
    title
    type
    version
    videoScript
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMessageCampaignGQL extends Apollo.Query<GetMessageCampaignQuery, GetMessageCampaignQueryVariables> {
   override document = GetMessageCampaignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }