import { Injectable } from '@angular/core';
import { CmUiNavbarButton, CmUiTableActionData, CmUiTableHeaderButton, CmUiTableHeaderInfoItem } from '@cm/ui-modules';
import { FrontendTranslateService } from '@shared/services/translation.service';
import { CONTACT_LIST_LEFT_MENU } from '../../globals';

@Injectable({
  providedIn: 'root',
})
export class MenuTranslator {
  constructor(private readonly translate: FrontendTranslateService) {}

  translateContactListMenu(): CmUiNavbarButton[] {
    const translated: CmUiNavbarButton[] = [];
    CONTACT_LIST_LEFT_MENU.forEach(item => {
      item.label = this.translate.instant(item.label);
      translated.push(item);
    });
    return translated;
  }
  translateCmUiNavbarButtons(buttons: CmUiNavbarButton[]): void {
    buttons.forEach(item => {
      item.label = this.translate.instant(item.label);
    });
  }
  translateTableActionButtons(buttons: CmUiTableActionData[]): void {
    buttons.forEach(item => {
      item.name = this.translate.instant(item.name);
    });
  }
  translateTableHeaderButtons(buttons: CmUiTableHeaderButton[]): void {
    buttons.forEach(item => {
      if (item.action !== 'timezone') {
        item.label = this.translate.instant(item.label);
      }
    });
  }

  translateTableHeaderInfoItems(items: CmUiTableHeaderInfoItem[]): void {
    items.forEach(item => {
        item.label = this.translate.instant(item.label);
      })
  }
}
