import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CmUiNavbarButton } from '@cm/ui-modules';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface Photo {
  url: string;
  link: string;
}

interface ConfigurationBase {
  [key: string]: string | null;
}

type Configuration = ConfigurationBase & {
  photos: Photo[];
};


export interface AppConfigObject {
  configuration: Configuration;
  links: CmUiNavbarButton[];
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private appConfigData = new BehaviorSubject<AppConfigObject>(null);
  constructor(
    private http: HttpClient
  ) {}

  public loadAppConfig(): Observable<any> {
    return this.http.get<AppConfigObject>('/api/rest/dashboard/config', {  responseType: 'json' })
    .pipe(
      catchError((error: any) => {
        console.log('Error loading config', error);
        return of({}); 
      })
    );
  }

  public getAppConfig(): Observable<AppConfigObject> {
    return this.appConfigData.asObservable();
  }

  public initAppConfig() {
    this.loadAppConfig().subscribe(data => {
      this.appConfigData.next(data);
    });
  }
  
  //todo: remove this method
  public appConfig() {
    return this.http.get<AppConfigObject>('/api/rest/dashboard/config', {
      responseType: 'json'
    });
  }
}
