import {FormActionButton} from '@cm/ui-modules';

export const DEFAULT_FORM_ACTION_BUTTONS: FormActionButton[] = [

  {
    color: 'warn',
    action: 'cancel',
    label: 'common.cancel',
    icon: 'cancel',
    side: 'left',
    visible: true,
    type: 'button',
  },
  {
    action: 'save',
    side: 'right',
    icon: 'done',
    label: 'common.save',
    visible: true,
    type: 'submit',
  }
];
