import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FrontendTranslateService extends TranslateService {

    override get(key: string, interpolateParams?: any): Observable<string | any> {
        // Trim the key before retrieving the translation
        const trimmedKey = key.trim();
        return super.get(trimmedKey, interpolateParams);
    }

    override instant(key: string, interpolateParams?: any): string | any {
        // Trim the key before retrieving the translation
        const trimmedKey = key.trim();
        return super.instant(trimmedKey, interpolateParams);
    }
}