import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {map, startWith} from 'rxjs/operators';
import {CmSearchField, CmUiTableColumn, CmUiTableFormatterFn} from '@cm/ui-modules';
import {Observable} from 'rxjs';
import {formatDate} from '@angular/common';
import {TableColumnDefinition, TableDefinition} from '../models';
import { GlobalTimezoneService } from './global-timezone.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class TableDefinitionService {
  constructor(
      private readonly translateService: TranslateService,
      private timezoneService: GlobalTimezoneService,
    ) {}

  toColumns(tableDefinition: TableDefinition): Observable<CmUiTableColumn[]> {
    return this.translateService.onLangChange.pipe(
      startWith(''),
      map(() => {
        const t = tableDefinition.map(
        columnDefinition => this.toColumn(columnDefinition)
      )
      return t;
    }
    ),
    );
  }

  toSearchFields(tableDefinition: TableDefinition): CmSearchField[] {
    return tableDefinition
      .filter(({ searchFieldType }) => !!searchFieldType)
      .map(({ prop, objectName, objectField, searchFieldType }) => ({
        prop,
        objectName,
        objectField: objectField || prop,
        type: searchFieldType,
      }));
  }

  private toColumn(definition: TableColumnDefinition): CmUiTableColumn {
    const { key, prop, visible, sortDisabled, searchFieldType, width } = definition;
    const name = this.translateService.instant(key);
    const formatter = this.getFormatter(definition);
    return {
      prop,
      visible,
      sortable: !sortDisabled,
      label: name,
      formatter,
      searchable: true,
      isCustomField: false,
      searchFieldType,
      width
    };
  }

  getFormatter(definition: TableColumnDefinition): CmUiTableFormatterFn {
    if (definition.dateFormat) {
      return this.getDateFormatter(definition.dateFormat);
    } else if (definition.valueKeyPrefix) {
      return this.getTranslateFormatter(definition.valueKeyPrefix);
    }
    else if(definition.transform){
      return value => definition.transform(value);
    }
    else {
      return null;
    }
  }

  private getDateFormatter(format: string): CmUiTableFormatterFn {
    const locale = this.translateService.currentLang;
    const timezone = this.timezoneService.getTimeZone();

    const formatterFunction = value => {
      //console.log("trans", value, format, locale, timezone)
      let timezoneNumberValue: string;
      try {
        value = value.match(/Z/g) ? value : value + 'Z';
        timezoneNumberValue = moment(value)
          .tz(timezone)
          .format('ZZ');
      } catch (e) {
        console.error(e);
      }
      return formatDate(value, format, locale, timezoneNumberValue);
    };

    return value => (value ? formatterFunction(value) : value);

    // const locale = this.translateService.currentLang;
    // return value => (value ? formatDate(value, format, locale) : value);
  }

  private getTranslateFormatter(prefix: string): CmUiTableFormatterFn {
    return value => (value ? this.translateService.instant(`${prefix}.${value}`) : value);
  }
}
