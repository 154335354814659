import { Component, Inject } from '@angular/core';
import { Moment } from 'moment-timezone';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-zone-modal',
  templateUrl: './timezone-modal.component.html',
  styleUrls: ['./timezone-modal.component.scss'],
})
export class TimezoneModalComponent {
  public selectZone: string;

  constructor(
    public dialogRef: MatDialogRef<TimezoneModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      moment: Moment;
      zonesList: {
        label: string;
        zoneName: string;
        cities: string;
        zoneTime: number;
      }[];
      currentZone: string;
    },
  ) {
    this.selectZone = data.currentZone;
  }

  onConfirm() {
    this.dialogRef.close({
      timezone: this.selectZone,
    });
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
