import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { NgIf, DatePipe } from '@angular/common';

@Component({
  selector: 'app-countdown',
  standalone: true,
  imports: [NgIf, DatePipe],
  template: `
    <div class="countdown-container" *ngIf="remainingTime > 0">
      Session expires in: {{remainingTimeFormatted}}
    </div>
  `,
  styles: [`
    .countdown-container {
      position: fixed;
      bottom: 10px;
      right: 10px;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 14px;
    }
  `]
})
export class CountdownComponent implements OnInit, OnDestroy {
  @Input() expirationTime: Date;
  remainingTime: number = 0;
  private timerSubscription: Subscription;
  public remainingTimeFormatted: string = '15:00';

  ngOnInit() {
    this.startCountdown();
  }

  ngOnDestroy() {
    if (this.timerSubscription) { 
      this.timerSubscription.unsubscribe();
    }
  }

  private startCountdown() {
    this.remainingTime = 15 * 60; // 15 minutes in seconds
    console.log('session expire timer is running', this.remainingTime)
    this.timerSubscription = interval(1000).subscribe(() => {
      this.remainingTime--; 
        const minutes = Math.floor(this.remainingTime / 60);
        const seconds = this.remainingTime % 60;
  
        // Format remaining time as mm:ss
        this.remainingTimeFormatted = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

        

        if (this.remainingTime <= 0) {
            this.timerSubscription.unsubscribe();
        }
    });
}
}