import {Injectable} from '@angular/core';
import {CmUiCompoundFilterObjectFieldOption} from '@cm/ui-modules';
import {
  FieldFragment,
  GetFieldOrderGQL,
  GetFieldsGQL,
  GetSegmentKeyTypeElementsGQL,
  GetSegmentKeyTypeElementsQueryVariables,
  GetSegmentKeyTypesGQL,
  GetSegmentKeyTypesQueryVariables,
  SegmentKeyTypeElement,
  Type
} from '@graphql';
import {FieldWithValue, ObjectName} from '@shared-models';
import {iif, Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

import {TranslateService} from '@ngx-translate/core';

// There are fields that user cannot fill, API should serve only insertable fields
const NON_INSERTABLE: string[] = ['fullName', 'sourceformId', 'graphQLEnumDefinition', 'graphQLFieldDefinition',
  'phoneNumberStatus', 'emailStatus'];
const NON_INSERTABLE_LEAD: string[] = ['fullName', 'sourceformId', 'graphQLEnumDefinition', 'graphQLFieldDefinition',  'externalId'];

@Injectable({
  providedIn: 'root',
})
export class GetFieldsService {
  constructor(private readonly getFieldsGQL: GetFieldsGQL,
              private readonly getSegmentKeyTypeElements: GetSegmentKeyTypeElementsGQL,
              private readonly getSegmentKeyTypesGQL: GetSegmentKeyTypesGQL,
              private readonly translateService: TranslateService,
              private readonly getFieldOrderGQL: GetFieldOrderGQL) {}

  getFields(objectName: ObjectName): Observable<FieldFragment[]> {
    return this.getFieldsGQL
      .fetch({ objectName }, { fetchPolicy: 'no-cache', errorPolicy: 'all' })
      .pipe(
        catchError(error => throwError(error)),
        map(response => response.data.__type.fields),
      );
  }
  getSegmentKeyTypes(variables: GetSegmentKeyTypesQueryVariables): Observable<Type[]> {
    return  this.getSegmentKeyTypesGQL.watch(variables, { errorPolicy: 'all', fetchPolicy : 'network-only' }).valueChanges.pipe(
      switchMap(result =>
        iif(() => !result.errors, of(result.data.segmentKeyTypes), throwError(result.errors)),
      ),
    );
  }
  getTypesAndKeys(variables: GetSegmentKeyTypeElementsQueryVariables): Observable<SegmentKeyTypeElement[]> {
    return this.getSegmentKeyTypeElements.watch(variables, { errorPolicy: 'all', fetchPolicy: 'network-only' })
    .valueChanges.pipe(
      switchMap(result =>
        iif(() => !result.errors, of(result.data.segmentKeyTypeElements), throwError(result.errors)),
      ),
    );
  }
  sortFields(objectName: ObjectName,
             fields: FieldWithValue[],
  ): Observable<FieldWithValue[]> {
    const sortArray = fieldArray => (a, b) => fieldArray.indexOf(b.name) - fieldArray.indexOf(a.name);
    return this.fetchFieldsOrder(objectName)
      .pipe(
        catchError(() => fields),
        map(result => fields.sort(sortArray(result)).reverse())
      );
  }

  getFieldsToObjectFieldForSegmentFilters(
    objectName: ObjectName,
  ): Observable<CmUiCompoundFilterObjectFieldOption[]> {
    return of([{value: 'testField', kind: 'TEXT', label: 'testowe pole '}]);
  }

  fetchFieldsOrder(dtoClassName: ObjectName): Observable<string[]> {
    return this.getFieldOrderGQL
      .fetch({ dtoClassName }, { fetchPolicy: 'no-cache', errorPolicy: 'all' })
      .pipe(
        map(response => response.data.getFieldOrder.objectFieldOrderList)
      );
  }

  getFieldsToObjectFieldOption(
    objectName: ObjectName,
  ): Observable<CmUiCompoundFilterObjectFieldOption[]> {
    return this.getNonInsertable(objectName).pipe(map(fields => fieldsToFieldOptions(objectName, fields, this.translateService)));
  }

  getNonInsertable(objectName: ObjectName): Observable<FieldFragment[]> {
    const nonInsertableFields = objectName === 'Lead' ? NON_INSERTABLE_LEAD : NON_INSERTABLE;
    return this.getFields(objectName).pipe(
      map(fields => fields.filter(field => !nonInsertableFields.includes(field.name))),
    );
  }
}

function fieldsToFieldOptions(objectName: string,
                              fields: FieldFragment[],
                              translateService: TranslateService): CmUiCompoundFilterObjectFieldOption[] {
  return fields.map(field => ({
    label: translateService.instant('API.' + objectName + '.' + field.name),
    value: field.name,
    kind: field.type && field.type.name === 'Int' ? 'NUMBER' : field.type.kind,
    name: field.type.name
  }));
}
