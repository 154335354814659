import {Injectable} from '@angular/core';
import {CmUiTableColumn} from '@cm/ui-modules';
import {GetCustomFieldDefinitionResponseDocument, GetCustomFieldDefinitionResponseQuery} from '@graphql';
import {Apollo} from 'apollo-angular';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ObjectName} from '../models';

@Injectable({
  providedIn: 'root',
})
export class CustomFieldService {
  constructor(private readonly apollo: Apollo) {}

  getObjectCustomColumns(objectName: ObjectName): Observable<CmUiTableColumn[]> {
    if (objectName !== 'Contact' && objectName !== 'Lead') {
      return of([]);
    }
    return this.apollo
      .watchQuery<GetCustomFieldDefinitionResponseQuery>({
        query: GetCustomFieldDefinitionResponseDocument,
        variables: { objectName },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map(data => data.data.getCustomFieldDefinitionResponse.customFieldDefinitionEntryList),
        map(entryList => entryList.find(entry => entry.objectName === objectName)),
        map(entry => entry.customFieldDefinitionList),
        map(data =>
          data.map(column => ({
            label: column.name,
            prop: `customFields.${column.name}`,
            sortable: false,
            searchable: false,
            visible: false,
            isCustomField: true,
          })),
        ),
      );
  }
}
