import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { CampaignsService } from '../../../modules/campaigns/campaigns.service';
import { FileUploadComponent } from '../file-upload/file-upload.component';


@Component({
  selector: 'app-import-from-file-modal',
  templateUrl: './import-from-file-modal.component.html',
  styleUrls: ['./import-from-file-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    FileUploadComponent
  ]
})
export class ImportFromFileModalComponent {
  public formGroup = new FormGroup({
    file: new FormControl(undefined,[Validators.required]),
  });
  @ViewChild('fileStatus') fileStatus: any;

  constructor(
    public dialogRef: MatDialogRef<ImportFromFileModalComponent>,
    private campaignsService: CampaignsService,
  ) { }

  onFileChange(file:File){
    this.formGroup.get('file').setValue(file);
  }

  private importFile(data: FormData) {
    this.campaignsService
      .importCampaign(data)
      .pipe()
      .subscribe(
        item => {
          this.dialogRef.close({
            status: 'success',
          });
        },
        error => {
          this.dialogRef.close({
            status: 'error',
            error: error,
          });
        },
      );
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onSubmit(form: FormGroup) {
    this.formGroup.get('file').setValidators([Validators.required]);
    this.formGroup.get('file').updateValueAndValidity();
    if (form.valid) {
      const formData = new FormData();
      formData.set('file', form.get('file').value);
      formData.set('moveMediaAssets', 'true');
      this.importFile(formData);
    } else {
      form.get('file').markAsDirty();
      form.get('file').markAsTouched();
    }
  }
}
