import {CmUiTableColumn} from '@cm/ui-modules';
import {
  CompoundFilterInput,
  EValueOperator,
  FilterOperator,
  SearchRequestInput,
  SearchValueExpressionInput
} from '@graphql';
import {ObjectName} from '@shared-models';

export function wordAndColumnsToCompoundFilter(
  value: string,
  columns: CmUiTableColumn[],
  objectName: ObjectName,
): CompoundFilterInput {
  if (!value) {
    return null;
  }
  columns = columns.filter(column => column.searchable && column.visible);
  let numberColumns: CompoundFilterInput[] = [];
  const items  = value.split(' ' );
  const stringFilters: CompoundFilterInput[] = [];

  columns.filter(column => column.searchFieldType === 'STRING')
    .forEach(column => {
    items.forEach(item => {
      stringFilters.push(columnToCompoundFilterInput(objectName, column.prop, 'CONTAINS', item));
    });
  });


  if (isDigits(value)) {
    numberColumns = columns
      .filter(column => column.searchFieldType === 'NUMBER')
      .map(column =>
        columnToCompoundFilterInput(objectName, column.prop, 'EQUALS', parseInt(value, 10)),
      );
  }

  return {
    type: 'EXPRESSION',
    expression: {
      expressionType: 'OR',
      compoundFilterList: [...stringFilters, ...numberColumns],
    },
  };
}

export function wordAndColumnsToSearchRequestInput(
  value: string,
  columns: CmUiTableColumn[],
  objectName: ObjectName,
): SearchRequestInput {
  if (!value) {
    return null;
  }
  columns = columns.filter(column => column.searchable && column.visible);
  let numberColumns: SearchValueExpressionInput[] = [];
  const items  = value.split(' ' );
  const stringFilters: SearchValueExpressionInput[] = [];

  columns.filter(column => column.searchFieldType === 'STRING')
    .forEach(column => {
      items.forEach(item => {
        stringFilters.push(columnToSearchValueExpressionInput(column.prop, 'LIKE', likeExpVal(item)));
      });
    });


  if (isDigits(value)) {
    numberColumns = columns
      .filter(column => column.searchFieldType === 'NUMBER')
      .map(column =>
        columnToSearchValueExpressionInput(column.prop, 'EQUAL', value),
      );
  }

  return {
    pagination: {
      page: 0,
      sort: null,
      size: 25
    },
    search: {
      operator: 'OR',
      valueLogicListList: [
        {
          searchValueExpressionList: [
            ...stringFilters, ...numberColumns
          ]
        }
      ]
    }
  };
}

function likeExpVal(value: string): string {
  return '%' + value + '%';
}

function columnToCompoundFilterInput(
  objectName: string,
  objectField: string,
  operator: FilterOperator,
  value: number | string,
): CompoundFilterInput {
  return {
    type: 'FILTER',
    filter: {
      objectName,
      objectField,
      operator,
      value,
    },
  };
}
function columnToSearchValueExpressionInput(
  objectField: string,
  operator: EValueOperator,
  value: string,
): SearchValueExpressionInput {
  return {
    key: objectField,
    operator,
    value
  };
}

function isDigits(str): boolean {
  return /^\d+$/.test(str);
}
