import { Injectable } from '@angular/core';
import { CmUiTimezoneModalService } from '@cm/ui-modules';
import { FrontendTranslateService } from '@shared/services/translation.service';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js';

@Injectable({
  providedIn: 'root',
})
export class GlobalTimezoneService {
  private timezone;
  
  constructor( private timezoneModal: CmUiTimezoneModalService, private translate: FrontendTranslateService) {
    this.timezone = moment.tz.guess();
    // console.log('GlobalTimezoneService timezone:', this.timezone)
  }

  public getTimeZone() {
    // console.log('getting timezone', this.timezone)
    return this.timezone;
  }

  public getTimeZoneInTimeFormat() {
    return moment.tz(this.timezone).format('ZZ');
  }

  public setTimeZone(newTimezone: string) {
    this.timezone = newTimezone;
    // console.log('seeting timezone', newTimezone)
  }

  public showTimezoneModal(){
    const data ={
      labels:{
        title:this.translate.instant('SelectTimezone'),
        save:this.translate.instant('Save'),
        cancel:this.translate.instant('Cancel'),
      },
      timezone: this.timezone
    }
    return this.timezoneModal.showModal(data)
  }
}
