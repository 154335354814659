import {Injectable} from '@angular/core';
import {ObjectOption} from '@cm/ui-modules';
import {
  CompoundFilterInput,
  CompoundFilterType,
  FilterOperator,
  GetContactListGQL,
  GetGroupListGQL,
  GetMeetingListGQL,
  GetRepresentativeListGQL,
  GetSpecializationListGQL,
  GetWorkplaceListGQL,
  OrderInput,
} from '@graphql';
import {Observable, of} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ObjectName} from '../models';
import {DatePipe} from '@angular/common';

export interface SearchVariables {
  limit: number;
  offset: number;
  orderBy: OrderInput;
  filter: CompoundFilterInput;
}

@Injectable({
  providedIn: 'root',
})
export class ObjectListDataService {
  constructor(
    private readonly getGroupList: GetGroupListGQL,
    private readonly getMeetingList: GetMeetingListGQL,
    private readonly getRepresentativeList: GetRepresentativeListGQL,
    private readonly getSpecializationList: GetSpecializationListGQL,
    private readonly getWorkplacesList: GetWorkplaceListGQL,
    private readonly getContactList: GetContactListGQL,
    private readonly datePipe: DatePipe
  ) {}

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd hh:mm');
  }

  emptyOrList(value: string, fields: string []): string [] {
    if (!value || value === '') {
      return [];
    }
    return fields;
  }
  searchFields(objectName: ObjectName, value: string): string [] {
    switch (objectName) {
      case 'Group':
        return this.emptyOrList(value, ['name']);
      case 'Meeting':
        return this.emptyOrList(value, ['title']);
      case 'Representative':
        return this.emptyOrList(value, ['firstName', 'lastName']);
      case 'Specialization':
        return this.emptyOrList(value, ['name']);
      case 'Workplace':
        return this.emptyOrList(value, ['name']);
      case 'Contact':
        return this.emptyOrList(value, ['firstName', 'lastName']);
      default:
        return [];
    }
  }

  getObjectList(
    objectName: ObjectName,
    value: string,
    selectedIds: number[],
  ): Observable<ObjectOption[]> {
    let variables: SearchVariables;
    switch (objectName) {
      case 'Group':
        variables = queryVariables(selectedIds, objectName, this.searchFields(objectName, value), value);
        return this.getGroupList.watch(variables, { errorPolicy: 'all' }).valueChanges.pipe(
          filter(response => !!response.data),
          map(response => response.data.getGroupList.data),
          map(rows =>
            rows.map(row => ({
              value: row.id,
              label: row.name
            })),
          ),
        );
      case 'Meeting':
        variables = queryVariables(selectedIds, objectName, this.searchFields(objectName, value), value);
        return this.getMeetingList.watch(variables, { errorPolicy: 'all' }).valueChanges.pipe(
          filter(response => !!response.data),
          map(response => response.data.getMeetingList.data),
          map(rows =>
            rows.map(row => ({
              value: row.id,
              disabled: true,
              label: row.title  + ' (' +
                this.transformDate(row.startTimeUTC) + ' - ' +
                this.transformDate(row.endTimeUTC )  + ')',

            })),
          ),
        );
      case 'Representative':
        variables = queryVariables(selectedIds, objectName, this.searchFields(objectName, value), value);
        return this.getRepresentativeList
          .watch(variables, { errorPolicy: 'all' })
          .valueChanges.pipe(
            filter(response => !!response.data),
            map(response => response.data.getRepresentativeList.data),
            map(rows =>
              rows.map(row => ({
                value: row.id,
                label: `${row.firstName} ${row.lastName}`,
              })),
            ),
          );
      case 'Specialization':
        variables = queryVariables(selectedIds, objectName, this.searchFields(objectName, value), value);
        return this.getSpecializationList
          .watch(variables, { errorPolicy: 'all' })
          .valueChanges.pipe(
            filter(response => !!response.data),
            map(response => response.data.getSpecializationList.data),
            map(rows =>
              rows.map(row => ({
                value: row.id,
                label: row.name,
              })),
            ),
          );
      case 'Workplace':
        variables = queryVariables(selectedIds, objectName, this.searchFields(objectName, value), value);
        return this.getWorkplacesList.watch(variables, { errorPolicy: 'all' }).valueChanges.pipe(
          filter(response => !!response.data),
          map(response => response.data.getWorkplaceList.data),
          map(rows =>
            rows.map(row => ({
              value: row.id,
              label: row.name,
            })),
          ),
        );
      case 'Contact':
        variables = queryVariables(selectedIds, objectName, this.searchFields(objectName, value), value);
        return this.getContactList.watch(variables, { errorPolicy: 'all' }).valueChanges.pipe(
          filter(response => !!response.data),
          map(response => response.data.getContactList.data),
          map(rows =>
            rows.map(row => ({
              value: row.id,
              label: `${row.firstName} ${row.lastName}`,
            })),
          ),
        );
      default:
        return of([]);
    }
  }
}

export function queryVariables(
  selectedIds: number[],
  objectName: ObjectName,
  searchedFields: string[],
  value: string,
): SearchVariables {
  const limit = (selectedIds ? selectedIds.length : 0) + 10;
  const operator: FilterOperator = 'CONTAINS';
  const type: CompoundFilterType = 'FILTER';
  const compoundFilterInput: CompoundFilterInput = {
    type: 'EXPRESSION',
    expression: {
      expressionType: 'OR',
      compoundFilterList: [
        // filters for searched fields
        ...searchedFields.map(objectField => ({
          type,
          filter: { objectName, objectField, operator, value },
        })),
      ],
    },
  };
  if (selectedIds && selectedIds.length > 0) {
    // filter for selected ids (for displaying in dropdown list)
    compoundFilterInput.expression.compoundFilterList.push({
      type,
      filter: { objectName, objectField: 'id', operator: 'IN', value: selectedIds},
    });
  }
  if (compoundFilterInput.expression.compoundFilterList.length === 0) {
    return {
      limit,
      offset: 0,
      orderBy: {},
      filter: null,
    };
  }
  return {
    limit,
    offset: 0,
    orderBy: {},
    filter: compoundFilterInput,
  };
}
