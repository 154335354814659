import { Injectable, NgZone, ComponentRef, ApplicationRef, ComponentFactoryResolver, Injector } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { fromEvent, merge, Subject } from 'rxjs';
import { debounceTime, takeUntil, filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { InactivityModalComponent } from '../components/inactivity-modal/inactivity-modal.component';
import { CountdownComponent } from '../components/countdown/countdown.component';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityMonitorService {
  private destroy$ = new Subject<void>();
  private timer: any;
  private countdownRef: ComponentRef<CountdownComponent>;
  private expireSession: boolean = true;
  private expire: boolean= false;

  constructor(
    private zone: NgZone,
    private router: Router,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private configService: ConfigService
  ) {
    this.observeConfig();
  }

private observeConfig(): void {
        this.configService.getAppConfig().subscribe(config => {
            if (config && config.configuration) {
                const expireSessionValue = +config.configuration.expireSession; 
                this.expire = expireSessionValue !== 0 ? true : false;
                this.expireSession = expireSessionValue !== 0 ? true : false;
            }
        });
}

  startMonitoring(): void {
    this.zone.runOutsideAngular(() => {
      const activity$ = merge(
        fromEvent(document, 'mousemove'),
        fromEvent(document, 'click')
      );

      activity$.pipe(
        debounceTime(100),
        takeUntil(this.destroy$)
      ).subscribe(() => this.checkRouteAndResetTimer());

      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      ).subscribe(() => this.checkRouteAndResetTimer());

      this.checkRouteAndResetTimer();
    });
  }

  private checkRouteAndResetTimer(): void {
    if (this.isAuthRoute() || !this.expireSession) {
      this.clearTimer();
      this.removeCountdown();
    } else {
      this.resetTimer();
    }
  }

  private isAuthRoute(): boolean {
    return this.router.url.startsWith('/authenticate/');
  }

  private resetTimer(): void {
    this.clearTimer();
    if (this.expire){
    //const expirationTime = this.getTokenExpirationTime();
    const expirationTime= new Date(Date.now() + 15 * 60 * 1000);
    if (expirationTime ) {
      const timeUntilExpiration = expirationTime.getTime() - Date.now();
      this.timer = setTimeout(() => this.handleInactivity(), timeUntilExpiration);
      this.showCountdown(expirationTime);
    } else {
      // If we can't determine the expiration time, use a default (e.g., 1 hour)
      const defaultExpiration = new Date(Date.now() + 15 * 60 * 1000);
      this.timer = setTimeout(() => this.handleInactivity(), 15 * 60 * 1000);
      this.showCountdown(defaultExpiration);
    }
  }
  }

  private clearTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private getTokenExpirationTime(): Date | null {
    const token = this.cookieService.get('access_token');
    if (!token) {
      return null;
    }

    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      if (payload.exp) {
        return new Date(payload.exp * 1000);
      }
    } catch (error) {
      console.error('Error parsing token:', error);
    }

    return null;
  }

  private handleInactivity(): void {
    this.cookieService.delete('access_token', '/');
    this.cookieService.delete('refresh_token', '/');
    this.removeCountdown();
    this.showInactivityModal();
  }

  private showInactivityModal(): void {
    this.zone.run(() => {
      const dialogRef = this.dialog.open(InactivityModalComponent, {
        width: '400px',
        disableClose: true,
        panelClass: ['inactivity-modal-overlay', 'mat-dialog-transparent'],
        backdropClass: 'inactivity-modal-overlay',
        
        data: { 
          title: 'Inactivity Detected',
          message: 'You were inactive. For security reasons we have logged you out. Please log in again to continue',
          buttonText: 'Go to Login',
          expirationTime: this.getTokenExpirationTime() }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === 'login') {
          this.router.navigate(['/app/authenticate/login']);
        }
      });
    });
  }

  private showCountdown(expirationTime: Date): void {
    this.zone.run(() => {
      if (this.countdownRef) {
        this.removeCountdown();
      }

      const factory = this.componentFactoryResolver.resolveComponentFactory(CountdownComponent);
      this.countdownRef = factory.create(this.injector);
      this.countdownRef.instance.expirationTime = expirationTime;

      document.body.appendChild(this.countdownRef.location.nativeElement);
      this.appRef.attachView(this.countdownRef.hostView);
    });
  }

  private removeCountdown(): void {
    if (this.countdownRef) {
      this.appRef.detachView(this.countdownRef.hostView);
      this.countdownRef.destroy();
      this.countdownRef = null;
    }
  }

  stopMonitoring(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.clearTimer();
    this.removeCountdown();
  }
}