import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  AccessService,
  CM_UI_NAVBAR_LOGO_URL,
  CM_UI_NAVBAR_SETTINGS,
  CmAccessModuleModule,
  CmUiCompoundFilterModule,
  CmUiFormModule,
  CmUiLoaderModule,
  CmUiNavbarModule,
  CmUiTableModule,
  UiTopBarModule
} from '@cm/ui-modules';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import * as fromComponents from './components';
import { DataListService } from './services/data-list.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { InactivityMonitorService } from './services/inactivitymonitor.service';


const MATERIAL_MODULES = [
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatPaginatorModule,
  MatIconModule,
  MatListModule,
  MatChipsModule,
  MatTableModule,
  MatSortModule,
  MatSnackBarModule,
  MatSelectModule,
];

const CM_UI_MODULES = [
  CmUiTableModule,
  CmUiNavbarModule,
  CmUiFormModule,
  UiTopBarModule,
  CmUiCompoundFilterModule,
  CmUiLoaderModule,
  UiTopBarModule,
  CmAccessModuleModule,
];
@NgModule({
  imports: [
    CommonModule,
    ...MATERIAL_MODULES,
    ...CM_UI_MODULES,
    TranslateModule,
    HttpClientModule, 
    FormsModule,
  ],
  exports: [
    ...MATERIAL_MODULES,
  ...CM_UI_MODULES,
    TranslateModule,
  ...fromComponents.components
],
  declarations: [...fromComponents.components],
  providers: [
    DataListService,
    {
      provide: CM_UI_NAVBAR_SETTINGS,
      useValue: {
        version: environment.VERSION,
      },
    },
    {
      provide: CM_UI_NAVBAR_LOGO_URL,
      useValue: {
        logoUrl: environment.highp_logo,
        routerLink: ['','dashboard'],
      },
    },

    TranslateService
  ],
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [AccessService, CookieService, InactivityMonitorService]
    };
  }
}
