import {CmUiCompoundFilterValue} from '@cm/ui-modules';
import {CompoundFilterInput, SearchLogicExpressionInput, SearchValueExpressionInput} from '@graphql';

export function filterValueToFilterInput(filterValue: CmUiCompoundFilterValue[]): CompoundFilterInput {
  return {
    type: 'EXPRESSION',
    expression: {
      expressionType: 'AND',
      compoundFilterList: filterValue.map(filter => ({
        type: 'FILTER',
        filter
      }))
    }
  };
}

export function filterSegmentValueToFilterInput(filterValue: CmUiCompoundFilterValue[]): SearchLogicExpressionInput {
  return {
    operator: 'AND',
    valueLogicListList: [{
      searchValueExpressionList: filterValue.map(filter => ({
        ...createFilterValue(filter)
      }))
    }]
  };
}

function createFilterValue(filter: CmUiCompoundFilterValue): SearchValueExpressionInput {
  const value: SearchValueExpressionInput = {
    value: filter.value,
    operator: 'EQUAL',
    key: filter.objectField
  };
  switch (filter.operator) {
    case 'CONTAINS':
      value.value = `%${filter.value}%`;
      value.operator = 'LIKE';
      break;
    case 'ENDS_WITH':
      value.value = `%${filter.value}`;
      value.operator = 'LIKE';
      break;
    case 'EQUALS':
      value.operator = 'EQUAL';
      break;
    case 'GREATER_THAN':
      value.operator = 'GREATER';
      break;
    case 'GREATER_THAN_OR_EQUALS_TO':
      value.operator = 'GREATER_EQUAL';
      break;
    case 'IN':
      value.value = `%${filter.value}%`;
      value.operator = 'LIKE';
      break;
    case 'IS_NOT_NULL':
      value.operator = 'NOT_EQUAL';
      break;
    case 'IS_NULL':
      value.operator = 'EQUAL';
      break;
    case 'LESS_THAN':
      value.operator = 'LESS';
      break;
    case 'LESS_THAN_OR_EQUALS_TO':
      value.operator = 'LESS_EQUAL';
      break;
    case 'NOT_CONTAINS':
      value.value = `%${filter.value}%`;
      value.operator = 'NOT_LIKE';
      break;
    case 'NOT_ENDS_WITH':
      value.value = `%${filter.value}`;
      value.operator = 'NOT_LIKE';
      break;
    case 'NOT_EQUALS':
      value.operator = 'NOT_EQUAL';
      break;
    case 'NOT_IN':
      value.value = `%${filter.value}%`;
      value.operator = 'NOT_LIKE';
      break;
    case 'NOT_STARTS_WITH':
      value.value = `${filter.value}%`;
      value.operator = 'NOT_LIKE';
      break;
    case 'STARTS_WITH':
      value.value = `${filter.value}%`;
      value.operator = 'LIKE';
      break;
  }
  return value;
}
