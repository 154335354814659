import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

interface DialogData {
  image: string | null;
  title: string;
  message: string;
  buttonText: string;
  expirationTime?: Date | null;
}

@Component({
  selector: 'app-inactivity-modal',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, NgIf],
  templateUrl: './inactivity-modal.component.html',
  styleUrls: ['./inactivity-modal.component.scss']
})
export class InactivityModalComponent {
  constructor(
    public dialogRef: MatDialogRef<InactivityModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  goToLogin(): void {
    this.dialogRef.close('login');
  }
}