import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '@cm/ui-modules';
import { firstValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomURLEncoder } from './custom-url-encoder';
import { Credential } from './models/credential';

import {
  ErrorDetails,
  InvalidGrantErrorLoginResult,
  LoginResult,
  LoginResultType,
  MULTISESSIONLoginResult,
  NOJWTLoginResult,
  SuccessLoginResult,
  TooManyTryLoginResult,
} from './models/login-result';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) { }

  logout(): void {
    // console.log('Logout request');
    this.logoutAsync();
  }

  private async logoutAsync(): Promise<void> {
    const accessToken = this.tokenService.getAccessToken();
    if (!accessToken) {
      // console.log('No JWT token found');
      return;
    }

    const domain = this.getDomain();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${accessToken}`
    });

    try {
      await firstValueFrom(this.httpClient.delete(`${domain}/api/auth/session/logout`, { headers, withCredentials: true }));
      this.tokenService.removeCookies();
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }


  check(): Observable<LoginResult> {
    const accessToken = this.tokenService.getAccessToken();
    if (!accessToken) {
      return of({ type: LoginResultType.NOJWT } as NOJWTLoginResult);
    }

    const domain = this.getDomain();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${accessToken}`
    });

    return this.httpClient.get<any>(`${domain}/api/auth/session/check`, { headers, withCredentials: true })
      .pipe(
        map((response: any) => {
          if (response.status === false) {
            // console.log('multisession response false and remove cookie');
            this.tokenService.removeCookies();
            return { type: LoginResultType.MULTISESSION } as MULTISESSIONLoginResult;
          } else {
            // console.log('multisession response true');
            return { type: LoginResultType.SUCCESS } as SuccessLoginResult;
          }
        }),
        catchError(error => this.handleError(error))
      );
  }

  login(credential: Credential): Observable<LoginResult> {
    const domain = this.getDomain();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic dGVzdDp0ZXN0'
    });

    const body = new HttpParams({ encoder: new CustomURLEncoder() })
      .set('tenant_domain', domain)
      .set('grant_type', 'password')
      .set('username', credential.username)
      .set('password', credential.password);

    return this.httpClient.post<any>(
      `${domain}/api/auth/oauth/token`,
      body.toString(),
      { headers, withCredentials: true }
    ).pipe(
      tap((response: any) => {
        // console.log('Login response:', response);
        this.tokenService.updateUser(response);
      }),
      map(() => ({ type: LoginResultType.SUCCESS } as SuccessLoginResult)),
      catchError((error: HttpErrorResponse) => {
        // console.error('Login error:', error.error);
        return of(this.handleLoginError(error));
      })
    );
  }

  private handleLoginError(error: HttpErrorResponse): LoginResult {
      const authorizeError = error.error as AuthorizeError;
      switch (authorizeError.error) {
        case 'invalid_grant':
        case 'unauthorized':
          return { type: LoginResultType.INVALID_GRANT } as InvalidGrantErrorLoginResult;
        case 'too-many-failed-login-attempts':
          return this.mapToTooManyTryLoginResult(authorizeError as TooManyFailedLoginAttemptsAuthorizeError);
        default:
            if (error.status === 0) {
              return { type: LoginResultType.ERROR, message: 'A network error occurred. Please check your connection.' };
            }

            return { type: LoginResultType.ERROR, message: 'An unexpected error occurred.' };
      }
  }


  private getDomain(): string {
    return window.location.hostname === 'localhost' ? environment.auth_domain : window.location.origin;
  }

  private handleError(error: any): Observable<LoginResult> {
    console.error('Authentication check failed', error);
    return of({ type: LoginResultType.ERROR });
  }

  private mapErrorToLoginResult(authorizeError: AuthorizeError): Observable<LoginResult> {
    if (!authorizeError) {
      console.error('Unknown error occurred', authorizeError);
      return of({ type: LoginResultType.ERROR });
    }

    // console.log('authorizeError', authorizeError.error);
    switch (authorizeError.error) {
      case 'unauthorized':
      case 'invalid_grant':
        return of({ type: LoginResultType.INVALID_GRANT } as InvalidGrantErrorLoginResult);
      case 'too-many-failed-login-attempts':
        return of(this.mapToTooManyTryLoginResult(authorizeError));
      default:
        console.error('Unhandled error type', authorizeError);
        return of({ type: LoginResultType.ERROR });
    }
  }

  private mapToTooManyTryLoginResult(
    tooManyTryErrorType: TooManyFailedLoginAttemptsAuthorizeError
  ): TooManyTryLoginResult {
    const errorDetails = this.mapToErrorDetailsTooManyTryLoginResult(tooManyTryErrorType);

    return {
      errorDetails,
      message: 'Too many failed login atempts',
      type: LoginResultType.TOO_MANY_TRY,
    };
  }

  private mapToErrorDetailsTooManyTryLoginResult(
    authorizeError: TooManyFailedLoginAttemptsAuthorizeError
  ): ErrorDetails {
    return {
      numberTry: authorizeError.allowedNumberOfFailedLoginAttemptsInPenalizedPeriod,
      penaltyTimeInMinutes: authorizeError.penaltyTimeInMinutes,
      penalizedPeriodInMinutes: authorizeError.penalizedPeriodInMinutes,
      userBlockedFromUTC: authorizeError.userBlockedFromUTC,
    };
  }
}

interface ApiCredential {
  username: string;
  password: string;
}

interface Unauthorized {
  error: 'unauthorized';
  error_description: string;
}

interface InvalidGrantAuthorizeError {
  error: 'invalid_grant';
  error_description: string;
}

interface TooManyFailedLoginAttemptsAuthorizeError {
  error: 'too-many-failed-login-attempts';
  allowedNumberOfFailedLoginAttemptsInPenalizedPeriod: number;
  penalizedPeriodInMinutes: number;
  penaltyTimeInMinutes: number;
  userBlockedFromUTC: string;
}

type AuthorizeError = InvalidGrantAuthorizeError | TooManyFailedLoginAttemptsAuthorizeError | Unauthorized;
