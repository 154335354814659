<h3 mat-dialog-title translate>{{ 'ADMIN.campaignList.importCampaign.title' | translate }}</h3>
<div mat-dialog-content>
    <app-file-upload accept=".highp" [placeholder]="'ADMIN.campaignList.importCampaign.placeholder'" (fileChanged)="onFileChange($event)"></app-file-upload>
</div>
<div mat-dialog-actions class="modal-btns">
  <button type="button" (click)="onCancel()" mat-stroked-button color="warn">
    <span translate>{{ 'Cancel' | translate }}</span>
  </button>
  <button type="submit" (click)="onSubmit(formGroup)" mat-flat-button color="accent">
    <span>{{ 'ADMIN.campaignList.tableAction.import' | translate }}</span>
  </button>
</div>
