import {FormDefinitionGroup, FormField, FormFieldType} from '@cm/ui-modules';
import {ContactCustomField, EnumValue, FormFieldDefinitionType} from '@graphql';
import {TranslateService} from '@ngx-translate/core';
import {CustomFieldWithValue, FieldWithValue, ObjectName} from '@shared-models';
import {FormFieldColumn} from '@cm/ui-modules/lib/cm-ui-form/models/form-definition';

interface ViewSetting {
  [objectName: string]: {
    [fieldName: string]: {
      [p: string]: string;
    };
  };
}

const VIEW_SETTINGS: ViewSetting = {
  Contact: {
    gender: {
      width: '200px',
    }
  },
};

export function customFormFieldsToFormDefinitionGroup(
  customFormFields: FormField[],
  translateService: TranslateService
): FormDefinitionGroup {
  return {
    label: translateService.instant('API.Contact.customFields'),
    name: 'customFields',
    fieldColumns: divideByColumns(customFormFields, translateService.instant('API.Contact.customFields'))
  };
}

export function customFieldsToFormFields(
  customFields: CustomFieldWithValue[],
  customFieldValues: ContactCustomField[],
): FormField[] {
  return customFields.map(customField => ({
    name: customField.name,
    type: customField.type === 'HIDDEN' as FormFieldDefinitionType ? 'TEXT' as FormFieldType : customField.type as FormFieldType,
    label: customField.label,
    value: customFieldValueRemap(customField.name, customFieldValues, customField.type),
    enumValues: addEmptyValue(customField.values, customField)
  }));
}

export function addEmptyValue(values: EnumValue[], customFieldWithValue: CustomFieldWithValue): EnumValue[] {
  if (values && values.length > 0) {
    if (customFieldWithValue.type === 'SINGLE_SELECT') {
      values.unshift({label: null, name: null});
    }
    return values;
  }
  return [];
}

export function customFieldValueRemap(fieldName: string, customFieldValues: ContactCustomField[], type: FormFieldType): any {
  if (!customFieldValues) {
    return null;
  }
  const values = customFieldValue(fieldName, customFieldValues);
  if (values) {
    if (type === 'SINGLE_SELECT') {
      return values[0];
    }
    if (type === 'CHECKBOX') {
      return JSON.parse(values[0]);
    } else {
      return values;
    }
  } else {
    return null;
  }
}

export function customFieldValue(fieldName: string, customFieldValues: ContactCustomField[]): any {
  if (!customFieldValues) {
    return null;
  }
  const customField = customFieldValues.find(field => field.fieldName === fieldName);
  if (customField) {
      return customField.valueList;
  } else {
    return null;
  }
}

export function divideByColumns(formFields: FormField[], groupLabel: string): FormFieldColumn[] {
  let chunk = 5;
  const finalTab = [];
  const columnLimit = 4;
  let slices = formFields.length / chunk;
  if (slices > columnLimit) {
    chunk = formFields.length / columnLimit;
    slices = columnLimit;
  }
  let temporary = chunk;
  for (let i = 0; i < slices; i++) {
    finalTab.push({
      fields: formFields.slice(i * chunk, temporary),
      label: i === 0 ? groupLabel : ''
    });
    temporary = temporary + chunk;
  }
  return finalTab;
}

export function fieldsToFormDefinitionGroups(
  fields: FieldWithValue[],
  translateService: TranslateService,
  objectName: ObjectName,
): FormDefinitionGroup[] {
  fields.filter(item => item.type.kind === 'ENUM' && item.name !== 'academicRank').forEach(
    itm => itm.type.enumValues.unshift({name: null}));
  const relationFields = fieldsToRelationFormFields(fields, translateService, objectName);
  const definitionGroups: FormDefinitionGroup[] = [
    {
      name: 'basic',
      label: translateService.instant('API.Contact.basicFields'),
      fieldColumns: divideByColumns(fieldsToBasicFormFields(fields, translateService, objectName),
        translateService.instant('API.Contact.basicFields'))
    },
  ];

  if (relationFields.length > 0) {
    definitionGroups.push({
      name: 'relations',
      label: translateService.instant('API.Contact.relationFields'),
      fieldColumns: [
        {
          label: translateService.instant('API.Contact.relationFields'),
          fields: relationFields,
        },
      ],
    });
  }

  return definitionGroups;
}

export function fieldsToBasicFormFields(
  fields: FieldWithValue[],
  translateService: TranslateService,
  objectName: ObjectName,
): FormField[] {
  return fields
    .filter(
      field =>
        field.type.ofType === null && field.name !== 'version' && field.type.kind !== 'OBJECT',
    )
    .map(field => ({
      name: field.name,
      type: resolveDateField(field.type.name, field.type.kind),
      label: translateService.instant(`API.${objectName}.${field.name}`),
      value: field.value,
      viewSettings: getFieldViewSettings(objectName, field.name),
      enumValues: field.type.enumValues
        ? field.type.enumValues.map(enumValue => ({
            label: translateService.instant(`API.${objectName}.${field.name}.${enumValue.name}`),
            name: enumValue.name,
          }))
        : [],
    }));
}

export function getFieldViewSettings(
  objectName: ObjectName,
  fieldName: string,
): {
  [p: string]: string;
} {
  if (VIEW_SETTINGS[objectName]) {
    return VIEW_SETTINGS[objectName][fieldName] || null;
  }
  return null;
}

export function resolveDateField(name: string, type: string): FormFieldType {
  if (name && name === 'LocalDateTime') {
    return 'DATETIME' as FormFieldType;
  } else {
    return type as FormFieldType;
  }
}

export function remapRelationFieldsOrder(originalFields: FieldWithValue[]): FieldWithValue [] {
  const remaped = [];
  const groups = originalFields.find(item => item.name === 'groupList');
  const meetings = originalFields.find(item => item.name === 'meetingList');
  const representative = originalFields.find(item => item.name === 'representativeList');
  const workplaces = originalFields.find(item => item.name === 'workplaceList');
  const specialisations = originalFields.find(item => item.name === 'specializationList');

  remaped.push(representative);
  remaped.push(groups);
  remaped.push(specialisations);
  remaped.push(workplaces);
  remaped.push(meetings);
  return remaped.filter(item => item !== undefined);
}

export function fieldsToRelationFormFields(
  fields: FieldWithValue[],
  translateService: TranslateService,
  objectName: ObjectName,
): FormField[] {
  return remapRelationFieldsOrder(fields)
    .filter(field => field.type.kind === 'LIST' && field.name !== 'customFieldList')
    .map(field => ({
      name: field.name,
      type: field.type.kind as FormFieldType,
      value: field.value ? (field.value as { id: number }[]).map(({ id }) => id) : null,
      label: translateService.instant(`API.${objectName}.${field.name}`),
      objectName: field.type.ofType.name,
    }));
}
