import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {
  CmMultipleActionData,
  CmSearchField,
  CmTableActionData,
  CmTableActionEvent,
  CmUiTableColumn,
  CmUiTableHeaderButton,
} from '@cm/ui-modules';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MenuTranslator } from '../../classes/MenuTranslator';
import { CompoundFilterInput, OrderInput } from '../../graphql';
import { wordAndColumnsToCompoundFilter } from '../../helpers/search';
import { ObjectName } from '../../models';
import { TableColumnService } from '../../services';

@Component({
  selector: 'app-data-list-view',
  templateUrl: './data-list-view.component.html',
  styleUrls: ['./data-list-view.component.scss'],
})
export class DataListViewComponent<RowId, DataType> implements OnChanges, OnInit {
  @Input() headerTemplate: TemplateRef<any>;
  @Input() dataList: DataListRows<DataType>;
  @Input() columns: CmUiTableColumn[];
  @Input() actions: CmTableActionData[] = [];
  @Input() multipleActions: CmMultipleActionData[] = [];
  @Input() isLoading = false;
  @Input() filterObjectName: ObjectName;
  @Input() pageIndex: number;
  @Input() pageSize: number;
  @Input() selectionType: 'multiple' | 'single' | 'none' = 'none';
  @Input() tableMenuButtons: CmUiTableHeaderButton[] = [];
  @Input() searchVisible = true;
  @Output() paginationChange = new EventEmitter<PageEvent>();
  @Output() setOrderBy = new EventEmitter<OrderInput>();
  @Output() actionClicked = new EventEmitter<CmTableActionEvent<any>>();
  @Output() multipleActionClicked = new EventEmitter<RowId[]>();
  @Output() onTableActionEvent = new EventEmitter<string>();
  @Output() onSearch = new EventEmitter<CompoundFilterInput>();
  searchFields: CmSearchField[];
  searchValue: string;
  sort: Sort;
  searchSubject = new Subject<string>();

  readonly pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(
    private tableColumnService: TableColumnService,
    private menuTranslator: MenuTranslator,
  ) {
    this.searchSubject
      .asObservable()
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        map(search => {
          localStorage.setItem(`${this.filterObjectName}_searchValue`, search);
          return wordAndColumnsToCompoundFilter(search, this.columns, this.filterObjectName);
        }),
      )
      .subscribe(compoundFilter => {
        this.onSearch.emit(compoundFilter);
      });
  }

  ngOnInit(): void {
    this.menuTranslator.translateTableHeaderButtons(this.tableMenuButtons);
    if (localStorage.getItem(`${this.filterObjectName}_searchValue`)) {
      this.searchValue = localStorage.getItem(`${this.filterObjectName}_searchValue`);
    }
    if (localStorage.getItem(`${this.filterObjectName}_sort`)) {
      this.sort = JSON.parse(localStorage.getItem(`${this.filterObjectName}_sort`));
      this.onSortChange(this.sort);
    }
  }

  initColumns() {
    if (localStorage.getItem(`${this.filterObjectName}_columns`)) {
      const _columns = JSON.parse(localStorage.getItem(`${this.filterObjectName}_columns`));
      this.columns = this.columns.map(column => {
        const findColumn = _columns.find(_column => _column.prop === column.prop);
        if (findColumn) {
          column.visible = findColumn.visible;
          if (findColumn.visibleOnMobile !== undefined) {
            column.visibleOnMobile = findColumn.visibleOnMobile;
          }
        }
        return column;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('filterObjectName' in changes) {
      this.updateSearchFields();
    }
    if ('columns' in changes) {
      this.initColumns();
    }
  }

  onTableAction(value: string) {
    this.onTableActionEvent.emit(value);
  }

  onSortChange(sort: Sort): void {
    this.setOrderBy.emit({
      field: sort.active,
      order: sort.direction === 'asc' ? 'ASCENDING' : 'DESCENDING',
    });
    localStorage.setItem(`${this.filterObjectName}_sort`, JSON.stringify(sort));
  }

  onPaginationChange(data: PageEvent): void {
    this.paginationChange.emit(data);
  }

  onActionClicked(event: CmTableActionEvent<RowId>): void {
    this.actionClicked.emit(event);
  }

  onMultipleActionClicked(event: RowId[]): void {
    // FIXME: event.rowIds shouldn't have removed Ids
    event = event.filter(rowId => this.dataList.rows.find(row => row['id'] === rowId));
    this.multipleActionClicked.emit(event);
  }

  onColumnsChange(columns: CmUiTableColumn[]): void {
    localStorage.setItem(`${this.filterObjectName}_columns`, JSON.stringify(columns));
  }

  onSearchValueChange(value: string): void {
    const filterButton = this.tableMenuButtons.find(button => button.action === 'filter');
    if (filterButton) {
      filterButton.disabled = !!value;
    }
    this.searchSubject.next(value);
  }

  private updateSearchFields(): void {
    this.searchFields = this.tableColumnService.getObjectSearchFields(this.filterObjectName);
  }
}

interface DataListRows<DataType> {
  total: number;
  rows: any[];
  error?: any;
}
