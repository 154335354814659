import { MutationResponse, DeleteResult, ErrorWithId, ExtensionsErrorException } from './models';

export function resultErrorsToErrorWithIdList(
  errors: ReadonlyArray<any>,
  id: number = null,
): ErrorWithId[] {
  if (!errors) {
    return [];
  }

  return errors
    .filter(
      extensionError => extensionError.extensions.type !== 'NoSuchObjectExistsGraphQLAPIException',
    )
    .map(extensionError => ({
      id: id || extensionError.extensions.id,
      exceptionKey: extensionExceptionToExceptionTranslateKey(extensionError.extensions),
    }));
}

export function mapMutationResponseToDeleteResult(response: MutationResponse): DeleteResult {
  const errorsToErrorWithIdList = resultErrorsToErrorWithIdList(response.errors);
  return errorsToErrorWithIdList.length === 0 ? <DeleteResult>'SUCCESS' : <DeleteResult>'FAILED';
}

function extensionExceptionToExceptionTranslateKey(
  extensionsErrorException: ExtensionsErrorException,
): string {
  return `${extensionsErrorException.message}.${extensionsErrorException.fieldPath}`;
}
